import { Outlet } from 'react-router-dom';
import SiteHeader from './header';

const instructor_Layout = () => {

  return (
    <div className="bg-white">
      <SiteHeader />
      <main>
        <Outlet />
      </main>  
    </div>
  );
};

export default instructor_Layout;
