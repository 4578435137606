
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    WORK_EXPRIENCE_BEGIN:'WORK_EXPRIENCE_BEGIN',
    WORK_EXPRIENCE_ERR:'WORK_EXPRIENCE_ERR',
    WORK_EXPRIENCE_SUCCESS:'WORK_EXPRIENCE_SUCCESS',


    ADD_WORK_EXPRIENCE_BEGIN:'ADD_WORK_EXPRIENCE_BEGIN',
    ADD_WORK_EXPRIENCE_ERR:'ADD_WORK_EXPRIENCE_ERR',
    ADD_WORK_EXPRIENCE_SUCCESS:'ADD_WORK_EXPRIENCE_SUCCESS'

    
}

export default actiontyps;