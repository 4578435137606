import React from "react";
import VideoCard from "./VideoCard";
import { checkIsMobile } from "../../../utils/helpers";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import reviewLeftArrow  from "../../../assets/svgs/reviewLeftArrow.svg";
import { useNavigate } from "react-router-dom";

export async function wishlistLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function Wishlist() {
  const isMobile = checkIsMobile();
  const navigate = useNavigate();
  return (
    <div className="max-w-full w-full m-auto px-4 md:py-6">
      {isMobile && (
        <div className="flex flex-row gap-4 py-8">
          <button className="bg-gray-100 rounded-full flex justify-center items-center" onClick={()=>navigate("/")}>
            <img src={reviewLeftArrow} alt="" className="py-2 px-3" />
          </button>
          <div className="relative w-full ">
            <input
              type="text"
              placeholder="Search"
              className="w-full border-none rounded-full pl-12 py-3 bg-gray-100"
            />
            <MagnifyingGlassIcon className="w-5 absolute top-[14px] left-4 text-[#363A49]" />
          </div>
        </div>
      )}
      <div className="flex flex-row gap-2">
        <h1 className="text-3xl font-semibold text-secondary">WishList</h1>
        <p className="text-3xl font-semibold text-secondary">(6)</p>
      </div>
      <div>
        <VideoCard />
      </div>
    </div>
  );
}

export default Wishlist;
