import React from 'react'

function ExpertIn() {
  return (
    <div className="border border-gray-200 rounded-xl my-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">
          Interested Topics
        </h3>

        <div className="flex flex-row mx-6 mb-4 gap-4 flex-wrap">
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Web Development
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Mobile Development
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Programming Languages
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Leadership
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Career Development
          </button>
          <button className="border border-gray-200 px-4 py-2 rounded-3xl hover:bg-gray-300 text-lg transform transition duration-300 hover:scale-110">
            Digital Marketing
          </button>
        </div>
      </div>
  )
}

export default ExpertIn
