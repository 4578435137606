import React from "react";
import { useState } from "react";
import pencil from "../../assets/svgs/pencil.svg";
import { Container, FloatingTextField } from "../../components/ui-components";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import downArrow from "../../assets/svgs/downArrow.svg";
import upArrow from "../../assets/svgs/upArrow.svg";
import PaymentBox from "./PaymentBox";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import Participants from "./Participants";
import FlexiblePayment from "./FlexiblePayment";
import paymentFailed from "../../assets/svgs/paymentFailed.svg";
import reviewLeftArrow from "../../assets/svgs/reviewLeftArrow.svg";
import { Link } from "react-router-dom";
import { checkIsMobile } from "../../utils/helpers";

export async function checkoutLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function Checkout() {
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const isMobile = checkIsMobile();
  return (
    <section className="w-full bg-gray-50 py-10">
      <Container className="md:px-10">
        <Link
          className="flex flex-col md:flex-row md:gap-4"
          to="/courses/courseid">
          <img
            src={reviewLeftArrow}
            alt="Back Arrow"
            className={`${isMobile && "w-fit border rounded-full p-2"}`}
          />
          <h1 className="text-3xl md:text-2xl text-secondary font-medium py-2 md:py-4">
            Checkout
          </h1>
        </Link>
        <div className="w-full flex flex-col md:flex-row gap-5 md:gap-12 my-2 md:my-0">
          {/* left Box */}
          <div className="w-full max-w-4xl">
            {isPaymentFailed && (
              <div className="bg-custom-gradient border border-[#D93C4C] p-4 flex flex-row gap-4 rounded-xl mb-8">
                <img src={paymentFailed} alt="paymentFailed" />
                <div>
                  <h2 className="text-base text-secondary font-medium">
                    Payment Failed!
                  </h2>
                  <p className="text-sm text-[#707070CC]">
                    We encountered an issue with your payment. Please try again
                  </p>
                </div>
              </div>
            )}

            {/* Course Name */}
            <div className="flex flex-col gap-2 md:gap-0 md:flex-row justify-between md:items-center bg-white border border-gray-300 p-4 rounded-2xl">
              <div className="flex flex-col gap-1">
                <h1 className="text-lg md:text-xl text-secondary font-medium">
                  How to becoming UX designer
                </h1>
                <p className="text-xs md:text-sm text-primary">
                  Batch 1&nbsp; | &nbsp;Online&nbsp; | &nbsp;One on One&nbsp; |
                  &nbsp;May 24 - Aug 24
                </p>
              </div>
              <div className="flex flex-row gap-1 bg-[#E8E7E7] items-center py-1 px-3 md:px-4 md:py-2 w-fit md:max-h-fit rounded-full">
                <img src={pencil} alt="pencil" />
                <p className="text-xs text-[#3B4350] whitespace-nowrap">
                  EDIT BATCH
                </p>
              </div>
            </div>

            {/* Add Participants */}
            <Participants />

            {/* Flexible Payments */}
            <FlexiblePayment />
          </div>

          {/* right box */}
          <PaymentBox />
        </div>
        
      </Container>
      {isMobile && (
          <div className="w-full shadow-3xl fixed bottom-0 left-0 right-0 bg-white">
            <div className="w-full flex flex-row justify-between items-center py-3 px-5">
              <div>
                <h4 className="flex flex-row justify-start items-center gap-1 md:gap-3">
                  <span className="text-lg md:text-xl text-text font-semibold">
                    ₹ 30,090
                  </span>
                  <span className="text-sm text-gray-600 font-medium">
                    20% Off
                  </span>
                </h4>
                <h6 className="text-sm  text-gray-600 font-medium">
                  <del>₹ 14,057</del>
                </h6>
              </div>
              <div>
                <Link
                  to="/courses/confirmation"
                  className="rounded-xl md:rounded-2xl bg-[#0E121D] px-6 md:px-3.5 py-2.5 md:w-48 flex justify-center text-xs md:text-lg font-semibold text-white shadow-sm hover:bg-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                  Pay Now
                </Link>
              </div>
            </div>
          </div>
        )}
    </section>
  );
}

export default Checkout;
