// import Cookies from 'js-cookie';
// import { message } from 'antd';
import { DataService } from '../../dataService/dataService';
import actiontyps from './actionsTypes';
import { setItem } from '../../utils/localStorageControl';
const { FAQS_BEGIN, FAQS_SUCCESS, FAQS_ERR } = actiontyps; 

const getFaqCategories = () => {
  return async (dispatch) => {
    try {
      dispatch({type: FAQS_BEGIN});
      let response = await DataService.get(`faqs/categories/fetch/all`);
      if(await response.data.success){
        return dispatch({ type: FAQS_SUCCESS, data:response.data.data});
      }else{
        return dispatch({ type: FAQS_ERR, err:response.data.message});
      }
    } catch (err) {
      dispatch({ type: FAQS_ERR, err:err});
    }
  };
};

const getFaqs = () => {
  return async (dispatch) => {
    try {
      dispatch({type: FAQS_BEGIN});
      let response = await DataService.get(`skill`);
      if(await response.data.success){
        return dispatch({ type: FAQS_SUCCESS, data:response.data.data});
      }else{
        return dispatch({ type: FAQS_ERR, err:response.data.message});
      }
    } catch (err) {
      dispatch({ type: FAQS_ERR, err:err});
    }
  };
};

export { getFaqs, getFaqCategories };
