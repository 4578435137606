import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Container , Drawer } from "../../../../components/ui-components";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import { Tabs } from 'antd';
import { useState } from "react";

const Card = ({ studentID , Name , email , profilePicture }) => (
    <div className="bg-white p-4 rounded-[34px] mb-4 flex justify-between h-max w-full py-3">
        <div className='flex flex-row items-center justify-start w-[50%]'>
            <span id={profilePicture} className="h-10 w-10 bg-gray-200 rounded-full"/>
            <h3 className="font-semibold text-md text-left w-[40%]  px-4">{Name}</h3>
            <p className="text-gray-500 text-sm">{email}</p>
        </div>
    
        <div className="relative flex items-center justify-center text-left mt-4 gap-x-2">
            <p className="text-gray-500 text-sm">{studentID}</p>
            <button className="flex items-center rounded-full  text-primary hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
              <EllipsisVerticalIcon aria-hidden="true" className="size-6" />
            </button>
        </div>
    </div>
);

const StudentData = [
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
   {profilePicture: '' , Name : 'Arun Tiwari' , email : 'someEmail@gmail.com' , studentID: '35513511'},
  ];


const Tab1Content = () => (
    <div className='w-full'>
      {StudentData.map((student, index) => (
        <Card key={index} profilePicture = {student.profilePicture} Name = {student.Name} email = {student.email} studentID = {student.studentID} />
      ))}
    </div>
  );
const Tab2Content = () => <div></div>;
const Tab3Content = () => <div></div>;

const items = [
    {
      key: '1',
      label: 'Total Learners',
      children: <Tab1Content />, 
    },
    {
      key: '2',
      label: 'Ongoing',
      children: <Tab2Content />,
    },
    {
      key: '3',
      label: 'Completed',
      children: <Tab3Content />,
    },
  ];

export default function Learner() {

    const [open ,setOpen] = useState(false);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center grow overflow-y-auto px-8">
        <div className="w-[90%] py-6">
          <div className="w-full flex flex-row justify-between items-center pt-6">
            <h1 className="font-bold text-lg text-left text-secondary">
              Learners
            </h1>
            <div className="relative w-max">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id={"head-search"}
                type={"text"}
                placeholder="Search Learners"
                className={
                  "bg-white border text-sm border-gray-400 bg-gray-300 rounded-4xl max-w-sm h-10 pl-8 text-input-text placeholder:text-input-placeholder"
                }
              />
            </div>
          </div>
          <div className="ml-2 grid grid-flow-col auto-cols-[190px] gap-4 items-start py-10">
            <Container className='bg-[#DCDCDD] h-[123px] w-[190px] rounded-[30px] flex flex-col items-start justify-between'> 
                <p className="font-semibold mt-4 text-text">2875</p>
                <p className="text-sm font-medium mb-6">Total Learners</p>
            </Container>
            <Container className='bg-[#DCDCDD] h-[123px] w-[190px] rounded-[30px] flex flex-col items-start justify-between'> 
                <p className="font-semibold mt-4 text-text">1704</p>
                <p className="text-sm font-medium mb-6">Ongoing Learners</p>
            </Container>
            <Container className='bg-[#DCDCDD] h-[123px] w-[200px] rounded-[30px] flex flex-col items-start justify-between'> 
                
                <p className="font-semibold mt-4 text-text">1200</p>
                <p className="text-sm font-medium mb-6">Completed Learners</p>
            </Container>
          </div>
            <div className="relative w-full py-4">
                {/* Tabs Component */}
                <Tabs
                defaultActiveKey="1"
                items={items}
                tabBarStyle={{
                    fontWeight: 'bold',
                    color: 'black',
                }}
                className="text-black font-medium p-4"
                />

                {/* Sort Button */}
                <div
                className="absolute top-4 right-4 flex flex-row justify-center items-start gap-x-2"
                >
                <div className="flex flex-row justify-center items-start border-2 border-gray-300 rounded-full h-10 w-24">
                    <svg
                        className="mt-3"
                        width="18"
                        height="13"
                        viewBox="0 0 18 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M6.81945 12.1001V10.241H10.5377V12.1001H6.81945ZM3.10119 7.45226V5.59313H14.256V7.45226H3.10119ZM0.3125 2.80444V0.945312H17.0447V2.80444H0.3125Z"
                        fill="#5F6368"
                        />
                    </svg>
                    <p className="font-medium text-sm ml-2 py-2">Sort</p>
                </div>
                <button className="flex justify-center items-center text-sm font-medium p-2 px-4 gap-x-2 text-primary border-2 border-gray-300  rounded-full ">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z" fill="#363A49"/>
                    </svg>
                    FIlter
                </button>
                </div>
            </div>
            <Drawer open={open} onClose={setOpen} title="Learner's Detail">
               <div className="flex flex-row justify-between items-center w-full ">
                   <span className="h-24 w-24 bg-gray-200 rounded-full"/>
                    <div className="flex-col items-start pt-5 justify-center w-[70%]">
                        <p className="font-semibold mt-4 text-left">Arun Tiwari</p>
                        <div className='flex justify-between items-center p-2 mb-8 w-full'>
                            <div>
                                <p className="text-text text-lg font-bold">Email</p>
                                <p className="text-gray-500 text-sm mt-1">arjun@gmail.com</p>
                            </div>
                            <div>
                                <p className="text-text text-lg font-bold">Mobile</p>
                                <p className="text-gray-500 text-sm mt-1">988538348</p>
                            </div>
                            <div>
                                <p className="text-text text-lg font-bold">Mobile</p>
                                <p className="text-gray-500 text-sm mt-1">984848443</p>
                            </div>
                        </div>
                    </div>
                    <button className="flex items-center rounded-full  text-primary hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
                        <EllipsisVerticalIcon aria-hidden="true" className="size-6" />
                    </button>
               </div>
               <div className="flex flex-col items-start justify-center bg-gray-200 rounded-[25px] p-4">
                    <div className="flex flex-row items-start justify-between w-full">
                    <p className="w-[60%] text-text text-lg flex-row items-start justify-center font-bold">
                        <p>Batch 7</p>
                    <p className="text-white text-center bg-primary text-[10px] w-max mt-2 border-gray-300 border-2 p-1 px-2 rounded-full">ONLINE</p>
                    </p>
                    <p className="text-gray-400 text-center text-[10px] w-max mt-2 border-gray-300 border-2 p-1 px-2 rounded-full">ONGOING</p>
                    </div>

               </div>
            </Drawer>
        </div>
      </div>
    </>
  );
}
