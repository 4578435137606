import React from "react";
import { useState } from "react";
import MobDrawer from "../../../../components/ui-components/MobDrawer";
import add from "../../../../assets/svgs/add.svg";
import MobFloatingTextField from "../../../../components/ui-components/MobFloatingTextField";
import { Button } from "../../../../components/ui-components";

function MobExperince() {
  const [open, setOpen] = useState(false);
  const [workExprienceData, setWorkExprienceData] = useState([
    {
      img: "",
      position: "Software Engineer",
      company: "Amazon",
      year: "2015-2018",
    },
  ]);
  const [formState, setFormState] = useState({
      title: "",
      company: "",
      stateDate: "",
      endDate: "",
      employmentType: "",
      industry: "",
      location: "",
    });
  
    const handleChange = (e) => {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value,
      });
    };
  
    const onFinish = (e) => {
      e.preventDefault();
      console.log(formState);
    };
  return (
    <>
      <div className="relative bg-white rounded-xl px-6 py-6">
        <button
          className="border  border-gray-300 px-2 py-1 rounded-full absolute right-4 top-6"
          onClick={() => setOpen(true)}>
          <p className="text-sm text-[#3B4350] font-medium flex flex-row gap-1 items-center justify-between">
            <span>ADD</span> <img src={add} alt="" />
          </p>
        </button>
        <h3 className="mb-8 font-bold text-lg">Work Experience</h3>

        <div className="  ">
          <div className="flex flex-col gap-4">
            {workExprienceData.map((item, index) => (
              <div
                className="border border-gray-200 rounded-xl px-4 py-4 flex flex-row gap-4 relative"
                key={index}>
                <button className="absolute right-4 top-4 underline">
                  Edit
                </button>
                <div className="bg-gray-100 rounded-full w-14 h-14"></div>
                <div className="flex flex-col justify-center">
                  <h4 className="text-base font-medium">
                    <b>{item.position}</b>
                  </h4>
                  <p className="text-gray-500 font-medium text-sm">
                    {item.company}
                  </p>
                  <p className="text-gray-500 text-sm">{item.year}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <MobDrawer open={open} onClose={setOpen} title="Add Work Experience">
        <form
          onSubmit={onFinish}
          method="POST"
          className="w-full px-5 flex flex-col justify-between h-full m-auto mt-6">
          {/* <FloatingLabel variant="filled" className='h-16 mb-1 pl-4 pr-4 w-full bg-input-background border-0 text-input-text placeholder-transparent rounded-xl' label="Email ID / Phone Number" /> */}

          <div className="flex flex-col justify-center">
            <MobFloatingTextField
              label="TITLE"
              type="text"
              onChange={handleChange}
              value={formState.title}
              name={"title"}
              id={"title"}
              // placeholder=""
              // className="w-full"
            />
            <MobFloatingTextField
              label="COMPANY NAME"
              type="text"
              value={formState.company}
              onChange={handleChange}
              name={"company"}
              id={"company"}
              // placeholder="demo@gmail.com"
            />
            <MobFloatingTextField
              label="START DATE"
              type="date"
              value={formState.startDate}
              onChange={handleChange}
              name={"startDate"}
              id={"startDate"}
            />
            <MobFloatingTextField
              label="END DATE"
              type="date"
              value={formState.endDate}
              onChange={handleChange}
              name={"endDate"}
              id={"endDate"}
            />
            <MobFloatingTextField
              label="EMPLOYMENT TYPE"
              type="text"
              value={formState.employmentType}
              onChange={handleChange}
              name={"employmentType}"}
              id={"employmentType}"}
              // placeholder="demo@gmail.com"
            />
            <div className="rounded-md px-3 pb-1.5 pt-2.5  -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600 w-full bg-[#F7F7F7] mb-4">
              {/* <label htmlFor="gender" className="absolute top-1">Gender</label> */}
              <select
                name="industry"
                className="border-none w-full bg-[#F7F7F7]"
                value={formState.industry}
                onChange={handleChange}>
                <option value="#">Industry</option>
                <option value="software">Software Development</option>
                <option value="sales">Sales</option>
                <option value="automobile">AutoMobile</option>
              </select>
            </div>
            <MobFloatingTextField
              label="LOCATION"
              type="text"
              value={formState.location}
              onChange={handleChange}
              name={"location"}
              id={"location"}
              // placeholder="demo@gmail.com"
            />
          </div>
          <Button
            type="submit"
            color="primary"
            variant="solid"
            className="w-full m-auto my-6">
            ADD
          </Button>
        </form>
      </MobDrawer>
    </>
  );
}

export default MobExperince;
