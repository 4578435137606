import React, { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Flex, Progress } from "antd";
import { Tabs } from "antd";

const courseData = [
  {
    title: "How to become a UX Designer",
    description:
      "May 24 - Aug 24 | 8 Classes | 2 classes per week | 60 min per class ",
    Batch_No: "Batch 3",
    Enrolled: "240",
    mode: "ONLINE",
    progress: 60,
    status: "Ongoing",
  },
  {
    title: "Advance UI Design Principles",
    description:
      "May 24 - Aug 24 | 8 Classes | 2 classes per week | 60 min per class ",
    Batch_No: "Batch 7",
    Enrolled: "322",
    mode: "CLASSROOM",
    progress: 80,
    status: "Completed",
  },
  {
    title: "Become a Design Leader",
    description:
      "May 24 - Aug 24 | 8 Classes | 2 classes per week | 60 min per class ",
    Batch_No: "Batch 10",
    Enrolled: "74",
    mode: "ONLINE",
    progress: 40,
    status: "Ongoing",
  },
  {
    title: "Building Effective Prototypes",
    description:
      "May 24 - Aug 24 | 8 Classes | 2 classes per week | 60 min per class",
    Batch_No: "Batch 13",
    Enrolled: "245",
    mode: "CLASSROOM",
    progress: 0,
    status: "Completed",
  },
  {
    title: "Designing for Accessibility",
    description:
      "May 24 - Aug 24 | 8 Classes | 2 classes per week | 60 min per class ",
    Batch_No: "Batch 4",
    Enrolled: "341",
    mode: "CLASSROOM",
    progress: 0,
    status: "Ongoing",
  },
];

const Card = ({
  title,
  description,
  Batch_No,
  Enrolled,
  mode,
  progress,
  status,
}) => (
  <div className="bg-white p-4 rounded-[34px] mb-4 flex justify-between h-[149px] w-full">
    <div className="bg-gray-200 h-[106px] w-[140px] rounded-[30px] flex flex-col items-start justify-between"></div>
    <div className="flex flex-col items-start justify-evenly mb-4 ml-6 w-[65%]">
      <div className="flex flex-row justify-start items-center">
        <h5 className="font-semibold text-lg pr-2">{Batch_No}</h5>
        <p className="text-gray-400 text-center text-[10px] w-max border-gray-300 border-2 p-1 rounded-full">
          {mode}
        </p>
      </div>
      <h3 className="font-semibold text-lg">{title}</h3>
      <p className="text-gray-500 text-sm">{description}</p>
    </div>
    <div className="flex flex-col justify-center items-evenly p-2 mt-2 mb-8 w-[35%]">
      <div className="flex justify-between items-start h-[80%] p-2 mb-8">
        <div>
          <p className="text-text text-lg font-bold">{Enrolled}</p>
          <p className="text-gray-500 text-sm">Enrolled</p>
        </div>
        <p className="text-gray-400 text-center text-[10px] w-[62px] mt-2 border-gray-300 border-2 p-1 rounded-full">
          {status}
        </p>
      </div>
      <Flex vertical gap="small" className="flex flex-row justify-between">
        <Progress
          percent={progress}
          strokeColor="black"
          size="small"
          percentPosition={{ align: "center", type: "outer" }}
          format={(percent) => (
            <div className="flex flex-col items-end">
              <span className="font-medium text-sm">{percent} Completed</span>
            </div>
          )}
          type="line"
          className="w-[90%]"
        />
      </Flex>
    </div>
    <Menu as="div" className="relative inline-block text-left mt-2">
      <div>
        <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
          <EllipsisVerticalIcon aria-hidden="true" className="size-6" />
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              + New Batch
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              Archive
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              Edit
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              Delete
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  </div>
);
const Tab1Content = () => {
  const [selectedStatus, setSelectedStatus] = useState(null); // Track selected filter

  const toggleFilter = (status) => {
    setSelectedStatus((prevStatus) => (prevStatus === status ? null : status));
  };

  const filteredCourses =
    selectedStatus === null
      ? courseData 
      : courseData.filter((course) => course.status === selectedStatus);

  return (
    <div className="w-full">
      <div className="w-max flex flex-row justify-center items-center pb-6">
        <button
          className={`text-gray-500 text-center text-[13px] w-max mr-4 mt-2 border-2 p-2 rounded-full ${
            selectedStatus === "Ongoing"
              ? "border-black text-white bg-black font-semibold"
              : "border-gray-300"
          }`}
          onClick={() => toggleFilter("Ongoing")}
        >
          {selectedStatus != "Ongoing" ? "Ongoing" : <div className="flex justify-between items-center">
            Ongoing <XMarkIcon className="w-5 h-5"/>
          </div> }
        </button>
        <button
          className={`text-gray-500 text-center text-[13px] w-max mt-2 border-2 p-2 rounded-full ${
            selectedStatus === "Completed"
              ? "border-black text-white bg-black font-semibold"
              : "border-gray-300"
          }`}
          onClick={() => toggleFilter("Completed")}
        >
          {selectedStatus != "Completed" ? "Completed" : <div className="flex justify-between items-center">
            Completed <XMarkIcon className="w-5 h-5"/>
          </div> }
        </button>
      </div>

      {filteredCourses.map((course, index) => (
        <Card
          key={index}
          title={course.title}
          description={course.description}
          Batch_No={course.Batch_No}
          Enrolled={course.Enrolled}
          progress={course.progress}
          status={course.status}
          mode={course.mode}
        />
      ))}
    </div>
  );
};
const Tab2Content = () => <div></div>;
const Tab3Content = () => <div></div>;
const Tab4Content = () => <div></div>;

const items = [
  {
    key: "1",
    label: "Active Courses(7)",
    children: <Tab1Content />,
  },
  {
    key: "2",
    label: "Draft",
    children: <Tab2Content />,
  },
  {
    key: "3",
    label: "Rejected",
    children: <Tab3Content />,
  },
  {
    key: "4",
    label: "Completed",
    children: <Tab4Content />,
  },
];

export default function BatchList() {
  return (
    <>
      <div className="relative w-full py-4">
        {/* Tabs Component */}
        <Tabs
          defaultActiveKey="1"
          items={items}
          tabBarStyle={{
            fontWeight: "bold",
            color: "black",
          }}
          className="text-black font-medium p-4"
        />

        <div className="absolute top-4 right-4 flex flex-row justify-center items-center">
          <div className="flex flex-row justify-center items-start border-2 mr-2 border-gray-300 rounded-full h-10 w-16">
            <p className="font-medium text-sm mr-1 py-2">All</p>

            <svg
              width="24"
              height="23"
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mt-2"
            >
              <path
                d="M6.5 8.9989L12 12.9989L17 8.9989"
                stroke="#5F6368"
                stroke-width="2"
              />
            </svg>
          </div>
          <div className="flex flex-row justify-center items-start border-2 border-gray-300 rounded-full h-10 w-24">
            <svg
              className="mt-3"
              width="18"
              height="13"
              viewBox="0 0 18 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.81945 12.1001V10.241H10.5377V12.1001H6.81945ZM3.10119 7.45226V5.59313H14.256V7.45226H3.10119ZM0.3125 2.80444V0.945312H17.0447V2.80444H0.3125Z"
                fill="#5F6368"
              />
            </svg>
            <p className="font-medium text-sm ml-2 py-2">Sort</p>
          </div>
        </div>
      </div>
    </>
  );
}
