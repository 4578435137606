import React from "react";
import moment from 'moment';
import { EditPencilTwo } from "../../../components/icons";

function EducationCard({ item, editAction}) {
  return (
    <div className="border border-gray-200 rounded-xl px-4 py-4 flex flex-row gap-4 relative">
      <button onClick={()=> editAction(item)} className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110">
        <p className="text-base ml-2">Edit</p>
        <EditPencilTwo color="black" />
      </button>
      <div className="bg-gray-100 rounded-xl w-24 h-24"></div>
      <div className="flex flex-col justify-center">
        <h4 className="text-xl font-medium mb-2">
          {item.college}
        </h4>
        <p className="text-gray-500 font-medium text-sm">{item.degree}</p>
        <p className="text-gray-500 text-sm">{moment(new Date(item.start_date)).format('YYYY')}-{moment(new Date(item.end_date)).format('YYYY')}</p>
      </div>
    </div>
  );
}

export default EducationCard;
