import zoom from '../../../../assets/images/zoom.png';
import webex from '../../../../assets/images/webex.png';
import meet from '../../../../assets/images/meet.png';
import microsoft from '../../../../assets/images/microsoft.png';
import { FloatingTextField } from '../../../../components/ui-components';
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Datepicker from "react-tailwindcss-datepicker";
import { useState } from 'react';



export const CardTile = ({ title, description, children }) => (
    <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
      <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
      <p className="text-gray-500 text-sm mb-4">{description}</p>
      <div className="w-full">{children}</div>
    </div>
  );
  


export default function EditMeetingDetails() {

  const [meetPlatform , setMeetPlatform] = useState(null)
  const [meetURL , setMeetURL] = useState(null)
  const [meetID , setMeetID] = useState(null)
  const [passCode , setPassCode] = useState(null)
  const [meetDesc , setMeetDesc] = useState('')
  const [Seats ,setSeats] = useState(null)
  const [startTime , setStartTime] = useState(null);

  const Time = ['12:00' , '12:30'  , "1:00" , '1:30' , '2:00' , '2:30']
  const countLetters = (text) => text.length;

  const [value, setValue] = useState({ 
    startDate: null, 
    endDate: null
  });

  return (
    <>
      <div className="w-full flex flex-col justify-start items-start grow overflow-y-auto px-8">
        <CardTile
        title="Select a Meeting Platform"
        description='Choose your preferred platform for hosting online sessions. This will help students seamlessly join and interact during the course.'
        >
        <div className='w-full flex flex-row justify-evenly items-center'>
            <button className='flex flex-row justify-start items-center p-2 border border-gray-300 rounded-full focus:border-2 focus:border-black focus:outline-none grayscale focus:grayscale-0 hover:grayscale-0'> <img className='w-8 h-8 mx-1' src={zoom} /> <p className='text-sm my-1 mx-1 focus:text-black'>Zoom</p> </button>
            <button className='flex flex-row justify-start items-center p-2 border border-gray-300 rounded-full focus:border-2 focus:border-black focus:outline-none grayscale focus:grayscale-0 hover:grayscale-0'> <img className='w-8 h-8 mx-1' src={meet} /> <p className='text-sm my-1 mx-1 focus:text-black'>Google Meet</p> </button>
            <button className='flex flex-row justify-start items-center p-2 border border-gray-300 rounded-full focus:border-2 focus:border-black focus:outline-none grayscale focus:grayscale-0 hover:grayscale-0'> <img className='w-8 h-8 mx-1' src={microsoft} /> <p className='text-sm my-1 mx-1 focus:text-black'>Microsoft Teams</p> </button>
            <button className='flex flex-row justify-start items-center p-2 border border-gray-300 rounded-full focus:border-2 focus:border-black focus:outline-none grayscale focus:grayscale-0 hover:grayscale-0'> <img className='w-8 h-8 mx-1' src={webex} /> <p className='text-sm my-1 mx-1 focus:text-black'>Webex Meetings</p> </button>
            <button className='flex flex-row justify-start items-center p-2 border border-gray-300 rounded-full focus:border-2 focus:border-black focus:outline-none grayscale focus:grayscale-0 hover:grayscale-0'> <p className='text-sm my-1 mx-1 focus:text-black'>Others</p> </button>
        </div>
        <div>
            <FloatingTextField
              label="Meeting Platform Name"
              type="text"
              name="Meeting Platform"
              id="Meeting Platform"
              className="w-full py-2 my-4"
              value={meetPlatform}
              onChange={(e) => setMeetPlatform(e.target.value)}
            />
            <FloatingTextField
              label="meeting URL"
              type="text"
              name="meetURL"
              id="meetURL"
              className="w-full py-2 my-4"
              value={meetURL}
              onChange={(e) => setMeetURL(e.target.value)}
            />
            <div className='flex flex-row justify-between items-center'>
                <FloatingTextField
                label="meeting ID"
                type="text"
                name="meetID"
                id="meetID"
                className="w-full py-2 my-4"
                value={meetID}
                onChange={(e) => setMeetID(e.target.value)}
                />
                <FloatingTextField
                label="passcode"
                type="number"
                name="passCode"
                id="passCode"
                className="w-full py-2 my-4"
                value={passCode}
                onChange={(e) => setPassCode(e.target.value)}
                />
            </div>
            <div className="relative">
                <FloatingTextField
                label="Write meeting Description.."
                type="text"
                name="meet Description"
                id="meetDescription"
                className="w-full min-h-60 py-2 my-4"
                value={meetDesc}
                onChange={(e) => setMeetDesc(e.target.value)}
                />
                <p className="text-end w-full text-sm text-gray-500 pt-1 pb-2">{`${countLetters(
                meetDesc
                )}/1200 max`}</p>
          </div>
        </div>
        </CardTile>
        <div className='py-6 w-full flex flex-row justify-between items-center gap-4'>
            <CardTile
            title="No of Seats"
            description='Enter the maximum number of participants for this session.'
            >
                <FloatingTextField
                label="Seats Available"
                type="number"
                name="Seats"
                id="Seats"
                className="w-full"
                value={Seats}
                onChange={(e) => setSeats(e.target.value)}
                />

            </CardTile>
            <CardTile
            title="Registration Closes On"
            description='Set the final date for learners to book this session.'
            >
            <div className='flex flex-row justify-between items-center'>
                <div className='w-[50%] mt-2 py-6'>
                    <Datepicker 
                        inputClassName="cover-fit border-0"
                        useRange={false}
                        asSingle={true}
                        placeholder="Select Date"
                        value={value} 
                        onChange={newValue => setValue(newValue)}
                        toggleClassName="hidden"
                    />
                </div>
                <Menu as="div" className="relative inline-block text-left mt-2 w-[50%]">
                    <MenuButton className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
                    <div className={`flex flex-col justify-start items-start px-4 bg-input-background rounded-lg overflow-hidden `}>
                    <input type='text' id='startTime' className="block h-10 px-4 py-1 w-full text-base text-input-text bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" placeholder='Select Time' value={startTime} />
                    </div>
                    </MenuButton>
                <MenuItems
                    transition
                    className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    {Time.map((value) => (
                    <div className="py-1">
                    <MenuItem>
                        <a
                        href="#"
                        onClick={() => {setStartTime(value)}}
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                        {value}
                        </a>
                    </MenuItem>
                    </div>
                    ))}
                </MenuItems>
                </Menu>
            </div>
            </CardTile>
        </div>
      </div>
    </>
  );
}
