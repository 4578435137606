import { useState } from "react";
import EditClassDistribution from "./EditClassDistribution";

const MileStones = [
    {
      milestone: "MILESTONE 1",
      class: [
        {
          class_name: "CLASS 1",
          chapters: [
            {
              ch_name: "CHAPTER 1",
              title: "Introduction to UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
              duration :'2 Topics . 30min'
            },
            {
              ch_name: "CHAPTER 2",
              title: "Impact of design in UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
              duration :'2 Topics . 30min'
            },
          ],
        },
        {
          class_name: "CLASS 2",
          chapters: [
            {
              ch_name: "CHAPTER 3",
              title: "Introduction to UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
              duration :'2 Topics . 30min'
            },
            {
              ch_name: "CHAPTER 4",
              title: "Impact of design in UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
              duration :'2 Topics . 30min'
            },
          ],
        },
      ],
    },
    {
      milestone: "MILESTONE 2",
      class: [
        {
          class_name: "CLASS 3",
          chapters: [
            {
              ch_name: "CHAPTER 5",
              title: "Essence of UI/UX design",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
              duration :'2 Topics . 30min'
            },
            {
              ch_name: "CHAPTER 6",
              title: "Introduction to UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
              duration :'2 Topics . 30min'
            },
            {
              ch_name: "CHAPTER 7",
              title: "Impact of design in UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
              duration :'2 Topics . 30min'
            },
          ],
        },
        {
          class_name: "CLASS 4",
          chapters: [
            {
                ch_name: "CHAPTER 8",
                title: "Essence of UI/UX design",
                subtitle: "Learn the basics of UI/UX and their importance in Design",
                duration :'2 Topics . 30min'
              },
              {
                ch_name: "CHAPTER 9",
                title: "Introduction to UI/UX",
                subtitle: "Learn the basics of UI/UX and their importance in Design",
                duration :'2 Topics . 30min'
              },
          ],
        },
        {
          class_name: "CLASS 5",
          chapters: [
            {
                ch_name: "CHAPTER 10",
                title: "Essence of UI/UX design",
                subtitle: "Learn the basics of UI/UX and their importance in Design",
                duration :'2 Topics . 30min'
              },
              {
                ch_name: "CHAPTER 11",
                title: "Introduction to UI/UX",
                subtitle: "Learn the basics of UI/UX and their importance in Design",
                duration :'2 Topics . 30min'
              },
          ],
        },
      ],
    },
  ];

export default function ClassDistribution () {
    const [showEdit , setShowEdit] = useState(false);

    return (
        <>
        <div className="w-full flex flex-col justify-start items-center grow overflow-y-auto px-8">
        {showEdit ? 
        <div className="w-[90%] py-6">
            <div className="w-full flex flex-row justify-between items-center pt-6">
          <h1 className="font-bold text-lg text-left text-secondary">
            Edit Batch Schedule
          </h1>
          <button onClick={() => {setShowEdit(false)}} className="flex justify-center items-center text-md font-medium p-3 px-8 text-white bg-primary rounded-lg ">
            Save
          </button>
        </div>
        <EditClassDistribution />
        </div>
         : 
        <div className="w-[90%]">
        <div className="w-full flex flex-row justify-between items-center pt-6">
          <h1 className="font-bold text-lg text-left text-secondary">
            Batch Schedule
          </h1>
          <button onClick={() => {setShowEdit(true)}} className="flex justify-center items-center text-sm font-medium p-2 border-2 border-gray-400 rounded-full ">
            <svg
              className="mx-2"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.54167 11.4583H2.55104L9.475 4.53438L8.46562 3.525L1.54167 10.449V11.4583ZM0.125 12.875V9.86458L9.475 0.532292C9.61667 0.402431 9.77309 0.302083 9.94427 0.23125C10.1155 0.160417 10.2955 0.125 10.4844 0.125C10.6733 0.125 10.8562 0.160417 11.0333 0.23125C11.2104 0.302083 11.3639 0.408333 11.4937 0.55L12.4677 1.54167C12.6094 1.67153 12.7127 1.825 12.7776 2.00208C12.8425 2.17917 12.875 2.35625 12.875 2.53333C12.875 2.72222 12.8425 2.90226 12.7776 3.07344C12.7127 3.24462 12.6094 3.40104 12.4677 3.54271L3.13542 12.875H0.125ZM8.96146 4.03854L8.46562 3.525L9.475 4.53438L8.96146 4.03854Z"
                fill="#505050"
              />
            </svg>
            Edit
          </button>
        </div>
        <div className="w-full h-max flex flex-col justify-center items-start">
        {MileStones.map((milestone, milestoneIndex) => (
          <div
            key={milestone.milestone}
            className="w-full flex flex-col justify-center items-center"
          >
            <p className="p-1 rounded-full border text-[13px] text-primary font-medium px-4 border-gray-400">
              {milestone.milestone}
            </p>
            {milestone.class.map((cls, classIndex) => (
              <div
                key={cls.class_name}
                className="w-full flex flex-col justify-center items-start"
              >
                <div className="w-full flex flex-row justify-center items-center py-4">
                  <span className="bg-gray-400 h-[2px] w-20 px-2" />
                  <p className="mt-2 text-primary font-medium text-[13px] mx-4">
                    {cls.class_name}
                  </p>
                  <span className="bg-gray-400 h-[2px] w-20 px-2" />
                </div>
                <ul className="w-full flex flex-col">
                    {cls.chapters.map((chapter) => (
                        <li
                        key={chapter.ch_name}
                        className="w-full flex flex-row justify-between items-center mb-4"
                        >
                        <div className="w-full flex flex-col justify-center items-center mb-4 bg-white border pb-4 pt-4 px-4 border-gray-200 rounded-[24px]">
                            <p className="w-full text-left text-sm text-gray-400 p-2">
                            {chapter.ch_name}
                            </p>
                            <div className="w-full h-max rounded-[24px]">
                            <div className="w-full flex flex-row justify-between items-center py-2 px-4">
                                <div className="w-full flex flex-col justify-center items-center px-2">
                                <p className="w-full text-2xl font-semibold">
                                    {chapter.title}
                                </p>
                                <p className="w-full text-sm text-gray-400">
                                    {chapter.subtitle}
                                </p>
                                </div>
                                <button className="w-[20%] ml-4 text-sm text-gray-500">
                                <div className="flex items-center">
                                    <p className="w-full text-sm text-gray-400">
                                        {chapter.duration}
                                    </p>
                                    <svg
                                    width="15"
                                    height="9"
                                    viewBox="0 0 15 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M1.53516 1.5L7.71583 7.5L13.8965 1.5"
                                        stroke="#2A3140"
                                        stroke-opacity="0.7"
                                        stroke-width="2"
                                    />
                                    </svg>
                                </div>
                                </button>
                            </div>
                            </div>
                        </div>
                        </li>
                    ))}
                </ul>  
              </div>
            ))}
          </div>
          ))}
        </div>
        </div>
        }
        </div>
        </>
    )
}