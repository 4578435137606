import React from "react";
import MobPersonalInfo from "./MobPersonalInfo";
import MobContainer from "../../../../components/ui-components/MobContainer";
import MobContactInfo from "./MobContactInfo";
import MobBio from "./MobBio";
import MobLanguage from "./MobLanguage";
import MobTopics from "./MobTopics";
import MobSocial from "./MobSocial";
import MobEducation from "./MobEducation";
import MobExperince from "./MobExperince";
import { useNavigate } from "react-router-dom";
import reviewLeftArrow from "../../../../assets/svgs/reviewLeftArrow.svg";

export async function MobMyProfileLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function MobMyProfile() {
  const navigate = useNavigate();
  return (
    <MobContainer className="bg-gray-100 py-16 flex flex-col gap-4">
      <div className="absolute top-4 left-4 bg-white rounded-full p-2" onClick={()=>navigate("/dashboard")}>
          <img src={reviewLeftArrow} alt="" />
        </div>
      <h1 className="text-3xl font-semibold text-[#262626] py-4">Profile</h1>
      <MobPersonalInfo />
      <MobContactInfo />
      <MobBio />
      <MobLanguage />
      <MobTopics />
      <MobSocial />
      <MobEducation />
      <MobExperince />
    </MobContainer>
  );
}

export default MobMyProfile;
