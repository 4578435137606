import React, { useState } from "react";
import WhatLearn from "./tabs/Whatlearn";
import Curriculam from "./tabs/Curriculam";
import InstructorDetails from "./tabs/InstructorDetails";
import VenueDetail from "./tabs/VenueDetail";
import { checkIsMobile } from "../../utils/helpers";

const CourseTabs = () => {
    const [activeTab, setActiveTab] = useState('tab1');
    const isMobile = checkIsMobile();
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="flex flex-col md:gap-5 md:px-6">
            <div className="relative border-b border-gray-200 mb-10">
                <nav aria-label="Tabs" className={`md:-mb-px sticky top-0 flex space-x-8 justify-start ${isMobile && "flex overflow-x-auto scroll-smooth scroll whitespace-nowrap custom-scroll"}`}>             
                    <a
                        onClick={(e) => {
                        e.preventDefault();
                        handleTabClick('tab1');
                        }}
                        className={`${activeTab == 'tab1'
                        ? 'border-gray-900 text-gray-900':'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} md:whitespace-nowrap border-b-2 px-1 py-4 text-base md:text-lg font-medium`}>
                        {'What’ll you learn'}
                    </a>
                    <a
                        onClick={(e) => {
                        e.preventDefault();
                        handleTabClick('tab2');
                        }}
                        className={`${activeTab == 'tab2'
                        ? 'border-gray-900 text-gray-900':'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} whitespace-nowrap border-b-2 px-1 py-4 text-base md:text-lg font-medium`}>
                        {'Curriculam'}
                    </a>
                    <a
                        onClick={(e) => {
                        e.preventDefault();
                        handleTabClick('tab3');
                        }}
                        className={`${activeTab == 'tab3'
                        ? 'border-gray-900 text-gray-900':'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} whitespace-nowrap border-b-2 px-1 py-4 text-base md:text-lg font-medium`}>
                        {'Instructor Detail'}
                    </a>
                    <a
                        onClick={(e) => {
                        e.preventDefault();
                        handleTabClick('tab4');
                        }}
                        className={`${activeTab == 'tab4'
                        ? 'border-gray-900 text-gray-900':'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} whitespace-nowrap border-b-2 px-1 py-4 text-base md:text-lg font-medium`}>
                        {'Venue Detail'}
                    </a>
                </nav>
            </div>
            <div className="flex flex-col gap-8 mb-10">
                <WhatLearn />
                <Curriculam />
                <InstructorDetails />
                {/* <VenueDetail /> */}
            </div>
        </div>
    );
};

export default CourseTabs;
