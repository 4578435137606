import {useState} from 'react';
import EditPrice from './EditPrice';

export const CardTile = ({ title, description, OfferPrice , free, CoursePrice, children }) => (
    <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
     <div className='w-full flex flex-row justify-between items-center'>
        <div className='flex flex-col items-start'>
            <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
            <p className="text-gray-500 text-sm mb-4">{description}</p>
        </div>
        <div className='flex flex-col items-end'>
            <h3 className="font-semibold text-lg text-gray-800 mb-2">{free ? "Free" : `₹ ${OfferPrice == '' ? '2999' : OfferPrice } `}</h3>
            <p className="text-gray-500 text-sm mb-4 flex flex-row justify-center">{free ? "" : "20% Off ₹ "}<p className='line-through'>{free ? "" : `${CoursePrice == '' ? '5000' : CoursePrice}`} </p></p>
        </div>
     </div>
      <div className="w-full">{children}</div>
    </div>
  );

  const coupons = [
    {title : 'SAVE 500' , description : 'Get an instant discount of ₹500 off on courses above ₹3,000'},
    {title : 'SAVE 500' , description : 'Get an instant discount of ₹500 off on courses above ₹3,000'},
    {title : 'SAVE 500' , description : 'Get an instant discount of ₹500 off on courses above ₹3,000'}
  ]

export default function Price () {

    const [showEdit , setShowEdit] = useState(false);
    const [values , setValues] = useState({
        isFree : false,
        OfferPrice : '45999',
        CoursePrice : "50000"
    })

    return (
        <>
        <div className="w-full flex flex-col justify-start items-center grow overflow-y-auto px-8">
        {
          showEdit ? 
          <div className="w-full flex flex-col justify-center items-start pt-6">
          <div className="w-full flex flex-row justify-between items-center pt-6">
          <h1 className="font-bold text-lg text-left text-secondary">
          Prices & Coupons
          </h1>
          <button onClick={() => {setShowEdit(false)}} className="flex justify-center items-center text-sm font-medium p-2 px-4 text-white bg-primary rounded-lg ">
            Save
          </button>
          </div>
          <EditPrice />
          </div>
          :
        <div className="w-[90%]">
        <div className="w-full flex flex-row justify-between items-center pt-6">
          <h1 className="font-bold text-lg text-left text-secondary">
            Prices & Coupons
          </h1>
          <button onClick={() => {setShowEdit(true)}} className="flex justify-center items-center text-sm font-medium p-2 border-2 border-gray-400 rounded-xl ">
            <svg
              className="mx-2"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.54167 11.4583H2.55104L9.475 4.53438L8.46562 3.525L1.54167 10.449V11.4583ZM0.125 12.875V9.86458L9.475 0.532292C9.61667 0.402431 9.77309 0.302083 9.94427 0.23125C10.1155 0.160417 10.2955 0.125 10.4844 0.125C10.6733 0.125 10.8562 0.160417 11.0333 0.23125C11.2104 0.302083 11.3639 0.408333 11.4937 0.55L12.4677 1.54167C12.6094 1.67153 12.7127 1.825 12.7776 2.00208C12.8425 2.17917 12.875 2.35625 12.875 2.53333C12.875 2.72222 12.8425 2.90226 12.7776 3.07344C12.7127 3.24462 12.6094 3.40104 12.4677 3.54271L3.13542 12.875H0.125ZM8.96146 4.03854L8.46562 3.525L9.475 4.53438L8.96146 4.03854Z"
                fill="#505050"
              />
            </svg>
            Edit
          </button>
        </div>
        <CardTile
        title="Price"
        description='Price for your online course.'
        OfferPrice={values.OfferPrice}
        CoursePrice={values.OfferPrice}
        free={values.isFree}
        >
        <div>
            <div className='w-[80%] flex flex-row justify-between items-center'>  
            {!values.isFree ? <div>
            <h3 className="font-semibold text-lg text-gray-800 my-2">Add Coupon</h3>
      
            <div className='grid grid-cols-2 gap-x-16 gap-y-4 p-3'>
                {coupons.map((coupon) => (
                    <div className='flex flex-col justify-start items-start bg-gray-50 p-4 w-[70%] '>
                        <h3 className="font-semibold text-lg text-gray-800 mb-2">{coupon.title}</h3>
                        <p className="text-gray-400 text-sm mb-4">{coupon.description}</p>
                    </div>
                ))}
            </div> 
            <button
            // onClick={() => handleAddTopic(chapter.id)}
            className="flex justify-start text-gray-800 border-2 border-gray-400 rounded-full text-sm mt-2 p-2"
            >
                <p>+ Create New Coupon</p>
            </button>
          
            </div> : 
            
          <div></div>}
        </div>
        </div>
        </CardTile>
        </div>
        }
          </div>
        </>
    )
}