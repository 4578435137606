import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Button } from "../../../components/ui-components";
import { Link } from "react-router-dom";

const InstructorDetails = () => {
  return (
    <div className="flex flex-col">
        <h5 className="text-2xl md:text-lg font-bold mb-5">Instructor Details</h5>
        <div className="flex flex-col md:flex-row gap-6">
            <div className="w-full md:w-72">
                <div className="w-full flex flex-row gap-4 md:gap-0 justify-center md:flex-col bg-gray-100 rounded-2xl px-5 py-10 md:px-5 md:py-5">
                    <div className="w-20 h-20 relative">
                        <img
                            src={require('../../../assets/images/testimonial1.jpg')}
                            alt="Uploaded Content"
                            className="w-20 h-20 rounded-full"
                        />
                        <CheckBadgeIcon className="absolute bottom-3 -right-2 size-5" />
                    </div>
                    <div className="flex flex-col gap-1 md:gap-4">
                        <h4 className="text-base font-medium text-text">Rohan Joshi</h4>
                        <p className="text-gray-600">@Microsoft</p>
                        <p className="text-gray-600">5 Years of Experience </p>
                        <p className="text-gray-600">No. Courses Conducted <b>14</b></p>
                        <Link className="w-full rounded-xl bg-black text-white text-sm font-medium text-center py-3 px-7">View Detail</Link>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col gap-4">
                <h5 className="text-text text-lg md:text-base font-medium">About</h5>
                <p className="text-gray-2 text-sm">New Delhi based UI designer and developer with more than 31 years of experience in web designing and development. I have done more than 5 big and 12 small projects for my clients. And now from the past 2 years I have been devoting myself to thes design process more and more.</p>
                <h5 className="text-text text-lg md:text-base font-medium">Languages Known</h5>
                <p className="text-gray-2 text-sm">English, Hindi</p>
                <h5 className="text-text text-lg md:text-base font-medium">Expert In</h5>
                <div className="flex flex-wrap gap-1 md:gap-4">
                    <span className="bg-white px-4 py-2 rounded-full text-text text-sm border border-gray-3">Graphic design</span>
                    <span className="bg-white px-4 py-2 rounded-full text-text text-sm border border-gray-3"> Web Design</span>
                    <span className="bg-white px-4 py-2 rounded-full text-text text-sm border border-gray-3">User Experience</span>
                    <span className="bg-white px-4 py-2 rounded-full text-text text-sm border border-gray-3">Typography</span>
                    <span className="bg-white px-4 py-2 rounded-full text-text text-sm border border-gray-3">UI design</span>
                    <span className="bg-white px-4 py-2 rounded-full text-text text-sm border border-gray-3">Prototyping</span>
                </div>
            </div>
        </div>
      </div>
  );
};

export default InstructorDetails;
