import React from "react";
import { checkIsMobile } from "../../utils/helpers";
import MobHeader from "../../components/ui-components/MobHeader";
import { Container } from "../../components/ui-components";
import InnerHeroSection from "../../components/shared-components/InnerHeroSection";
import VenueBenefits from "./VenueBenefits";
import SuccessStories from "./SucessStories";
import HowItWorks from "./HowItWorks";
import Faq from "./Faq";
import ReadyToPartner from "./ReadyToPartner";

export async function venueProviderLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function VenueProvider() {
  const isMobile = checkIsMobile();
  return (
    <>
      {isMobile && <MobHeader />}
      <section className="bg-gray-100 mx-5 md:mx-0 py-20">
        <Container>
          <InnerHeroSection
            preHeading={"Venue Provider"}
            heading={"List Your Venue and\nStart Earning Today"}
            textInfo={
              "Turn your venue into a thriving learning space for local and global communities."
            }
            buttonText={"Get Started as a Venue Provider"}
            buttonLink={"/become_instructor"}
          />
        </Container>
      </section>
      <section className="py-16">
        <Container>
          <VenueBenefits />
        </Container>
      </section>
      <section className="py-16">
        <Container>
          <SuccessStories />
        </Container>
      </section>
      <section className="md:py-16">
        <Container>
          <HowItWorks />
        </Container>
      </section>
      <section className="py-16">
        <Container>
          <Faq />
        </Container>
      </section>
      <section className="md:py-16">
        <Container>
          <ReadyToPartner />
        </Container>
      </section>
    </>
  );
}

export default VenueProvider;
