import React from "react";
import { Container } from "../../../components/ui-components";
import { Link } from "react-router-dom";
import folder from "../../../assets/svgs/folder.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon, ShareIcon } from "@heroicons/react/24/outline";

export const pollsLoader = async () => {
  return {
    date: new Date().toISOString(),
  };
};

const pollsFolder = [
  { id: 1, name: "Beginner Learners Polls", polls: "4 Polls" },
  { id: 2, name: "Intermediate Learners Polls", polls: "8 Polls" },
  { id: 3, name: "Advanced Users Feedback", polls: "3 Polls" },
  { id: 4, name: "Team Collaboration Polls", polls: "6 Polls" },
  { id: 5, name: "Engagement Polls", polls: "-" },
];

function Polls() {
  return (
    <div className="pt-24 w-full bg-gray-50 h-screen">
      <Container className="flex flex-col gap-6">
        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="md:text-xl font-medium">Polls</h1>
          <div className="flex flex-row gap-2">
            <button className="text-sm border border-gray-400 rounded-xl px-4 py-2">
              + Create Folder
            </button>
            <button className="text-sm text-white bg-[#0E121D] rounded-xl px-4 py-2">
              + Create Poll Template
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-3">
          {pollsFolder.map((item) => (
            <Link key={item.id} to={`/instructor_courses/dashboard/polls/${item.id}`} state={{pollName: item.name}} className="flex flex-row justify-between items-center py-2 border px-4 bg-white rounded-xl">
              <div className="flex flex-row items-center gap-4">
                <img
                  src={folder}
                  alt="folder"
                  className="border px-4 py-4 rounded-xl"
                />
                <p className="md:text-sm font-medium text-[#444444]">
                  {item.name}
                </p>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <p className="md:text-sm text-gray-600">{item.polls}</p>
                <Menu as="div" className="relative inline-block text-left">
                  <MenuButton className="text-gray-800 text-sm font-semibold py-2 rounded-full">
                    <EllipsisVerticalIcon className="size-5" />
                  </MenuButton>
                  <MenuItems
                    transition
                    // right-0 z-10 mt-2
                    className="absolute right-6 md:right-0 lg:right-0 xl:right-0 top-6 md:top-[44px] lg:top-[44px] xl:top-[44px] w-56 origin-top-right divide-y divide-gray-6 rounded-2xl bg-white shadow-3xl transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in flex flex-col justify-center">
                    <div className="pb-3 md:pb-0 lg:pb-0 md:py-1  px-2">
                      <MenuItem>
                        <a className="flex flex-row justify-start items-center px-4 py-2 text-base font-medium text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                          Share
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a className="flex flex-row justify-start items-center px-4 py-2 text-base font-medium text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                          Copy Link
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a className="flex flex-row justify-start items-center px-4 py-2 text-base font-medium text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                          Share via Email
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Menu>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default Polls;
