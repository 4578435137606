
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    COURSES_BEGIN:'COURSES_BEGIN',
    COURSES_ERR:'COURSES_ERR',
    COURSES_SUCCESS:'COURSES_SUCCESS'
}

export default actiontyps;