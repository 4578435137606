import React from 'react';
import linkedin from "../../assets/svgs/linkedin.svg";
import colorGoogle from "../../assets/svgs/colorGoogle.svg";
import twitter from "../../assets/svgs/twitter.svg";
import pinkball from "../../assets/svgs/pinkball.svg";

function SocialLinks() {
  return (
    <div className="border border-gray-200 rounded-xl my-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">Social Media</h3>
        <div className="flex flex-row mx-6 mb-6 gap-6">
          <img src={linkedin} alt="linkedin logo" />
          <img src={twitter} alt="twitter logo" />
          <img src={colorGoogle} alt="Google logo" />
          <img src={pinkball} alt="PinkBall logo" />
        </div>
      </div>
  )
}

export default SocialLinks
