
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    LEARNINGMODES_BEGIN:'LEARNINGMODES_BEGIN',
    LEARNINGMODES_ERR:'LEARNINGMODES_ERR',
    LEARNINGMODES_SUCCESS:'LEARNINGMODES_SUCCESS'
}

export default actiontyps;