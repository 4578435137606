import React from "react";
import { CheckBoxOutline } from "../../components/icons";

const CourseIncludes = () => {
  return (
    <div className="flex flex-col md:px-6">
      <h5 className="text-lg font-bold mb-5">Course Includes</h5>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-5">
        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">30 live Sessions</span>
        </div>
        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">5 Assignments</span>
        </div>
        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">Course Certificate</span>
        </div>
        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">Community</span>
        </div>
        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">Chat With Instructor</span>
        </div>
        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">5 Polls</span>
        </div>

        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">5 Topics</span>
        </div>
        
        <div className="flex flex-row gap-3 justify-start items-center">
          <CheckBoxOutline color={'#1B1B28'} className={'size-5 border-2 border-dark rounded-'} />
          <span className="text-base text-text font-medium">30 Live Sessions</span>
        </div>
      </div>
    </div>
  );
};

export default CourseIncludes;
