
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    ON_BOARDING_BEGIN:'ON_BOARDING_BEGIN',
    ON_BOARDING_ERR:'ON_BOARDING_ERR',
    ON_BOARDING_SUCCESS:'ON_BOARDING_SUCCESS'
}

export default actiontyps;