import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import CourseCard from "../courses/courseCard";
import { Link } from "react-router-dom";
import { Button, Carousel } from "antd";
import { checkIsMobile } from "../../utils/helpers";

const NewlyLaunchedCourses = () => {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobile = checkIsMobile();

  const goToPrev = () => {
    carouselRef.current.prev();
    setCurrentSlide(currentSlide - 1);
  };

  const goToNext = () => {
    carouselRef.current.next();
    setCurrentSlide(currentSlide + 1);
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className={`flex flex-row md:flex-col justify-between items-start gap-6 ${isMobile && "relative"}`}>
        <h5 className="text-2xl md:text-6xl font-bold ">
          Newly{isMobile && <br />} Launched Courses
        </h5>
        <a
          href="#"
          className={`rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white ${isMobile && "absolute bottom-0 right-0"}`}>
          Explore all
        </a>
        {!isMobile && (
          <div className="flex flex-row justify-center items-end gap-5">
            <div className="w-10 flex items-center">
              <Button
                type="text"
                onClick={goToPrev}
                shape="circle"
                icon={<ChevronLeftIcon className="size-7 text-dark" />}
              />
            </div>
            <div className="w-10 flex items-center">
              <Button
                type="text"
                onClick={goToNext}
                shape="circle"
                icon={<ChevronRightIcon className="size-7 text-dark" />}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex col-span-2 w-full">
        <div className="w-full">
          <Carousel
            ref={carouselRef}
            // dots
            // arrows
            draggable
            // autoplay
            // autoplaySpeed={3000}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={1}
            style={{ height: "100%" }}
            id={"review-slider"}>
            {["", "", ""].map((item, index) => (
              <div className="p-5">
                <Link to={"/courses/courseid"} key={index + 1}>
                  <CourseCard type={index} />
                </Link>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default NewlyLaunchedCourses;
