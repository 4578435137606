import React from "react";
import { Button } from "../../../components/ui-components";

function OtpVerification({setNewPassword}) {
  return (
    <div className="md:max-w-[66%] m-auto flex flex-col gap-4 md:my-16">
      <h1 className="text-3xl font-medium text-secondary">OTP Verification</h1>
      <p className="text-base text-primary">
        We've sent a six-digit code for verification to your following email ID.
        Kindly enter the code below to change the password.
      </p>
      <p className="text-base text-secondary font-medium">
        shadow007@gmail.com
      </p>

      <div className="flex flex-row  justify-between my-2">
        <input
          type="number"
          min
          className="h-14 w-14 text-center bg-input-background border-none rounded-xl"
          maxLength="1"
        />
        <input
          type="number"
          className="h-14 w-14 text-center bg-input-background border-none rounded-xl"
          maxLength="1"
        />
        <input
          type="number"
          className="h-14 w-14 text-center bg-input-background border-none rounded-xl"
          maxLength="1"
        />
        <input
          type="number"
          className="h-14 w-14 text-center bg-input-background border-none rounded-xl"
          maxLength="1"
        />
        <input
          type="number"
          className="h-14 w-14 text-center bg-input-background border-none rounded-xl"
          maxLength="1"
        />
        <input
          type="number"
          className="h-14 w-14 text-center bg-input-background border-none rounded-xl"
          maxLength="1"
        />
      </div>

      <div className="flex flex-row justify-between">
        <button className="text-sm text-primary border-b border-b-gray-500">
          Resent Code
        </button>
        <p className="text-sm text-primary">00:00</p>
      </div>

      <Button
        type="submit"
        color="primary"
        variant="solid"
        className="w-full m-auto my-10 rounded-xl" onClick={()=>setNewPassword(true)}>
        Confirm
      </Button>
    </div>
  );
}

export default OtpVerification;
