import React from "react";
import { Outlet } from "react-router-dom";


export async function createCourseLoader() {
  return {
    date: new Date().toISOString(),
  };
}

export default function CreateBatchLayout() {

  return (
    <div className="bg-gray-50 pt-16 w-full h-full">
      <section className="flex flex-col justify-center items-start w-full h-full">
        <Outlet />
      </section>
    </div>
  );
}
