import React from "react";
import { useState } from "react";
import visa from "../../../assets/svgs/visa.svg";
import masterCard from "../../../assets/svgs/masterCard.svg";
import { Button } from "../../../components/ui-components";
import { FloatingTextField } from "../../../components/ui-components";
import backArrow from "../../../assets/svgs/backArrow.svg";
import { checkIsMobile } from "../../../utils/helpers";

function AddCard({ setIsAddCard }) {
  const [formState, setFormState] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    name: "",
    zipCode: "",
  });
  const isMobile = checkIsMobile();

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formState);
  };

  return (
    <div className=" w-full flex flex-col gap-4 md:my-8">
      {!isMobile && (
        <>
          <button
            className="fixed top-8 left-6"
            onClick={() => setIsAddCard(false)}>
            <img src={backArrow} alt="back Arrow" />
          </button>
          <h1 className="text-3xl font-medium">Add Card</h1>
        </>
      )}

      <div className="w-full flex flex-row justify-between">
        <div className="flex flex-row gap-8">
          <div className="flex flex-row items-center">
            <input
              name="creditCard"
              type="radio"
              className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-gray-500 checked:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden mr-2"
            />
            <label htmlFor="creditCard" className="text-base text-primary">
              Credit
            </label>
          </div>
          <div className="flex flex-row items-center">
            <input
              name="debitCard"
              type="radio"
              className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-gray-500 checked:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden mr-2"
            />
            <label htmlFor="debitCard" className="text-base text-primary">
              Debit
            </label>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <img src={visa} alt="visa" className={`${isMobile ? "w-12" : ""}`} />
          <img src={masterCard} alt="masterCard" className={`${isMobile ? "w-10" : ""}`} />
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col md:gap-14 justify-between">
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
          <FloatingTextField
            label="Card Number"
            type="number"
            maxLength="16"
            value={formState.cardNumber}
            name="cardNumber"
            onChange={handleChange}
          />
          <FloatingTextField
            label="Expiry"
            type="date"
            value={formState.expiryDate}
            name="cardNumber"
            onChange={handleChange}
          />
          <FloatingTextField
            label="CVV"
            type="number"
            maxLength={3}
            hidden
            value={formState.cvv}
            name="cardNumber"
            onChange={handleChange}
          />
          <FloatingTextField
            label="Card holder's Name"
            type="text"
            value={formState.name}
            name="cardNumber"
            onChange={handleChange}
          />
          <FloatingTextField
            label="Zip code"
            type="number"
            value={formState.zipCode}
            name="cardNumber"
            onChange={handleChange}
          />
        </div>
        <Button
          type="submit"
          color="primary"
          variant="solid"
          className="w-[50%] m-auto my-6">
          Save
        </Button>
      </form>
    </div>
  );
}

export default AddCard;
