import { Button } from "../../../components/ui-components";
import meet from '../../../assets/images/meet.png';
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Flex, Progress } from "antd";


export default function DetailTopCard() {
  return (
    <>
      <div className="w-full flex flex-row justify-between items-start pb-8 border-b-2 border-gray-500">
        <div className="w-max px-5 py-12 flex items-center">
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 8H18" stroke="black" stroke-width="2"/>
              <path d="M8 1L2 8L8 15" stroke="black" stroke-width="2"/>
            </svg>
        </div>
        <div className="w-[50%] flex flex-col justify-start items-start py-4">
          <div className="w-full flex flex-col items-start justify-between mb-4">
            <div className="flex flex-row justify-start items-center">
              <h5 className="font-semibold text-lg pr-2">Batch 7</h5>
              <p className="text-black text-center text-[10px] w-max border-gray-600 border-[1px] p-1 px-2 rounded-full">
                ONLINE
              </p>
            </div>
            <h3 className="font-bold text-lg">
              How to become a UX Designer
            </h3>
            <p className="text-gray-500 text-sm">
              17 Chapter | Intermediate | English | Design{" "}
            </p>
          </div>
          <div className="flex justify-between items-center p-2 mb-8 w-[90%]">
            <div>
              <p className="text-text text-lg font-semibold">45/60</p>
              <p className="text-gray-500 text-sm mt-1">Enrolled</p>
            </div>
            <div>
              <p className="text-text text-lg font-semibold">
                24May 2024 - 10July 2024
              </p>
              <p className="text-gray-500 text-sm mt-1">Enrolled</p>
            </div>
            <div>
              <p className="text-text text-lg font-semibold">10 Classes</p>
              <p className="text-gray-500 text-sm mt-1">Total</p>
            </div>
            <div>
              <p className="text-text text-lg font-semibold">4.2(188)</p>
              <p className="text-gray-500 text-sm mt-1">Ratings</p>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center w-[85%]">
            <div className="flex flex-row justify-start items-center">
              <span className="w-10 h-10 bg-gray-200 rounded-full" />
              <div className="p-2">
                <p className=" text-md mt-1 font-semibold">Rohan Joshi(me)</p>
                <p className="text-gray-400 text-md">Host</p>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center">
              <span className="w-10 h-10 bg-gray-200 rounded-full" />
              <div className="p-2">
                <p className=" text-md mt-1 font-semibold">Deepu Mathew</p>
                <p className="text-gray-400 text-md">Co Host</p>
              </div>
            </div>
            <p className="text-gray-500 text-center text-[12px] w-max border-gray-400 border-2 p-1 px-2 rounded-full">
              + Add a Co-Host
            </p>
          </div>
        </div>

        <div className="w-[45%] flex flex-col items-end justify-start">
          <div className="flex justify-between items-center pb-4">
            <div className="text-sm text-black text-right px-2">
                <p>Batch creation date : 21 Nov 24, 10:30am</p>
                <p>Last Modified : 13 Dec 24, 6pm</p>
            </div>
            <div className="px-3 py-2">
                <p className="text-black text-center text-sm w-max border-gray-400 border-2 p-1 px-3 rounded-full flex justify-evenly">
                <span className="h-2 w-2 mt-2 mr-2 bg-green-500 rounded-full" />
                Active
                </p>
            </div>
          </div>
          <div className="w-[90%] flex justify-start items-start pt-8">
            <p className="text-white bg-orange-500 text-center font-semibold text-[14px] w-max border-gray-300 border-2 p-1 px-4 rounded-full">
                Class 2 – Starts in 2 Hours
            </p>
          </div>
          <div className="w-full flex flex-row justify-between items-center px-3 pb-12 border-l-[1px] border-gray-400">
            <img className='w-8 h-8 mx-1 ml-6' src={meet} />
            <div>
                <p className="text-gray-400 text-md">Google Meet Link</p>
                <p className="text-gray-400 text-md">https://meet.google.com/abc-defg-h..</p>
            </div>
            < Button className={'h-12'} text='Join Meeting' />
          </div>
          <div className="w-full flex flex-col justify-center items-end mt-2">
          <p className="text-md mt-1">TIme Zone : GMT+5:30 (IST)</p>
            <Progress
              percent={75}
              strokeColor="black"
              type="line"
              className="w-[80%]"
              format={(percent) => `${percent}% Completed`} 
            />
          </div>
        </div>
        <EllipsisVerticalIcon aria-hidden="true" className="size-6 my-3 mx-5" />
      </div>
    </>
  );
}
