import React, { useState, useEffect } from 'react';
import clsx from 'clsx'
import { useLocation } from 'react-router-dom';


const navigation = [
    { name: 'Batch Performance', href: '/instructor_courses/dashboard' },
    { name: 'Batch Schedule', href: '/Instructor_courses/dashboard/batch_details/' },
    { name: 'Class Distribution', href: '/Instructor_courses/dashboard/batch_details/class_distribution' },
    { name: 'Meeting Details', href: '/Instructor_courses/dashboard/batch_details/meeting_details' },
    { name: 'Price & Coupon', href: '/Instructor_courses/dashboard/batch_details/price' }
]

const navigationFormsAndAssessments = [
   { name: 'Attendance', href: '/instructor_courses/dashboard/course_listing' },
   { name: 'Learning Material', href: '/instructor_courses/dashboard/batch_listing' },
   { name: 'Survey', href: '/dashboard/survey' },
   { name: 'Polls', href: '/dashboard/poll' },
   { name: 'Quiz', href: '/dashboard/quiz' },
   { name: 'Assessments', href: '/dashboard/assessment' }
]

const navigationCommunication = [
   { name: 'Chat', href: '#' },
   { name: 'Reviews', href: '/Instructor_courses/dashboard/batch_details/review' },
   { name: 'Learners', href: '/Instructor_courses/dashboard/batch_details/Learners' },
]

const SidePanel = () => {
    let location = useLocation();
    const [activeRoute, setActiveRoute] = useState('');

    useEffect(() => {
    console.log("checkHome", location.pathname);
    if (location && location.pathname) {
        if(location.pathname == '/instructor_courses/course_listing'){
            setActiveRoute('/instructor_courses/course_listing');
        }else{
            setActiveRoute(location.pathname);
        }
    }
    }, []);
  
    return (
        // transition-all duration-300 ease-in-out w-96 ${filterSidebar?'ml-0':'-ml-96'}`
        <div className={`flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-500 py-8 transition-all duration-300 ease-in-out px-4`}>
        <nav className="flex flex-1 flex-col justify-evenly items-start">
            <ul role="list" className="w-full space-y-2">
                {navigation.map((item) => (
                    <li key={item.name}>
                    <a
                        href={item.href}
                        className={clsx(
                        activeRoute == item.href
                            ? 'bg-gray-4'
                            : 'hover:bg-gray-4',
                        'group flex rounded-full gap-3 px-2 py-1',
                        )}
                    >
                     
                        <p className='text-gray-500 py-2 font-sm'>{item.name}</p>
                    </a>
                    </li>
                ))}
            </ul>
            <ul role="list" className="w-full space-y-2">
                {navigationFormsAndAssessments.map((item) => (
                    <li key={item.name}>
                    <a
                        href={item.href}
                        className={clsx(
                        activeRoute == item.href
                            ? 'bg-gray-4'
                            : 'hover:bg-gray-4',
                        'group flex rounded-full gap-3 px-2 py-1',
                        )}
                    >
                        
                        <p className='text-gray-500 py-2 font-sm'>{item.name}</p>

                    </a>
                    </li>
                ))}
            </ul>
            <ul role="list" className="w-full space-y-2">
                {
                    navigationCommunication.map((item)=>(
                        <li key={item.name}>
                            <a
                                href={item.href}
                                className={clsx(
                                activeRoute == item.href
                                    ? 'bg-gray-4'
                                    : 'hover:bg-gray-4',
                                'group flex rounded-full gap-3 px-2 py-1',
                                )}
                            >
                              
                            <p className='text-gray-500 py-2 font-sm'>{item.name}</p>
                            </a>
                        </li>
                    ))
                }
            </ul>
        </nav>
    </div>
  );
};

export default SidePanel;
