import { Fragment, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import FilterSideBar from "./filterSidebar";
import { Button, Container } from "../../components/ui-components";
import CourseCard from "./courseCard";
import { Filter } from "../../components/icons";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { checkIsMobile } from "../../utils/helpers";
import reviewLeftArrow from "../../assets/svgs/reviewLeftArrow.svg";
import filter from "../../assets/svgs/filter.svg";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import store from "../../redux/store";
import { getCourses } from "../../redux/courses/actionCreator";
import CourseItemCard from "../../components/shared-components/CourseItemCard";

// const courses = [
//   {
//     name: "Download the app",
//     description: "Get an exclusive $5 off code",
//     href: "#",
//   },
//   {
//     name: "Return when you're ready",
//     description: "60 days of free returns",
//     href: "#",
//   },
//   {
//     name: "Sign up for our newsletter",
//     description: "15% off your first order",
//     href: "#",
//   },
//   {
//     name: "Sign up for our newsletter",
//     description: "15% off your first order",
//     href: "#",
//   },
//   {
//     name: "Sign up for our newsletter",
//     description: "15% off your first order",
//     href: "#",
//   },
//   {
//     name: "Sign up for our newsletter",
//     description: "15% off your first order",
//     href: "#",
//   },
// ];

const popularOptions = [
  {
    label: "Most Popular",
    key: "Popular",
  },
  {
    label: "Newly Added",
    key: "Newly Added",
  },
  {
    label: "High to Low Reviews",
    key: "reviews_high",
  },
  {
    label: "Low to High Reviews",
    key: "reviews_low",
  },
  {
    label: "Price: Low to High",
    key: "price_low",
  },
  {
    label: "Price: High to Low",
    key: "price_high",
  },
  {
    label: "Shortest Duration",
    key: "shortest_duration",
  },
  {
    label: "Longest Duration",
    key: "longest_duration",
  },
];

const getCoursesList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getCourses());
    console.log('responceresponceresponce', responce);
    if(responce && responce.type == 'COURSES_SUCCESS'){
      resolve(responce.data);
    }else{
      resolve([]);
    }
  })
}



export async function coursesLoader() {
  const courses = await getCoursesList();
  return {
    date: new Date().toISOString(),
    courses:courses
  };
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const sort = [
  { id: 1, name: "Sort" },
  { id: 2, name: "Ascending" },
  { id: 3, name: "Descending" },
];

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
];

export default function Courses() {
  const [sorting, setSorting] = useState("Popular");
  const [filterSidebar, setFilterSidebar] = useState(false);
  let loadingData = useLoaderData();
  // const [tabs, setTabs] = useState(tabsData);
  const [activeTab, setActiveTab] = useState("All");
  const tabs = ["All", "Online", "Classroom", "One on One"];
  const isMobile = checkIsMobile();
  const [selected, setSelected] = useState(sort[0]);
  const [open, setOpen] = useState(false);

  // console.log(learnersData.map((ele)=>ele.title));

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


  const applySorting = (action) => {
    setSorting(action.key);
  };

  return (
    <>
      <div className="bg-white flex flex-row justify-start items-stretch">
        <div
          className={`hidden md:flex h-full transition-all duration-300 ease-in-out w-96 ${
            filterSidebar ? "ml-0" : "-ml-96"
          }`}>
          <FilterSideBar onClose={setFilterSidebar} />
        </div>

        <div className="flex flex-col w-full">
          {isMobile && (
            <div className="flex flex-row gap-4 pt-8 sticky top-0 z-10 bg-white border-b pb-2 px-5">
              <div className="bg-white rounded-full flex justify-center items-center">
                <img src={reviewLeftArrow} alt="" className="py-2 px-3" />
              </div>
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full bg-[#F0F0F0] border-none rounded-full pl-12 py-3"
                />
                <MagnifyingGlassIcon className="w-5 absolute top-[14px] left-4 text-[#363A49]" />
              </div>
            </div>
          )}
          <div className="border-b border-b-gray-5 md:pt-6">
            <div className="flex flex-row justify-center items-center w-full p-4">
              <h3 className="text-text text-2xl md:text-3xl text-center font-medium">
                {"Guitar for Beginner"}
              </h3>
            </div>
            <div className="md:sticky top-0 bg-white">
              <Container>
                <div className=" flex flex-row justify-between w-full items-center">
                  <div className="mb-3 hidden md:flex">
                    <button
                      onClick={() => setFilterSidebar(!filterSidebar)}
                      className={
                        "bg-white border border-gray-5 rounded-full px-4 py-2 text-sm inline-flex gap-2 justify-center font-semibold text-text items-center"
                      }>
                      <Filter /> <span>Filter</span>
                    </button>
                  </div>
                  <div className="md:pt-3 m-auto md:m-0">
                    <nav
                      className={`flex md:gap-8 justify-center ${
                        isMobile &&
                        " overflow-x-scroll scroll whitespace-nowrap scroll-smooth custom-scroll"
                      }`}>
                      {tabs.map((tab) => (
                        <a
                          key={tab}
                          onClick={(e) => {
                            e.preventDefault();
                            handleTabClick(tab);
                          }}
                          className={classNames(
                            tab == activeTab
                              ? "border-gray-900 text-gray-900"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "whitespace-nowrap border-b-2 px-4 py-3 text-base md:text-lg font-medium "
                          )}>
                          {tab}
                        </a>
                      ))}
                    </nav>
                  </div>
                  <div className="hidden md:flex mb-3">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="inline-flex justify-center items-center border border-gray-5 w-full gap-2 rounded-full bg-white px-4 py-2 text-sm font-semibold text-text">
                          {sorting}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 size-5 text-gray-400"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                        <div className="py-1">
                          {popularOptions.map((item, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => applySorting(item)}>
                              <a className="flex flex-row justify-between items-center px-4 py-2 text-sm text-text data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none font-medium">
                                <span>{item.label}</span>
                                {sorting == item.key ? (
                                  <CheckIcon className="size-4" />
                                ) : null}
                              </a>
                            </MenuItem>
                          ))}
                        </div>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          {isMobile && (
            <div className="h-fit pl-2 pt-5 flex flex-row justify-start overflow-x-auto scroll gap-1 whitespace-nowrap scroll-smooth custom-scroll">
              <button
                className="h-10 rounded-3xl border"
                onClick={() => setOpen(true)}>
                <img src={filter} alt="" className="px-5 py-2 max-w-none" />
              </button>
              

              {/* sort */}
              <select
                name="sort"
                className="h-10 border border-gray-300 rounded-full w-20 px-4 py-2">
                <option value="#">Sort</option>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
              {/* rating */}
              <select
                name="Rating"
                className="h-10 border border-gray-300 rounded-full w-24 px-4 py-2">
                <option value="#">Rating</option>
                <option value="4.5 above">4.5 & Above</option>
                <option value="3 above">3 & Above</option>
                <option value="2 above">2 & Above</option>
                <option value="1 above">1 & Above</option>
              </select>
              {/* course level */}
              <select
                name="courseLevel"
                className="h-10 border border-gray-300 rounded-full w-36 pl-4 pr-8 py-2">
                <option value="#">Course Level</option>
                <option value="all">All Level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advance">Advance</option>
              </select>
              {/* Duration */}
              <select
                name="duration"
                className="h-10 border border-gray-300 rounded-full w-28 px-4 py-2">
                <option value="#">Duration</option>
                <option value="all">All</option>
                <option value="one day">One day Course</option>
                <option value="weekly">Weekly Course</option>
              </select>
              {/* Discount */}
              <select
                name="discount"
                className="h-10 border border-gray-300 rounded-full w-28 px-4 py-2">
                <option value="#">Discount</option>
                <option value="10% above">10% & Above</option>
                <option value="20% above">20% & Above</option>
                <option value="30% above">30% & Above</option>
                <option value="40% above">40% & Above</option>
                <option value="50% above">50% & Above</option>
              </select>
            </div>
          )}
          <div className="md:py-6">
            <Container>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-10 py-10">
                {loadingData.courses.map((course, index) => (
                  <Link to={"/courses/courseid"} key={index + 1}>
                    <CourseItemCard course={course} type={index} />
                  </Link>
                ))}
              </div>
            </Container>
          </div>
        </div>
      </div>

      {isMobile && (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
          <div className="fixed inset-0" style={{ background: "#00000040" }} />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <DialogPanel
                  transition
                  className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-y-full sm:duration-700  overflow-hidden">
                  <div className="w-full flex h-full flex-col overflow-y-auto bg-white  py-6 shadow-xl">
                    <div className="px-4 sm:px-6 border-b pb-4">
                      <div className="flex items-center justify-between">
                        <span className="text-xl font-medium">Filter</span>
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <FilterSideBar onClose={setFilterSidebar} />
                    </div>
                  </div>
                </DialogPanel>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
