import React, { useState } from "react";
import { Container, Button } from "../../../../components/ui-components";
import SidePanel from "../SidePanel";
import ClassroomStep1 from './steps/ClassroomStep1'
import ClassroomStep2 from './steps/ClassroomStep2'
import ClassroomStep3 from './steps/ClassroomStep3'
import ClassroomStep4 from './steps/ClassroomStep4'
import CourseSuccessful from "../CourseSuccessful";
import SelectCourse from "../SelectCourse";

export async function createBatchClassroomLoader() {
  return {
    date: new Date().toISOString(),
  };
}

export default function CreateBatchClassroom() {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 6));
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return <SelectCourse />
      case 2:
        return <ClassroomStep1 />;
      case 3:
        return <ClassroomStep2 />;
      case 4:
        return <ClassroomStep3 />;
      case 5:
        return <ClassroomStep4 />;
      case 6:
        return <CourseSuccessful />;
      default:
        return null;
    }
  };

  const isSidePanelVisible = currentStep > 1 && currentStep <= 5;


  const buttonText = (() => {
    if (currentStep === 1) return "Continue"; 
    if (currentStep === 5) return "Review & Submit";
    if (currentStep === 6) return null; 
    return "Next ->";
  })();

  return (
    <div className={`${currentStep == 1 ? 'bg-white pt-12' : 'bg-[#EFEFEF]  h-full pt-24'} w-full flex flex-col items-center justify-center overflow-hidden`}>
      <section className="flex flex-row justify-center items-start w-full h-full">
        {isSidePanelVisible && (
          <Container className="w-[25%]">
            <SidePanel current_step={currentStep - 1} batchType={'Classroom'} />
          </Container>
        )}

        <div className={`w-full h-full flex flex-col justify-start items-start px-6 ${isSidePanelVisible ? "w-[80%]" : "w-full"}`}>
          {renderContent()} 
          {currentStep < 6 && ( 
            <footer className={`w-full flex ${currentStep == 1 ? 'sticky bottom-0 justify-center bg-white' : 'justify-end'} items-center py-4`}>
              <Button
                onClick={nextStep}
                text={buttonText} 
                className="min-w-32 w-max h-[50px]"
              />
            </footer>
          )}
        </div>
      </section>
    </div>
  );
}
