import React, { useEffect, useRef, useState } from "react";
import Sortable from "sortablejs";

export const CardTile = ({ title, description, children }) => (
  <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
    <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
    <p className="text-gray-500 text-sm mb-4">{description}</p>
    <div className="w-full">{children}</div>
  </div>
);

const SortableList = ({ title, description }) => {
  const sortableRef = useRef(null);
  const [items, setItems] = useState([
    { id: 1, title: "Untitled", subtitle: "Description" },
  ]);

  const countLetters = (text) => text.length;

  const handleAddItem = () => {
    setItems((prevItems) => [
      ...prevItems,
      {
        id: Date.now(),
        title: "Untitled",
        subtitle: "Description",
      },
    ]);
  };

  const handleTitleChange = (id, newTitle) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, title: newTitle } : item
      )
    );
  };

  const handleSubtitleChange = (id, newSubtitle) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, subtitle: newSubtitle } : item
      )
    );
  };

  useEffect(() => {
    if (sortableRef.current) {
      const sortable = new Sortable(sortableRef.current, {
        animation: 150,
        handle: ".drag-handle",
        onEnd: (evt) => {
          const newOrder = [...items];
          if (
            evt.oldIndex !== undefined &&
            evt.newIndex !== undefined &&
            evt.oldIndex >= 0 &&
            evt.newIndex >= 0 &&
            evt.oldIndex < newOrder.length &&
            evt.newIndex < newOrder.length
          ) {
            const [movedItem] = newOrder.splice(evt.oldIndex, 1);
            newOrder.splice(evt.newIndex, 0, movedItem);
            setItems(newOrder);
          }
        },
      });
  
      return () => {
        sortable.destroy();
      };
    }
  }, [items]);
  

  return (
    <CardTile title={title} description={description}>
      <ul
        ref={sortableRef}
        className="w-full flex flex-col"
      >
        {items.map((item, index) => (
          <li
            key={item.id}
            className="w-full flex items-center justify-start gap-x-3 py-3 px-4 cursor-grab text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg"
          >
            <svg
              className="w-[5%] shrink-0 drag-handle ms-auto text-gray-400 dark:text-neutral-500"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="9" cy="12" r="1"></circle>
              <circle cx="9" cy="5" r="1"></circle>
              <circle cx="9" cy="19" r="1"></circle>
              <circle cx="15" cy="12" r="1"></circle>
              <circle cx="15" cy="5" r="1"></circle>
              <circle cx="15" cy="19" r="1"></circle>
            </svg>
            <div className="w-full flex flex-col justify-center items-center bg-input-background rounded-lg overflow-hidden">
              <div className="w-full flex flex-row item-center justify-between">
                <input
                  type="text"
                  value={item.title}
                  onChange={(e) => handleTitleChange(item.id, e.target.value)}
                  className="block h-max px-4 pb-2 pt-4 w-full text-2xl text-input-text placeholder-transparent bg-input-background border-0 appearance-none focus:outline-none focus:ring-0 focus:border-0 peer"
                  placeholder="Untitled"
                />
                <p className="text-end p-2 w-full text-sm text-gray-400 pt-1">
                  {`${countLetters(item.title)}/50 max`}
                </p>
              </div>
              <div className="w-full flex flex-row item-center justify-between">
                <input
                  type="text"
                  value={item.subtitle}
                  onChange={(e) => handleSubtitleChange(item.id, e.target.value)}
                  className="block h-max px-4 pb-2.5 pt-2 w-full text-sm text-gray-400 placeholder-transparent bg-input-background border-0 appearance-none focus:outline-none focus:ring-0 focus:border-0 peer"
                  placeholder="Description"
                />
                <p className="invisible p-2 text-end w-full text-sm text-gray-400 pb-2">
                  {`${countLetters(item.title)}/50 max`}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <button
        onClick={handleAddItem}
        className="text-gray-400 text-center ml-12 mt-4 text-[15px] w-max border-gray-300 border-2 p-2 rounded-full"
      >
        + Add
      </button>
    </CardTile>
  );
};

export default function Step2() {
  return (
    <div className="w-full flex flex-col justify-start items- grow overflow-y-auto px-8">
      <h1 className="font-medium text-lg text-left text-secondary mb-4">
        Course Essentials
      </h1>

      <SortableList
        title="Target Audience"
        description="List up to 6 key skills and knowledge areas learners will gain from this course, highlighting the most impactful takeaways."
      />
      <SortableList
        title="Key Takeaways"
        description="Identify the primary goals and outcomes your learners can expect from completing this course."
      />
      <SortableList
        title="Prerequisites"
        description="Outline any necessary prior knowledge or skills required for learners to excel in this course."
      />
    </div>
  );
}
