import React, { useEffect, useRef, useState } from "react";
import Sortable from "sortablejs";
import { Container } from "../../../components/ui-components";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import { MinusSmallIcon } from "@heroicons/react/20/solid";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { type } from "@testing-library/user-event/dist/type";

export const pollTemplateLoader = async () => {
  return {
    date: new Date().toISOString(),
  };
};

const addQuestion = [
  { id: 1, title: "Yes / No", type: "yes_no" },
  { id: 2, title: "Single Selection", type: "single_selection" },
  { id: 3, title: "Multiple Selection", type: "multiple_selection" },
  { id: 4, title: "DropDown selection", type: "dropDown" },
  { id: 5, title: "Date selection", type: "date_selection" },
  { id: 6, title: "1 - 10 Score", type: "score" },
  { id: 7, title: "Like", type: "like" },
];

// export const CardTile = ({ title, description, children }) => (
//   <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
//     <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
//     <p className="text-gray-500 text-sm mb-4">{description}</p>
//     <div className="w-full">{children}</div>
//   </div>
// );

// const SortableList = ({ title, description }) => {
//   const sortableRef = useRef(null);
//   const [items, setItems] = useState([
//     { id: 1, title: "Untitled", subtitle: "Description" },
//   ]);

//   const countLetters = (text) => text.length;

//   const handleAddItem = () => {
//     setItems((prevItems) => [
//       ...prevItems,
//       {
//         id: Date.now(),
//         title: "Untitled",
//         subtitle: "Description",
//       },
//     ]);
//   };

//   const handleTitleChange = (id, newTitle) => {
//     setItems((prevItems) =>
//       prevItems.map((item) =>
//         item.id === id ? { ...item, title: newTitle } : item
//       )
//     );
//   };

//   const handleSubtitleChange = (id, newSubtitle) => {
//     setItems((prevItems) =>
//       prevItems.map((item) =>
//         item.id === id ? { ...item, subtitle: newSubtitle } : item
//       )
//     );
//   };

//   useEffect(() => {
//     if (sortableRef.current) {
//       const sortable = new Sortable(sortableRef.current, {
//         animation: 150,
//         handle: ".drag-handle",
//         onEnd: (evt) => {
//           const newOrder = [...items];
//           if (
//             evt.oldIndex !== undefined &&
//             evt.newIndex !== undefined &&
//             evt.oldIndex >= 0 &&
//             evt.newIndex >= 0 &&
//             evt.oldIndex < newOrder.length &&
//             evt.newIndex < newOrder.length
//           ) {
//             const [movedItem] = newOrder.splice(evt.oldIndex, 1);
//             newOrder.splice(evt.newIndex, 0, movedItem);
//             setItems(newOrder);
//           }
//         },
//       });

//       return () => {
//         sortable.destroy();
//       };
//     }
//   }, [items]);

//   return (
//     <CardTile title={title} description={description}>
//       <ul ref={sortableRef} className="w-full flex flex-col">
//         {items.map((item, index) => (
//           <li
//             key={item.id}
//             className="w-full flex items-center justify-start gap-x-3 py-3 px-4 cursor-grab text-sm font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg">
//             <svg
//               className="w-[5%] shrink-0 drag-handle ms-auto text-gray-400 dark:text-neutral-500"
//               xmlns="http://www.w3.org/2000/svg"
//               width="24"
//               height="24"
//               viewBox="0 0 24 24"
//               fill="none"
//               stroke="currentColor"
//               strokeWidth="2"
//               strokeLinecap="round"
//               strokeLinejoin="round">
//               <circle cx="9" cy="12" r="1"></circle>
//               <circle cx="9" cy="5" r="1"></circle>
//               <circle cx="9" cy="19" r="1"></circle>
//               <circle cx="15" cy="12" r="1"></circle>
//               <circle cx="15" cy="5" r="1"></circle>
//               <circle cx="15" cy="19" r="1"></circle>
//             </svg>
//             <div className="w-full flex flex-col justify-center items-center bg-input-background rounded-lg overflow-hidden">
//               <div className="w-full flex flex-row item-center justify-between">
//                 <input
//                   type="text"
//                   value={item.title}
//                   onChange={(e) => handleTitleChange(item.id, e.target.value)}
//                   className="block h-max px-4 pb-2 pt-4 w-full text-2xl text-input-text placeholder-transparent bg-input-background border-0 appearance-none focus:outline-none focus:ring-0 focus:border-0 peer"
//                   placeholder="Untitled"
//                 />
//                 <p className="text-end p-2 w-full text-sm text-gray-400 pt-1">
//                   {`${countLetters(item.title)}/50 max`}
//                 </p>
//               </div>
//               <div className="w-full flex flex-row item-center justify-between">
//                 <input
//                   type="text"
//                   value={item.subtitle}
//                   onChange={(e) =>
//                     handleSubtitleChange(item.id, e.target.value)
//                   }
//                   className="block h-max px-4 pb-2.5 pt-2 w-full text-sm text-gray-400 placeholder-transparent bg-input-background border-0 appearance-none focus:outline-none focus:ring-0 focus:border-0 peer"
//                   placeholder="Description"
//                 />
//                 <p className="invisible p-2 text-end w-full text-sm text-gray-400 pb-2">
//                   {`${countLetters(item.title)}/50 max`}
//                 </p>
//               </div>
//             </div>
//           </li>
//         ))}
//       </ul>
//       <button
//         onClick={handleAddItem}
//         className="text-gray-400 text-center ml-12 mt-4 text-[15px] w-max border-gray-300 border-2 p-2 rounded-full">
//         + Add
//       </button>
//     </CardTile>
//   );
// };

function PollTemplate() {
  const [questions, setQuestions] = useState([]);
  const sortableRef = useRef(null);

  useEffect(() => {
    if (sortableRef.current) {
      const sortable = new Sortable(sortableRef.current, {
        animation: 150,
        handle: ".drag-handle",
        onEnd: (evt) => {
          const newOrder = [...questions];
          if (
            evt.oldIndex !== undefined &&
            evt.newIndex !== undefined &&
            evt.oldIndex >= 0 &&
            evt.newIndex >= 0 &&
            evt.oldIndex < newOrder.length &&
            evt.newIndex < newOrder.length
          ) {
            const [movedItem] = newOrder.splice(evt.oldIndex, 1);
            newOrder.splice(evt.newIndex, 0, movedItem);
            setQuestions(newOrder);
          }
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [questions]);

  const handleAddQuestion = (type) => {
    const newQuestion = {
      id: Math.random() + Date.now(),
      type,
      title: "Question Title",
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleDeleteQuestion = (id) => {
    const updatedQuestions = questions.filter((question) => question.id !== id);
    setQuestions(updatedQuestions);
  };

  return (
    <div className="w-full h-screen flex flex-col justify-start items-grow overflow-y-auto px-8 pt-24 bg-gray-100">
      <Container className="max-w-[975px] w-full">
        <div className="m-auto flex flex-col bg-white my-4 p-5 rounded-2xl">
          <input
            type="text"
            placeholder="Untitled Poll"
            className="border-none text-[26px] text-gray-500 pb-0"
          />
          <input
            type="text"
            placeholder="Description"
            className="border-none text-base text-gray-400 pt-0"
          />
        </div>
        {questions.length !== 0 && (
          <div className="bg-white p-5 rounded-2xl">
            <ul ref={sortableRef} className="w-full flex flex-col gap-2">
              {questions.map((question, index) => (
                <li
                  key={question.id}
                  className="flex items-center justify-between gap-3 bg-gray-50 border border-gray-200 p-4 rounded-lg shadow-sm cursor-grab">
                  <div className="flex items-center gap-3">
                    {/* Drag Handle */}
                    <div className="drag-handle w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <circle cx="9" cy="12" r="1"></circle>
                        <circle cx="9" cy="5" r="1"></circle>
                        <circle cx="9" cy="19" r="1"></circle>
                        <circle cx="15" cy="12" r="1"></circle>
                        <circle cx="15" cy="5" r="1"></circle>
                        <circle cx="15" cy="19" r="1"></circle>
                      </svg>
                    </div>
                    {/* Question Details */}
                    <div>
                      <h4 className="font-medium text-gray-800">
                        {question.title}
                      </h4>
                      <p className="text-sm text-gray-500">{question.type}</p>
                    </div>
                  </div>
                  {/* Delete Button */}
                  <button
                    onClick={() => handleDeleteQuestion(question.id)}
                    className="text-red-500 hover:text-red-700"
                    title="Delete">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <Disclosure as="div" className="p-5 bg-white rounded-2xl">
          <dt>
            <DisclosureButton className="group flex gap-2 w-full items-start text-left text-gray-900">
              <span className="flex h-7 items-center">
                <PlusSmallIcon
                  aria-hidden="true"
                  className="size-5 group-data-[open]:hidden bg-black text-white rounded-full"
                />
                <svg
                  className="size-5 group-[&:not([data-open])]:hidden bg-black p-1 rounded-full"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.36242 6.02133L2.09348 9.29027C1.9473 9.43645 1.7812 9.50621 1.59516 9.49957C1.40912 9.49292 1.24302 9.41651 1.09685 9.27034C0.950673 9.12417 0.877587 8.95474 0.877587 8.76206C0.877587 8.56938 0.950673 8.39995 1.09685 8.25378L4.34586 5.00477L1.07691 1.73582C0.930741 1.58965 0.860977 1.42022 0.867621 1.22754C0.874265 1.03486 0.950673 0.865431 1.09685 0.719259C1.24302 0.573086 1.41245 0.5 1.60513 0.5C1.79781 0.5 1.96724 0.573086 2.11341 0.719259L5.36242 3.9882L8.63137 0.719259C8.77754 0.573086 8.94697 0.5 9.13965 0.5C9.33233 0.5 9.50176 0.573086 9.64793 0.719259C9.7941 0.865431 9.86719 1.03486 9.86719 1.22754C9.86719 1.42022 9.7941 1.58965 9.64793 1.73582L6.37898 5.00477L9.64793 8.27371C9.7941 8.41988 9.86719 8.58599 9.86719 8.77203C9.86719 8.95807 9.7941 9.12417 9.64793 9.27034C9.50176 9.41651 9.33233 9.4896 9.13965 9.4896C8.94697 9.4896 8.77754 9.41651 8.63137 9.27034L5.36242 6.02133Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span className="text-sm/7 font-semibold">Add Question</span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" className="mt-4 grid grid-cols-3 gap-3">
            {addQuestion.map((item) => (
              <button
                className="flex flex-row gap-x-2 items-center border p-2 rounded-xl"
                key={item.id}
                onClick={() => handleAddQuestion(item.type)}>
                <div className="bg-gray-100 w-10 h-10 rounded-lg"></div>
                <span className="text-base text-secondary font-medium">
                  {item.title}
                </span>
              </button>
            ))}
          </DisclosurePanel>
        </Disclosure>
      </Container>

      {/* <SortableList /> */}
    </div>
  );
}

export default PollTemplate;
