import React from "react";
import SidePanel from "../SidePanel";
import DetailTopCard from "../DetailTopCard";
import { Outlet } from "react-router-dom";
// import BatchSchedule from './BatchSchedule';
// import ClassDistribution from './ClassDistribution';
// import MeetingDetails from "./MeetingDetails";
// import Price from "./Price";
// import Review from "./Review";
// import Learner from "./Learner";

export async function batchDetailsOnlineLoader() {
  return {
    date: new Date().toISOString(),
  };
}

export default function BatchDetailsOnline() {



  return (
    <div className={`h-full pt-24 w-full flex flex-col items-center justify-center overflow-hidden`}>
      <section className="w-full h-max">
          <DetailTopCard />
      </section>
      <section className="flex flex-row justify-start items-start w-full h-full">
        <div className="w-[20%]">
            <SidePanel />
        </div>

        <div className={`h-full flex flex-col justify-start items-start px-6 w-[80%]`}>
         <Outlet/>
        </div>
      </section>
    </div>
  );
}
