
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SiteHeader from './header';
import SiteFooter from './footer';
import { useLocation } from 'react-router-dom';
import Notification from '../components/ui-components/Notifications';

const ThemeLayout = () => {
  const [fetching, setFetching] = useState(true);
  const [isChat, setIsChat] = useState(false);

   let location = useLocation();
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    useEffect(() => {
      console.log("checkHome", location);
      if (location && (location.pathname.includes("/chat")  || location.pathname.includes("/dashboard")) ) {
        setIsChat(true);
      }else{
        setIsChat(false);
      }
    }, [location]);

  return (
    <div className="bg-white relative pb-16 md:pt-16">
      <SiteHeader />
      <main>
        <Outlet />
        <Notification open={false} autoClose={false} type={'info_with_action'} action={()=>{}} />
      </main> 
      {
        isChat ? null : <SiteFooter />
      } 
    </div>
  );
};

export default ThemeLayout;
