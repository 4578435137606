import React from "react";
import { useState } from "react";
import { EditPencilTwo } from "../../../../components/icons";
import MobDrawer from "../../../../components/ui-components/MobDrawer";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

function MobTopics() {
  const [open, setOpen] = useState(false);
  const [topics, setTopics] = useState([
    {name: "Web Development", checked: true},
    {name: "Mobile Development", checked: true},
    {name: "Leadership", checked: false},
    {name: "Programming Languages", checked: true},
    {name: "Career Development", checked: false},
    {name: "Digital Marketing", checked: false},
    {name: "Python", checked: false},
  ]);
  return (
    <>
      <div className="bg-white rounded-xl relative pb-4">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">
          Interested Topics
        </h3>
        <button
          className="border  border-gray-300 px-1 py-1 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center"
          onClick={() => setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <div className="flex flex-row px-6 mb-4 gap-1 flex-wrap">
          <button className="border border-gray-200 px-2 py-1 h-fit rounded-3xl hover:bg-gray-300 text-sm">
            Web Development
          </button>
          <button className="border border-gray-200 px-2 py-1 h-fit rounded-3xl hover:bg-gray-300 text-sm">
            Mobile Development
          </button>
          <button className="border border-gray-200 px-2 py-1 h-fit rounded-3xl hover:bg-gray-300 text-sm">
            Leadership
          </button>
          <button className="border border-gray-200 px-2 py-1 h-fit rounded-3xl hover:bg-gray-300 text-sm">
            Programming Languages
          </button>
          <button className="border border-gray-200 px-2 py-1 h-fit rounded-3xl hover:bg-gray-300 text-sm">
            Career Development
          </button>
          <button className="border border-gray-200 px-2 py-1 h-fit rounded-3xl hover:bg-gray-300 text-sm">
            Digital Marketing
          </button>
        </div>
      </div>

      <MobDrawer
        open={open}
        onClose={setOpen}
        title="Interested Topics">
          <fieldset>
          <div className="relative w-full">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id={"head-search"}
              type={"text"}
              placeholder="Search for a Topics"
              // onClick={() => setOpen(true)}
              // readOnly
              className={
                "bg-white border text-sm border-gray-400 rounded-4xl w-full  h-10 pl-8 text-input-text placeholder:text-input-placeholder"
              }
            />
          </div>
          <div className="mt-6 space-y-6">
              {
                topics.map((item,index)=>(
                  <div key={index} className="border-b flex flex-row items-center justify-between gap-2 py-3">
                    <label htmlFor={item.name} className="text-base text-secondary">{item.name}</label>
                    <input type="checkbox" checked={item.checked} name={item.name} className="text-black outline-gray-400" />
                  </div>
                ))
              }
          </div>
        </fieldset>
        </MobDrawer>
    </>
  );
}

export default MobTopics;
