import React from "react";
import { Container } from "../../components/ui-components";
import NotificationHeader from "./NotificationHeader";
import TodayNotifications from "./TodayNotifications";
import YesterdayNotifications from "./YesterdayNotifications";
import LastWeekNotification from "./LastWeekNotification";
import { checkIsMobile } from "../../utils/helpers";

export async function notificationPageLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function NotificationPage() {
  const isMobile = checkIsMobile();
  return (
    <>
      {!isMobile && (
        <section className={`md:py-4`}>
          <Container className={"max-w-3xl"}>
            <NotificationHeader />
          </Container>
        </section>
      )}

      <section className="md:py-4">
        <Container className={"max-w-3xl"}>
          <div className="flex flex-col gap-6">
            <TodayNotifications />
            <YesterdayNotifications />
            <LastWeekNotification />
          </div>
        </Container>
      </section>
    </>
  );
}

export default NotificationPage;
