import React from "react";
import userCircle from "../../../assets/svgs/userCircle.svg";
import { EditPencilTwo } from "../../../components/icons";

function ProfileCard({profile}) {
  return (
    <>
      <div className="bg-white py-5 rounded-xl flex flex-col gap-2 justify-center items-center">
        <div className="relative mb-3">
          <img src={profile.profileImageFileId?profile.profileImageFileId:userCircle} alt="User Profile" />
          <button className="absolute bottom-0 right-4 flex justify-center items-center w-10 px-1 py-1 border border-gray-300 bg-white rounded-full">
            <EditPencilTwo color="black" />
          </button>
        </div>

        <h3 className="text-lg font-semibold">{profile?.name}</h3>

        <div className="flex flex-col justify-start gap-2">
          <div className="flex flex-row items-center px-8 gap-2 hover:bg-gray-100 mx-2 rounded-lg">
            <div className="bg-gray-200 w-4 h-4 rounded-md top-0 left-16"></div>
            <button className="py-1 rounded-xl text-base font-medium">
              Profile
            </button>
          </div>
          <div className="flex flex-row items-center px-8 gap-2 hover:bg-gray-100 mx-2 rounded-lg">
            <div className="bg-gray-200 w-4 h-4 rounded-md top-0 left-16"></div>
            <button className="py-1  rounded-xl text-base font-medium">
              Education
            </button>
          </div>
          <div className="flex flex-row items-center px-8 gap-2 hover:bg-gray-100 mx-2 rounded-lg">
            <div className="bg-gray-200 w-4 h-4 rounded-md top-0 left-16"></div>
            <button className="py-1 rounded-xl text-base font-medium whitespace-nowrap">
              Work Experience
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileCard;
