import React from "react";
import { useState } from "react";
import MobDrawer from "../../../../components/ui-components/MobDrawer";
import add from "../../../../assets/svgs/add.svg";
import { Button, DropDownField } from "../../../../components/ui-components";
import MobFloatingTextField from "../../../../components/ui-components/MobFloatingTextField";

function MobEducation() {
  const [open, setOpen] = useState(false);
  const [educationData, setEducationData] = useState([
    {
      img: "",
      university: "IASE University",
      degree: "Diploma In Computer Science",
      year: "2015-2018",
    },
    {
      img: "",
      university: "IGNOU University",
      degree: "Bachlor In Computer Science",
      year: "2015-2018",
    },
  ]);
  const [formState, setFormState] = useState({
      degree: "",
      college: "",
      fieldOfStudy: "",
      startDate: "",
      endDate: "",
    });
  
    const handleChange = (e) => {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value,
      });
    };
  
    const onFinish = (e) => {
      e.preventDefault();
      console.log(formState);
    };
  return (
    <>
      <div className="relative bg-white rounded-xl px-6 py-6">
        <button
          className="border  border-gray-300 px-2 py-1 rounded-full absolute right-4 top-6"
          onClick={() => setOpen(true)}>
          <p className="text-sm text-[#3B4350] font-medium flex flex-row gap-1 items-center justify-between">
            <span>ADD</span> <img src={add} alt="" />
          </p>
        </button>
        <h3 className="mb-8 font-bold text-lg">Education</h3>

        <div className="  ">
          <div className="flex flex-col gap-4">
            {educationData.map((item, index) => (
              <div
                className="border border-gray-200 rounded-xl px-4 py-4 flex flex-row gap-4 relative"
                key={index}>
                <button className="absolute right-4 top-4 underline">
                  Edit
                </button>
                <div className="bg-gray-100 rounded-full w-14 h-14"></div>
                <div className="flex flex-col justify-center">
                  <h4 className="text-base font-medium">
                    {item.university}
                  </h4>
                  <p className="text-gray-500 font-medium text-sm">
                    {item.degree}
                  </p>
                  <p className="text-gray-500 text-sm">{item.year}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <MobDrawer
        open={open}
        onClose={setOpen}
        title="Add Education">
        <form
          onSubmit={onFinish}
          method="POST"
          className="w-full flex flex-col justify-between h-full px-5 m-auto mt-6">
          {/* <FloatingLabel variant="filled" className='h-16 mb-1 pl-4 pr-4 w-full bg-input-background border-0 text-input-text placeholder-transparent rounded-xl' label="Email ID / Phone Number" /> */}
          <div className="flex flex-col justify-center">
            <DropDownField
              options={[
                { key: 1, value: "Bachlor" },
                { key: 2, value: "Masters" },
                { key: 3, value: "PHD" },
              ]}
              label="DEGREE"
              name={"degree"}
              id={"degree"}
              placeholder="degree"
              error={""}
              value={formState.degree}
            />
            <MobFloatingTextField
              label="COLLEGE"
              type="text"
              value={formState.college}
              onChange={handleChange}
              name={"college"}
              id={"college"}
              // placeholder="demo@gmail.com"
            />
            <MobFloatingTextField
              label="FIELD OF STUDY"
              type="text"
              value={formState.fieldOfStudy}
              onChange={handleChange}
              name={"fieldOfStudy"}
              id={"fieldOfStudy"}
              // placeholder="demo@gmail.com"
            />
            <MobFloatingTextField
              label="START DATE"
              type="date"
              value={formState.startDate}
              onChange={handleChange}
              name={"startDate"}
              id={"startDate"}
            />
            <MobFloatingTextField
              label="END DATE"
              type="date"
              value={formState.endDate}
              onChange={handleChange}
              name={"endDate"}
              id={"endDate"}
            />
          </div>

          <Button
            type="submit"
            color="primary"
            variant="solid"
            className="w-full m-auto my-6">
            ADD
          </Button>
        </form>
      </MobDrawer>
    </>
  );
}

export default MobEducation;
