import React from "react";
import { DividerWithLeftTitle } from "../../../components/ui-components";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ArrowDownCircleIcon, ChevronDownIcon, ChevronUpIcon, MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { ArrowUpCircleIcon } from "@heroicons/react/20/solid";
import { checkIsMobile } from "../../../utils/helpers";

const Curriculam = () => {
    const isMobile = checkIsMobile();
  return (
    <div className="flex flex-col">
        <h5 className="text-2xl md:text-3xl text-text font-bold md:mb-5">Curriculam</h5>
        <div className="grid grid-cols-1 gap-8">
            <div>
                <DividerWithLeftTitle>MILESTONE 1</DividerWithLeftTitle>
                <div className="flex flex-col gap-3">
                    <div className='p-3 rounded-lg bg-gray-3'>
                        <Disclosure as="div" className="">
                            <DisclosureButton className="relative group flex flex-col md:flex-row w-full items-start justify-between text-left text-gray-900">
                                <span className="text-lg font-semibold">{'What is UI/UX & Benefits'}</span>
                                <div className=" md:ml-6 flex gap-4 md:h-7 justify-between items-center">
                                    <span className="text-base text-gray-1">2 Topics . 30min</span>
                                    <span className={`md:mt-1 ${isMobile && "absolute right-0 top-2"}`}>
                                        <ChevronDownIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                        <ChevronUpIcon aria-hidden="true" className="size-5 group-[&:not([data-open])]:hidden" />
                                    </span>
                                </div>
                            </DisclosureButton>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-sm text-text">{'Explicabo nihil laborum. Saepe facilis consequuntur in eaque. Consequatur perspiciatis quam. Sed est illo quia.'}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                    <div className='p-3 rounded-lg bg-gray-3'>
                        <Disclosure as="div" className="">
                            <DisclosureButton className="relative group flex flex-col md:flex-row w-full items-start justify-between text-left text-gray-900">
                                <span className="text-lg font-semibold">{'What is UI/UX & Benefits'}</span>
                                <div className="md:ml-6 flex gap-4 md:h-7 justify-between items-center">
                                    <span className="text-base text-gray-1">2 Topics . 30min</span>
                                    <span className={`md:mt-1 ${isMobile && "absolute right-0 top-2"}`}>
                                        <ChevronDownIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                        <ChevronUpIcon aria-hidden="true" className="size-5 group-[&:not([data-open])]:hidden" />
                                    </span>
                                </div>
                            </DisclosureButton>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-sm text-text">{'Explicabo nihil laborum. Saepe facilis consequuntur in eaque. Consequatur perspiciatis quam. Sed est illo quia.'}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                    <div className='p-3 rounded-lg bg-gray-3'>
                        <Disclosure as="div" className="">
                            <DisclosureButton className="relative group flex flex-col md:flex-row w-full items-start justify-between text-left text-gray-900">
                                <span className="text-lg font-semibold">{'What is UI/UX & Benefits'}</span>
                                <div className="md:ml-6 flex gap-4 md:h-7 justify-between items-center">
                                    <span className="text-base text-gray-1">2 Topics . 30min</span>
                                    <span className={`md:mt-1 ${isMobile && "absolute right-0 top-2"}`}>
                                        <ChevronDownIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                        <ChevronUpIcon aria-hidden="true" className="size-5 group-[&:not([data-open])]:hidden" />
                                    </span>
                                </div>
                            </DisclosureButton>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-sm text-text">{'Explicabo nihil laborum. Saepe facilis consequuntur in eaque. Consequatur perspiciatis quam. Sed est illo quia.'}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                </div>
            </div>
            <div>
                <DividerWithLeftTitle>MILESTONE 1</DividerWithLeftTitle>
                <div className="flex flex-col gap-3">
                    <div className='p-3 rounded-lg bg-gray-3'>
                        <Disclosure as="div" className="">
                            <DisclosureButton className="relative group flex flex-col md:flex-row w-full items-start justify-between text-left text-gray-900">
                                <span className="text-lg font-semibold">{'What is UI/UX & Benefits'}</span>
                                <div className=" md:ml-6 flex gap-4 md:h-7 justify-between items-center">
                                    <span className="text-base text-gray-1">2 Topics . 30min</span>
                                    <span className={`md:mt-1 ${isMobile && "absolute right-0 top-2"}`}>
                                        <ChevronDownIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                        <ChevronUpIcon aria-hidden="true" className="size-5 group-[&:not([data-open])]:hidden" />
                                    </span>
                                </div>
                            </DisclosureButton>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-sm text-text">{'Explicabo nihil laborum. Saepe facilis consequuntur in eaque. Consequatur perspiciatis quam. Sed est illo quia.'}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                    <div className='p-3 rounded-lg bg-gray-3'>
                        <Disclosure as="div" className="">
                            <DisclosureButton className="relative group flex flex-col md:flex-row w-full items-start justify-between text-left text-gray-900">
                                <span className="text-lg font-semibold">{'What is UI/UX & Benefits'}</span>
                                <div className="md:ml-6 flex gap-4 md:h-7 justify-between items-center">
                                    <span className="text-base text-gray-1">2 Topics . 30min</span>
                                    <span className={`md:mt-1 ${isMobile && "absolute right-0 top-2"}`}>
                                        <ChevronDownIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                        <ChevronUpIcon aria-hidden="true" className="size-5 group-[&:not([data-open])]:hidden" />
                                    </span>
                                </div>
                            </DisclosureButton>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-sm text-text">{'Explicabo nihil laborum. Saepe facilis consequuntur in eaque. Consequatur perspiciatis quam. Sed est illo quia.'}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                    <div className='p-3 rounded-lg bg-gray-3'>
                        <Disclosure as="div" className="">
                            <DisclosureButton className="relative group flex flex-col md:flex-row w-full items-start justify-between text-left text-gray-900">
                                <span className="text-lg font-semibold">{'What is UI/UX & Benefits'}</span>
                                <div className="md:ml-6 flex gap-4 md:h-7 justify-between items-center">
                                    <span className="text-base text-gray-1">2 Topics . 30min</span>
                                    <span className={`md:mt-1 ${isMobile && "absolute right-0 top-2"}`}>
                                        <ChevronDownIcon aria-hidden="true" className="size-5 group-data-[open]:hidden" />
                                        <ChevronUpIcon aria-hidden="true" className="size-5 group-[&:not([data-open])]:hidden" />
                                    </span>
                                </div>
                            </DisclosureButton>
                            <DisclosurePanel as="dd" className="mt-2 pr-12">
                                <p className="text-sm text-text">{'Explicabo nihil laborum. Saepe facilis consequuntur in eaque. Consequatur perspiciatis quam. Sed est illo quia.'}</p>
                            </DisclosurePanel>
                        </Disclosure>
                    </div>
                </div>
            </div>  
        </div>
      </div>
  );
};

export default Curriculam;
