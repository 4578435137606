import React from "react";
import { useState } from "react";
import { EditPencilTwo } from "../../../../components/icons";
import MobDrawer from "../../../../components/ui-components/MobDrawer";
import linkedin from "../../../../assets/svgs/linkedin.svg";
import twitter from "../../../../assets/svgs/twitter.svg";
import colorGoogle from "../../../../assets/svgs/colorGoogle.svg";
import pinkball from "../../../../assets/svgs/pinkball.svg";
import MobFloatingTextField from "../../../../components/ui-components/MobFloatingTextField";
import { Button } from "../../../../components/ui-components";

function MobSocial() {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    linkedin: "",
    instagram: "",
    facebook: "",
    twitter: "",
    youtube: "",
  });

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formState);
  };
  return (
    <>
      <div className="bg-white rounded-xl pb-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">Social Media</h3>
        <button
          className="border  border-gray-300 px-1 py-1 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center"
          onClick={() => setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <div className="flex flex-row mx-6 mb-6 gap-6">
          <img src={linkedin} alt="linkedin logo" className="w-7 h-7" />
          <img src={twitter} alt="twitter logo" className="w-7 h-7" />
          <img src={colorGoogle} alt="Google logo" className="w-7 h-7" />
          <img src={pinkball} alt="PinkBall logo" className="w-7 h-7" />
        </div>
      </div>

      <MobDrawer
        open={open}
        onClose={setOpen}
        title="Add Social Media">
        <form
          onSubmit={handleSubmit}
          className="w-full px-5 flex flex-col justify-between m-auto mt-4 h-full">
          <div className="flex flex-col justify-center">
            <MobFloatingTextField
              label="Linkedin Link"
              type="text"
              value={formState.linkedin}
              onChange={handleChange}
              name={"linkedin"}
              id={"linkedin"}
              // placeholder="demo@gmail.com"
            />
            <MobFloatingTextField
              label="Instagram Link"
              type="text"
              value={formState.instagram}
              onChange={handleChange}
              name={"instagram"}
              id={"instagram"}
              // placeholder="demo@gmail.com"
            />
            <MobFloatingTextField
              label="Facebook Link"
              type="text"
              value={formState.facebook}
              onChange={handleChange}
              name={"facebook"}
              id={"facebook"}
              // placeholder="demo@gmail.com"
            />
            <MobFloatingTextField
              label="X Link"
              type="text"
              value={formState.twitter}
              onChange={handleChange}
              name={"twitter"}
              id={"twitter"}
              // placeholder="demo@gmail.com"
            />
            <MobFloatingTextField
              label="Youtube Link"
              type="text"
              value={formState.youtube}
              onChange={handleChange}
              name={"youtube"}
              id={"youtube"}
              // placeholder="demo@gmail.com"
            />
          </div>

          <Button
            type="submit"
            color="primary"
            variant="solid"
            className="w-full m-auto my-6">
            SAVE
          </Button>
        </form>
      </MobDrawer>
    </>
  );
}

export default MobSocial;
