import React from "react";
import heart from "../../../assets/svgs/heart.svg";

const videoData = [
  {
    title: "How to becoming UX designer..",
    classType: "Online | Classroom | One on One | 60hrs",
    name: "Rohan Joshi",
    rating: "4.8",
    reviews: "200",
    price: "9,999/-",
    batches: [
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
    ],
  },
  {
    title: "How to becoming UX designer..",
    classType: "Online | Classroom | One on One | 60hrs",
    name: "Rohan Joshi",
    rating: "4.8",
    reviews: "200",
    price: "9,999/-",
    batches: [
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
    ],
  },
  {
    title: "How to becoming UX designer..",
    classType: "Online | Classroom | One on One | 60hrs",
    name: "Rohan Joshi",
    rating: "4.8",
    reviews: "200",
    price: "9,999/-",
    batches: [
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
    ],
  },
  {
    title: "How to becoming UX designer..",
    classType: "Online | Classroom | One on One | 60hrs",
    name: "Rohan Joshi",
    rating: "4.8",
    reviews: "200",
    price: "9,999/-",
    batches: [
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
    ],
  },
  {
    title: "How to becoming UX designer..",
    classType: "Online | Classroom | One on One | 60hrs",
    name: "Rohan Joshi",
    rating: "4.8",
    reviews: "200",
    price: "9,999/-",
    batches: [
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
    ],
  },
  {
    title: "How to becoming UX designer..",
    classType: "Online | Classroom | One on One | 60hrs",
    name: "Rohan Joshi",
    rating: "4.8",
    reviews: "200",
    price: "9,999/-",
    batches: [
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
      { batchName: "Batch 1", date: "May 24 - Aug 24" },
    ],
  },
];

function VideoCard() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-10 my-4 md:my-8">
      {videoData.map((ele, index) => (
        <div className="relative ">
          <div className="flex justify-between items-center">
            <span className="text-[13px] font-medium bg-gray-100 border border-gray-400 py-1 px-2 rounded-full absolute top-4 left-4">
              Design
            </span>
            <span className="text-[13px] font-medium bg-gray-500 text-white py-1 px-2 rounded-full absolute top-4 right-4">
              Popular
            </span>
          </div>
          <div className="h-44 w-full bg-gray-100 rounded-2xl"></div>
          <div className="flex flex-row justify-between items-center mt-4">
            <h2 className="text-base md:text-sm font-bold">{ele.title}</h2>
            <img src={heart} alt="" />
          </div>
          <p className="mt-2 text-xs font-semibold text-gray-600">
            {ele.classType}
          </p>

          <div className="flex flex-row items-center mt-2">
            <div className="w-8 h-8 bg-gray-4 rounded-full"></div>
            <div className="ml-2 flex flex-row gap-2">
              <p className="text-sm font-medium">{ele.name}</p>
              <p className="text-sm text-gray-500">
                <span className="font-semibold">{ele.rating}</span> (
                {ele.reviews})
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-2 my-2">
            {ele.batches.map((ele, index) => (
              <div
                key={index}
                className="flex flex-col bg-input-background px-4 py-2 rounded-xl">
                <p className="text-xs md:text-sm text-secondary">{ele.batchName}</p>
                <p className="text-[10px] text-primary">{ele.date}</p>
              </div>
            ))}
          </div>

          <p className="mt-2 text-sm font-semibold">
            <span className="text-xs text-gray-500">Starts From</span> ₹{" "}
            {ele.price}
          </p>
        </div>
      ))}
    </div>
  );
}

export default VideoCard;
