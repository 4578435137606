import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store, { history } from "./redux/store";
import "./App.css";
import ThemeLayout from "./layout/themeLayout";
import Home, { homeLoader } from "./screens/home";
import AuthLayout from "./layout/authLayout";
import Auth, { authLoader } from "./screens/auth";
import Signin, { signinLoader } from "./screens/auth/signin";
import Password, { passwordLoader } from "./screens/auth/password";
import ForgotPassword, { forgotPasswordLoader } from "./screens/auth/forgot";
import Verification, { verificationLoader } from "./screens/auth/verification";
import OnBoardingLayout from "./layout/onBoardingLayout";
import OnBoarding, { OnBoardingLoader } from "./screens/onboarding";
import NewPassword, { newPasswordLoader } from "./screens/auth/newPassword";
import FullScreenMessageLayout from "./layout/fullScreenMessage";
import AuthFinal, { authFinalLoader } from "./screens/fullscreen/authFinal";
import OnBoardingFinal, {
  onBoardingFinalLoader,
} from "./screens/fullscreen/onBoardingFinal";
import AboutUs, { aboutLoader } from "./screens/aboutus";
import Instructor, { instructorLoader } from "./screens/instructor";
import FaqPage, { faqLoader } from "./screens/faqs";
import Features, { featuresLoader } from "./screens/features";
import DashboardLayout from "./screens/dashboard";
import MyProfile, { myProfileLoader } from "./screens/dashboard/profile";
import PoliciesAgreementsLayout from "./screens/privacPoliciesAgreements";
import PrivacyPolicy, {
  privacyPolicyLoader,
} from "./screens/privacPoliciesAgreements/privacyPolicy";
import TermsAndConditions, {
  termsAndConditionsLoader,
} from "./screens/privacPoliciesAgreements/termsConditions";
import InstructorAgreement, {
  instructorAgreementLoader,
} from "./screens/privacPoliciesAgreements/instructorAgreement";
import VenueProviderAgreement, {
  venueProviderAgreementLoader,
} from "./screens/privacPoliciesAgreements/venueProviderAgreement";
import PaymentProcessingAgreement, {
  paymentProcessingAgreementLoader,
} from "./screens/privacPoliciesAgreements/paymentProcessingAgreement";
import Courses, { coursesLoader } from "./screens/courses";
import NotificationPage, {
  notificationPageLoader,
} from "./screens/notification";
import CourseDetail, { courseDetailLoader } from "./screens/courseDetail";
import Category, { categoryLoader } from "./screens/category";
import ContactUs, { contactLoader } from "./screens/contactus";
import Forum, { forumLoader } from "./screens/forum";
import Chat, { chatLoader } from "./screens/chat";
import Setting, { settingLoader } from "./screens/dashboard/setting";
import Wishlist, { wishlistLoader } from "./screens/dashboard/wishlist";
import Purchase, { purchaseLoader } from "./screens/dashboard/purchase";
import InstructorProfile, {
  InstructorProfileLoader,
} from "./screens/InstructorProfile";
import MyCourses, { myCoursesLoader } from "./screens/dashboard/mycourses";
import Checkout, { checkoutLoader } from "./screens/chechout";
import Confirmation, {
  confirmationLoader,
} from "./screens/chechout/Confirmation";
import Poll, { pollLoader } from "./screens/polls/pollQuestions";
import PollResult, { pollResultLoader } from "./screens/polls/pollResult";
import instructor_Layout from "./layout/instructorLayout";
import CourseListing, {
  courseListingLoader,
} from "./screens/Instructor_main/courseListing";
import InstructorDashboard, {
  instructorDashboardLoader,
} from "./screens/Instructor_main";
import MyCourseDetail, {
  myCourseDetailLoader,
} from "./screens/dashboard/myCourseDetail";
import QuizLayout from "./screens/quiz";
import QuizPlatform, { quizPlatformLoader } from "./screens/quiz/quizPlatfrom";
import QuizResult, { quizResultLoader } from "./screens/quiz/quizResults";
import SurveyQuestions, { surveyQuestionsLoader } from "./screens/survey";
import SurveyResult, {
  surveyResultLoader,
} from "./screens/survey/surveyComplete";
import Certificates, {
  certificateLoader,
} from "./screens/dashboard/Certificates";
import MobMyProfile, {
  MobMyProfileLoader,
} from "./screens/dashboard/profile/mobileProfile/MobMyProfile";
import MobChangeMobile, {
  MobChangeMobileLoader,
} from "./screens/dashboard/profile/mobileProfile/MobChangeMobile";
import MobNewVerification, {
  MobNewVerificationLoader,
} from "./screens/dashboard/profile/mobileProfile/MobNewVerification";
import { checkIsMobile } from "./utils/helpers";
import MobileChat, { MobileChatLoader } from "./screens/chat/MobileChat";
import InstructorChatBox, {
  InstructorChatBoxLoader,
} from "./screens/chat/InstructorChatBox";
import BatchListing, {
  batchListingLoader,
} from "./screens/Instructor_main/batchListing";
import CreateCourse, {
  createCourseLoader,
} from "./screens/Instructor_main/createCourse";
import VenueProvider, { venueProviderLoader } from "./screens/venueProvider";
import Assessment, { assessmentLoader } from "./screens/assignment";
import AssessmentResult, {
  assessmentResultLoader,
} from "./screens/assignment/assessment_result";
import InstructorOnBoarding, {
  instructorOnBoardingLoader,
} from "./screens/Instructor_main/onboarding";
import CreateBatchOnline, {
  createBatchOnlineLoader,
} from "./screens/Instructor_main/createBatch/online";
import CreateBatchClassroom , { createBatchClassroomLoader } from "./screens/Instructor_main/createBatch/classroom";
import CreateBatchOneOnOne , { createBatchOneOnOneLoader } from "./screens/Instructor_main/createBatch/oneOnOne"
import CreateBatchLayout from "./screens/Instructor_main/createBatch";
import MobProfile, {
  MobProfileLoader,
} from "./screens/dashboard/profile/mobileProfile";
import ChooseBatch, {
  chooseBatchLoader,
} from "./screens/courseDetail/MobComponents";
import MobCategory, { MobCategoryLoader } from "./screens/category/MobCategory";
import MobPrivacyDashBoard from "./screens/privacPoliciesAgreements/mobPrivacyAgreements";
import SubTopics, { SubTopicsLoader } from "./screens/category/MobCategory/SubTopics";
import MyAssessment, { MyAssessmentLoader } from "./screens/dashboard/myAssessment";
import BatchDetailsOnline, { batchDetailsOnlineLoader } from "./screens/Instructor_main/batchDetails/online";
import Polls, { pollsLoader } from "./screens/Instructor_main/polls";
import PollsFolders, { pollsFoldersLoader } from "./screens/Instructor_main/polls/PollsFolders";
import PollTemplate, { pollTemplateLoader } from "./screens/Instructor_main/polls/PollTemplate";
import BatchSchedule from "./screens/Instructor_main/batchDetails/online/BatchSchedule";
import ClassDistribution from "./screens/Instructor_main/batchDetails/online/ClassDistribution";
import MeetingDetails from "./screens/Instructor_main/batchDetails/online/MeetingDetails";
import Price from "./screens/Instructor_main/batchDetails/online/Price";
import Review from "./screens/Instructor_main/batchDetails/online/Review";
import Learner from "./screens/Instructor_main/batchDetails/online/Learner";

// const Protected = () => {
//   const token = localStorage.getItem("token");

//   return token ? <Outlet /> : <Navigate to="/signin" />;
// };

const isMobile = checkIsMobile();

let router = createBrowserRouter([
  {
    path: "/",
    Component: ThemeLayout,
    children: [
      {
        index: true,
        loader: homeLoader,
        Component: Home,
      },
      {
        path: "aboutus",
        loader: aboutLoader,
        Component: AboutUs,
      },
      {
        path: "instructor",
        loader: instructorLoader,
        Component: Instructor,
      },
      {
        path: "faq",
        loader: faqLoader,
        Component: FaqPage,
      },
      {
        path: "features",
        loader: featuresLoader,
        Component: Features,
      },
      {
        path: "venue_provider",
        loader: venueProviderLoader,
        Component: VenueProvider,
      },
      {
        path: "notification",
        loader: notificationPageLoader,
        Component: NotificationPage,
      },
      
      {
        path: "categories",
        children: [
          {
            index: true,
            Loader: isMobile ? MobCategoryLoader :  categoryLoader,
            Component: isMobile ? MobCategory : Category,
          },
          {
            path: ":categoryid",
            Loader: SubTopicsLoader,
            Component: SubTopics,
          },
        ],
      },
      {
        path: "contactus",
        loader: contactLoader,
        Component: ContactUs,
      },
      {
        path: "instructorProfile",
        loader: InstructorProfileLoader,
        Component: InstructorProfile,
      },
      {
        path: "dashboard",
        Component: DashboardLayout,
        children: [
          {
            index: true,
            loader: isMobile ? MobProfileLoader : myProfileLoader,
            Component: isMobile ? MobProfile : MyProfile,
          },
          {
            path: "profile",
            loader: isMobile ? MobMyProfileLoader : myProfileLoader,
            Component: isMobile ? MobMyProfile : MyProfile,
          },
          {
            path: "mobVerification",
            loader: MobChangeMobileLoader,
            Component: MobChangeMobile,
          },
          {
            path: "mobNewVerification",
            loader: MobNewVerificationLoader,
            Component: MobNewVerification,
          },
          {
            path: "courses",
            children: [
              {
                index: true,
                loader: myCoursesLoader,
                Component: MyCourses,
              },
              {
                path: ":courseid",
                children: [
                  {
                    index: true,
                    loader: myCourseDetailLoader,
                    Component: MyCourseDetail,
                  },
                  // {
                  //   path: "batch",
                  //   loader: chooseBatchLoader,
                  //   Component: ChooseBatch
                  // }
                ],
              },
            ],
          },
          {
            path: "quiz",
            children: [
              {
                index: true,
                Component: QuizLayout,
              },
              {
                path: "quiz_platform",
                loader: quizPlatformLoader,
                Component: QuizPlatform,
              },
              {
                path: "quiz_result",
                loader: quizResultLoader,
                Component: QuizResult,
              },
            ],
          },
          {
            path: "my_assessments",
            loader: MyAssessmentLoader,
            Component: MyAssessment
          },
          {
            path: "survey",
            loader: surveyQuestionsLoader,
            Component: SurveyQuestions,
          },
          {
            path: "survey_result",
            loader: surveyResultLoader,
            Component: SurveyResult,
          },
          {
            path: "settings",
            loader: settingLoader,
            Component: Setting,
          },
          {
            path: "wishlist",
            loader: wishlistLoader,
            Component: Wishlist,
          },
          {
            path: "certificates",
            loader: certificateLoader,
            Component: Certificates,
          },
          {
            path: "purchase",
            loader: purchaseLoader,
            Component: Purchase,
          },
          {
            path: "poll",
            loader: pollLoader,
            Component: Poll,
          },
          {
            path: "poll_result",
            loader: pollResultLoader,
            Component: PollResult,
          },
          {
            path: "assessment",
            loader: assessmentLoader,
            Component: Assessment,
          },
          {
            path: "assessment_result",
            loader: assessmentResultLoader,
            Component: AssessmentResult,
          },
        ],
      },
      {
        path: "documents",
        Component: isMobile ? MobPrivacyDashBoard : PoliciesAgreementsLayout,
        children: [
          {
            index: true,
            loader: privacyPolicyLoader,
            Component: PrivacyPolicy,
          },
          {
            path: "privacy_policies",
            loader: privacyPolicyLoader,
            Component: PrivacyPolicy,
          },
          {
            path: "terms_conditions",
            loader: termsAndConditionsLoader,
            Component: TermsAndConditions,
          },
          {
            path: "instructor_agreement",
            loader: instructorAgreementLoader,
            Component: InstructorAgreement,
          },
          {
            path: "venue_provider_agreement",
            loader: venueProviderAgreementLoader,
            Component: VenueProviderAgreement,
          },
          {
            path: "payment_processing_agreement",
            loader: paymentProcessingAgreementLoader,
            Component: PaymentProcessingAgreement,
          },
        ],
      },
      {
        path: "courses",
        children: [
          {
            index: true,
            loader: coursesLoader,
            Component: Courses,
          },
          {
            path: ":courseid",
            children: [
              {
                index: true,
                loader: courseDetailLoader,
                Component: CourseDetail,
              },
              {
                path: "batches",
                loader: chooseBatchLoader,
                Component: ChooseBatch,
              },
            ],
          },
          {
            path: "checkout",
            loader: checkoutLoader,
            Component: Checkout,
          },
          {
            path: "confirmation",
            loader: confirmationLoader,
            Component: Confirmation,
          },
          // {
          //   path: 'mycourses',
          //   loader: myCoursesLoader,
          //   Component: MyCourses
          // }
        ],
        // loader: coursesLoader,
        // Component: Courses,
      },
      {
        path: "chat",
        children: [
          {
            index: true,
            loader: isMobile ? MobileChatLoader : chatLoader,
            Component: isMobile ? MobileChat : Chat,
          },
          {
            path: ":chatid",
            loader: InstructorChatBoxLoader,
            Component: InstructorChatBox,
          },
        ],
      },
      {
        path: "forums",
        loader: forumLoader,
        Component: Forum,
      },
    ],
  },
  {
    path: "instructor_courses",
    Component: instructor_Layout,
    children: [
      {
        index: true,
        loader: instructorDashboardLoader,
        Component: InstructorDashboard,
      },
      {
        path: "dashboard",
        Component: InstructorDashboard,
        children: [
          {
            index: true,
            loader: courseListingLoader,
            Component: CourseListing,
          },
          {
            path: "course_listing",
            loader: courseListingLoader,
            Component: CourseListing,
          },
          {
            path: "batch_listing",
            loader: batchListingLoader,
            Component: BatchListing,
          },
          {
            path: "batch_details",
            loader: batchDetailsOnlineLoader,
            Component : BatchDetailsOnline,
            children: [
              {
                index: true,
                Component: BatchSchedule
              },
              {
                path:'class_distribution',
                Component: ClassDistribution
              },
              {
                path: 'meeting_details',
                Component: MeetingDetails
              },
              {
                path: 'price',
                Component: Price
              },
              {
                path: 'review',
                Component: Review
              },
              {
                path: 'Learners',
                Component: Learner
              }
            ]
          },
          {
            path: "polls",
            children: [
              {
                index: true,
                loader: pollsLoader,
                Component: Polls
              },
              {
                path: ":pollid",
                loader: pollsFoldersLoader,
                Component: PollsFolders
              },
              
            ]
          },
        ],
      },
      {
        path: "poll_template",
        loader: pollTemplateLoader,
        Component: PollTemplate
      },
    ],
  },
  {
    path: "createBatch",
    children: [
      {
        index: true,
        Component: CreateBatchLayout,
      },
      {
        path: "online",
        loader: createBatchOnlineLoader,
        Component: CreateBatchOnline,
      },
      {
        path: "classroom",
        loader: createBatchClassroomLoader,
        Component: CreateBatchClassroom
      },
      {
        path:"one_on_one",
        loader: createBatchOneOnOneLoader,
        Component: CreateBatchOneOnOne
      },
    ],
  },
  {
    path: "create_course",
    loader: createCourseLoader,
    Component: CreateCourse,
  },
  {
    path: "/auth",
    Component: AuthLayout,
    children: [
      {
        index: true,
        loader: authLoader,
        Component: Auth,
      },
      {
        path: "signin",
        loader: signinLoader,
        Component: Signin,
      },
      {
        path: "password",
        loader: passwordLoader,
        Component: Password,
      },
      {
        path: "setpassword",
        loader: newPasswordLoader,
        Component: NewPassword,
      },
      {
        path: "forgot",
        loader: forgotPasswordLoader,
        Component: ForgotPassword,
      },
      {
        path: "verification",
        loader: verificationLoader,
        Component: Verification,
      },
    ],
  },
  {
    path: "/onboarding",
    loader: OnBoardingLoader,
    Component: OnBoarding,
    // Component: OnBoardingLayout,
    // children: [
    //   {
    //     index: true,

    //   },
    //   {
    //     path: "instructor_onboarding",
    //     loader: instructorOnBoardingLoader,
    //     Component: InstructorOnBoarding,
    //   },
    // ],
  },
  {
    path: "/final",
    Component: FullScreenMessageLayout,
    children: [
      {
        index: true,
        loader: authFinalLoader,
        Component: AuthFinal,
      },
      {
        path: "onboarding",
        loader: onBoardingFinalLoader,
        Component: OnBoardingFinal,
      },
    ],
  },
]);

export const App = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
