import React, { useState } from "react";
import rightArrow from "../../../assets/svgs/rightArrow.svg";
import { Drawer } from "../../../components/ui-components";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Button } from "../../../components/ui-components";
import { checkIsMobile } from "../../../utils/helpers";
import MobDrawer from "../../../components/ui-components/MobDrawer";

const timeZone = [
  { country: "United Kingdom", gmt: "GMT + 0" },
  { country: "India", gmt: "GMT + 5:30" },
  { country: "Australia", gmt: "GMT + 10" },
  { country: "United States (New York)", gmt: "GMT - 5" },
  { country: "Japan", gmt: "GMT + 9" },
  { country: "Germany", gmt: "GMT + 1" },
  { country: "Brazil (São Paulo)", gmt: "GMT - 3" },
  { country: "Russia (Moscow)", gmt: "GMT + 3" },
  { country: "South Africa", gmt: "GMT + 2" },
  { country: "China", gmt: "GMT + 8" },
];

function TimeZone() {
  const [open, setOpen] = useState(false);
  const isMobile = checkIsMobile();
  return (
    <>
      <div
        className="w-full flex flex-row justify-between items-center md:items-start transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer mt-4 md:mt-8 border-b pb-4 md:border-none md:pb-0"
        onClick={() => setOpen(true)}>
        {isMobile ? (
          <>
            <div>
              <p className="text-lg text-primary">Time Zone</p>
              <p className="text-sm text-gray-500">
                Indian Standard Time - UTC + 5:30
              </p>
            </div>
            <div>
              <img src={rightArrow} alt="" />
            </div>
          </>
        ) : (
          <>
            <p className="text-base text-primary">Time Zone</p>
            <div className="flex flex-row justify-center gap-2">
              <p className="text-base text-primary">
                Indian Standard Time - UTC + 5:30
              </p>
              <img src={rightArrow} alt="" />
            </div>
          </>
        )}
      </div>

      {isMobile ? (
        <MobDrawer open={open} onClose={setOpen} title="Time Zone">
          <form className="flex flex-col justify-between h-full">
            <fieldset>
              <div className="relative w-full">
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id={"head-search"}
                  type={"text"}
                  placeholder="Search Time Zone"
                  // onClick={() => setOpen(true)}
                  // readOnly
                  className={
                    "bg-white border text-sm border-gray-400 rounded-4xl w-full max-w-md h-10 pl-8 text-input-text placeholder:text-input-placeholder"
                  }
                />
              </div>
              <div className="mt-6 space-y-4">
                {timeZone.map((ele, index) => (
                  <div
                    key={index}
                    className="flex items-center w-full gap-6 overflow-y-auto">
                    <div className="w-full flex flex-row justify-between items-center">
                      <label
                        htmlFor={ele.id}
                        className="block text-lg font-normal text-gray-900">
                        {ele.country}
                      </label>
                      <span className="text-gray-500">{ele.gmt}</span>
                    </div>
                    <input
                      defaultChecked={ele.id === "email"}
                      id={ele.id}
                      name="notification-method"
                      type="radio"
                      className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-gray-500 checked:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden mr-2"
                    />
                  </div>
                ))}
              </div>
            </fieldset>
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="w-48 text-sm m-auto my-6 rounded-xl">
              SAVE
            </Button>
          </form>
        </MobDrawer>
      ) : (
        <Drawer open={open} onClose={setOpen} title="Time Zone">
          <form className="flex flex-col justify-between h-full">
            <fieldset>
              <div className="relative w-full">
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id={"head-search"}
                  type={"text"}
                  placeholder="Search Time Zone"
                  // onClick={() => setOpen(true)}
                  // readOnly
                  className={
                    "bg-white border text-sm border-gray-400 rounded-4xl w-full max-w-md h-10 pl-8 text-input-text placeholder:text-input-placeholder"
                  }
                />
              </div>
              <div className="mt-6 space-y-6">
                {timeZone.map((ele, index) => (
                  <div
                    key={index}
                    className="flex items-center w-[70%] gap-6 overflow-y-auto">
                    <div className="w-full flex flex-row justify-between items-center">
                      <label
                        htmlFor={ele.id}
                        className="ml-3 block text-lg font-medium text-gray-900">
                        {ele.country}
                      </label>
                      <span className="text-primary">{ele.gmt}</span>
                    </div>
                    <input
                      defaultChecked={ele.id === "email"}
                      id={ele.id}
                      name="notification-method"
                      type="radio"
                      className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-gray-500 checked:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden mr-2"
                    />
                  </div>
                ))}
              </div>
            </fieldset>
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="w-48 text-sm m-auto my-6 rounded-xl">
              SAVE
            </Button>
          </form>
        </Drawer>
      )}
    </>
  );
}

export default TimeZone;
