import { FloatingTextField } from '../../../../../components/ui-components';
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Datepicker from "react-tailwindcss-datepicker";
import { useState } from 'react';



export const CardTile = ({ title, description, children }) => (
    <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
      <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
      <p className="text-gray-500 text-sm mb-4">{description}</p>
      <div className="w-full">{children}</div>
    </div>
  );
  


export default function ClassroomStep3() {

  const [Seats ,setSeats] = useState(null)
  const [startTime , setStartTime] = useState(null);
  const [LocationDesc , setLocationDesc] = useState('');

  const Time = ['12:00' , '12:30'  , "1:00" , '1:30' , '2:00' , '2:30']
  const countLetters = (text) => text.length;

  const [value, setValue] = useState({ 
    startDate: null, 
    endDate: null
  });
  const [showLocation , setShowLocation] = useState(false);

  return (
    <>
      <div className="w-full flex flex-col justify-start items-start grow overflow-y-auto px-8">
        <div className="w-full flex flex-row justify-between items-center py-6">
          <h1 className="font-medium text-xl text-left text-secondary mb-4">
            Venue Details
          </h1>
        </div>
        <CardTile
        title="Choose Venue"
        description='Choose venue for this batch.'
        >
           <div className='py-4 flex justify-center items-center'>
           <button onClick={()=>{setShowLocation(true)}} className={`${showLocation ? 'hidden' : 'w-[95%] py-4 flex flex-row justify-between items-center rounded-xl bg-gray-100'}`} >
                <svg className='w-max px-2' width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.9987 14.0969C8.5112 12.8747 9.65321 11.6983 10.4247 10.5677C11.1963 9.43715 11.582 8.37535 11.582 7.38229C11.582 6.00729 11.1543 4.87674 10.2987 3.99062C9.44314 3.10451 8.34314 2.66146 6.9987 2.66146C5.65425 2.66146 4.55425 3.10451 3.6987 3.99062C2.84314 4.87674 2.41536 6.00729 2.41536 7.38229C2.41536 8.37535 2.80113 9.43715 3.57266 10.5677C4.34418 11.6983 5.4862 12.8747 6.9987 14.0969ZM6.9987 16.4115C4.84453 14.8226 3.23655 13.2795 2.17474 11.7823C1.11293 10.2851 0.582031 8.8184 0.582031 7.38229C0.582031 5.47257 1.17786 3.90278 2.36953 2.67292C3.5612 1.44306 5.10425 0.828125 6.9987 0.828125C8.89314 0.828125 10.4362 1.44306 11.6279 2.67292C12.8195 3.90278 13.4154 5.47257 13.4154 7.38229C13.4154 8.8184 12.8845 10.2851 11.8227 11.7823C10.7609 13.2795 9.15286 14.8226 6.9987 16.4115ZM6.9987 9.07812C7.50286 9.07812 7.93446 8.89861 8.29349 8.53958C8.65252 8.18056 8.83203 7.74896 8.83203 7.24479C8.83203 6.74062 8.65252 6.30903 8.29349 5.95C7.93446 5.59097 7.50286 5.41146 6.9987 5.41146C6.49453 5.41146 6.06293 5.59097 5.70391 5.95C5.34488 6.30903 5.16536 6.74062 5.16536 7.24479C5.16536 7.74896 5.34488 8.18056 5.70391 8.53958C6.06293 8.89861 6.49453 9.07812 6.9987 9.07812ZM0.582031 19.1615V17.3281H13.4154V19.1615H0.582031Z" fill="#5F6368"/>
                </svg>

                <p className='w-[80%] text-gray-400 text-left'>Choose Venue</p>
                <svg className='w-max px-2' width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L0.999999 1" stroke="#2A3140" stroke-opacity="0.7" stroke-width="2"/>
                </svg>
            </button>
            {showLocation ? 
              <div className='w-full py-10 flex flex-row justify-between items-start rounded-xl bg-gray-50'>
                <svg className='w-max mt-2 mx-4 pt-4 px-2' width="32" height="40" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.9987 14.0969C8.5112 12.8747 9.65321 11.6983 10.4247 10.5677C11.1963 9.43715 11.582 8.37535 11.582 7.38229C11.582 6.00729 11.1543 4.87674 10.2987 3.99062C9.44314 3.10451 8.34314 2.66146 6.9987 2.66146C5.65425 2.66146 4.55425 3.10451 3.6987 3.99062C2.84314 4.87674 2.41536 6.00729 2.41536 7.38229C2.41536 8.37535 2.80113 9.43715 3.57266 10.5677C4.34418 11.6983 5.4862 12.8747 6.9987 14.0969ZM6.9987 16.4115C4.84453 14.8226 3.23655 13.2795 2.17474 11.7823C1.11293 10.2851 0.582031 8.8184 0.582031 7.38229C0.582031 5.47257 1.17786 3.90278 2.36953 2.67292C3.5612 1.44306 5.10425 0.828125 6.9987 0.828125C8.89314 0.828125 10.4362 1.44306 11.6279 2.67292C12.8195 3.90278 13.4154 5.47257 13.4154 7.38229C13.4154 8.8184 12.8845 10.2851 11.8227 11.7823C10.7609 13.2795 9.15286 14.8226 6.9987 16.4115ZM6.9987 9.07812C7.50286 9.07812 7.93446 8.89861 8.29349 8.53958C8.65252 8.18056 8.83203 7.74896 8.83203 7.24479C8.83203 6.74062 8.65252 6.30903 8.29349 5.95C7.93446 5.59097 7.50286 5.41146 6.9987 5.41146C6.49453 5.41146 6.06293 5.59097 5.70391 5.95C5.34488 6.30903 5.16536 6.74062 5.16536 7.24479C5.16536 7.74896 5.34488 8.18056 5.70391 8.53958C6.06293 8.89861 6.49453 9.07812 6.9987 9.07812ZM0.582031 19.1615V17.3281H13.4154V19.1615H0.582031Z" fill="#5F6368"/>
                </svg>
                <div className='w-[80%] flex flex-col justify-start items-start p-4'>
                  <p className='text-lg font-semibold'>White House</p>
                  <p className='w-[50%] mt-1 text-sm text-gray-400'>No.67/1B, 4th Cross, Shanthala Nagar, Lavelle Road, Bangalore - 560001</p>
                  <p className='mt-2 flex justify-start items-center'>
                    <svg className='mr-1' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.45738 12.0117L3.9694 14.096C3.81344 14.1811 3.66101 14.2165 3.51214 14.2023C3.36326 14.1881 3.23211 14.1385 3.11868 14.0534C3.00525 13.9684 2.92017 13.8514 2.86346 13.7025C2.80674 13.5536 2.79965 13.4012 2.84219 13.2453L3.75672 9.35318L0.672839 6.7372C0.54523 6.62377 0.467247 6.49261 0.43889 6.34374C0.410532 6.19486 0.417621 6.04953 0.460158 5.90774C0.502694 5.76595 0.580677 5.64898 0.694107 5.55682C0.807537 5.46465 0.949325 5.41148 1.11947 5.3973L5.16042 5.03575L6.75553 1.33509C6.82642 1.17912 6.92568 1.06215 7.05328 0.984162C7.18089 0.906179 7.31559 0.867188 7.45738 0.867188C7.59917 0.867188 7.73387 0.906179 7.86147 0.984162C7.98908 1.06215 8.08833 1.17912 8.15923 1.33509L9.75434 5.05701L13.7953 5.3973C13.9654 5.41148 14.1072 5.4682 14.2207 5.56745C14.3341 5.6667 14.4121 5.78722 14.4546 5.92901C14.4971 6.0708 14.5007 6.21258 14.4652 6.35437C14.4298 6.49616 14.3483 6.62377 14.2207 6.7372L11.158 9.35318L12.0726 13.2453C12.1151 13.4012 12.108 13.5536 12.0513 13.7025C11.9946 13.8514 11.9095 13.9684 11.7961 14.0534C11.6827 14.1385 11.5515 14.1881 11.4026 14.2023C11.2537 14.2165 11.1013 14.1811 10.9454 14.096L7.45738 12.0117Z" fill="black" fill-opacity="0.49"/>
                    </svg>
                    4.8 (60)</p>
                </div>
                <div>
                <button className="mt-4 w-max px-2 flex flex-row justify-start items-center bg-white border border-gray-300 rounded-full ">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="none" fill-opacity="0.6"/>
                    <path d="M14.9583 26.0417H16.0865L23.825 18.3031L22.6969 17.175L14.9583 24.9135V26.0417ZM13.375 27.625V24.2604L23.825 13.8302C23.9833 13.6851 24.1582 13.5729 24.3495 13.4937C24.5408 13.4146 24.742 13.375 24.9531 13.375C25.1642 13.375 25.3687 13.4146 25.5667 13.4937C25.7646 13.5729 25.9361 13.6917 26.0813 13.85L27.1698 14.9583C27.3281 15.1035 27.4436 15.275 27.5161 15.4729C27.5887 15.6708 27.625 15.8687 27.625 16.0667C27.625 16.2778 27.5887 16.479 27.5161 16.6703C27.4436 16.8616 27.3281 17.0365 27.1698 17.1948L16.7396 27.625H13.375ZM23.251 17.749L22.6969 17.175L23.825 18.3031L23.251 17.749Z" fill="#5F6368"/>
                    </svg>
                    <p className='pr-4'>Change Venue</p>
                </button>
                </div>
              </div> :
              
              <div></div>}
           </div>
            <div className="relative">
                <FloatingTextField
                label="Write Venue Description.."
                type="text"
                name="Venue Description"
                id="VenueDescription"
                className="w-full min-h-60 py-2 my-4"
                value={LocationDesc}
                onChange={(e) => setLocationDesc(e.target.value)}
                />
                <p className="text-end w-full text-sm text-gray-500 pt-1 pb-2">{`${countLetters(
                LocationDesc
                )}/1200 max`}</p>
          </div>
        </CardTile>
        <div className='py-6 w-full flex flex-row justify-between items-center gap-4'>
            <CardTile
            title="No of Seats"
            description='Enter the maximum number of participants for this session.'
            >
                <FloatingTextField
                label="Seats Available"
                type="number"
                name="Seats"
                id="Seats"
                className="w-full"
                value={Seats}
                onChange={(e) => setSeats(e.target.value)}
                />

            </CardTile>
            <CardTile
            title="Registration Closes On"
            description='Set the final date for learners to book this session.'
            >
            <div className='flex flex-row justify-between items-center'>
                <div className='w-[50%] mt-2 py-6'>
                    <Datepicker 
                        inputClassName="cover-fit border-0"
                        useRange={false}
                        asSingle={true}
                        placeholder="Select Date"
                        value={value} 
                        onChange={newValue => setValue(newValue)}
                        toggleClassName="hidden"
                    />
                </div>
                <Menu as="div" className="relative inline-block text-left mt-2 w-[50%]">
                    <MenuButton className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
                    <div className={`flex flex-col justify-start items-start px-4 bg-input-background rounded-lg overflow-hidden `}>
                    <input type='text' id='startTime' className="block h-10 px-4 py-1 w-full text-base text-input-text bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" placeholder='Select Time' value={startTime} />
                    </div>
                    </MenuButton>
                <MenuItems
                    transition
                    className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    {Time.map((value) => (
                    <div className="py-1">
                    <MenuItem>
                        <a
                        href="#"
                        onClick={() => {setStartTime(value)}}
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                        {value}
                        </a>
                    </MenuItem>
                    </div>
                    ))}
                </MenuItems>
                </Menu>
            </div>
            </CardTile>
        </div>
      </div>
    </>
  );
}
