import EditMeetingDetails from "./EditMeetingDetails";
import zoom from "../../../../assets/images/zoom.png";
import { useState } from "react";

export const CardTile = ({ title, description, children }) => (
  <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
    <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
    <p className="text-gray-500 text-sm mb-4">{description}</p>
    <div className="w-full">{children}</div>
  </div>
);

export default function MeetingDetails() {
  const [showEdit, setShowEdit] = useState(false);
  const [values, setValues] = useState({
    meetingPlatform: "zoom",
    meetingPlatformName: "BlueJeans",
    meetingURL: "https:bluejeans.com/meet",
    meetingID: "352546366",
    passCode: "POS23524",
    meetingDesc: `This beginner-level course introduces the fundamentals of UX design. Learn how to create engaging and user-centered interfaces, conduct user research, and build wireframes and prototypes using industry-standard tools like Figma. By the end of this course, you'll have the skills to kickstart a career in UX design.\n
    Dive deep into essential topics like design thinking, usability testing, and accessibility to ensure your designs meet real-world needs. Through hands-on projects, you'll practice creating intuitive workflows and layouts that delight users and solve practical problems. Each lesson is structured to provide actionable insights, enabling you to confidently tackle UX challenges.\n
    Whether you're an aspiring designer or a professional looking to enhance your skills, this course provides step-by-step guidance tailored to your learning goals. Gain valuable experience with tools, methods, and techniques used by top designers in the industry, and build a strong portfolio to showcase your expertise.`,
    time: "11:30 PM",
    date: "7 Jan 2024",
    seats: "50",
  });

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center grow overflow-y-auto px-8">
        {showEdit ? (
          <div className="w-full py-6">
            <div className="w-[95%] flex flex-row justify-between items-center pt-6">
              <h1 className="font-bold text-lg text-left text-secondary">
                Edit Meeting Details
              </h1>
              <button
                onClick={() => {
                  setShowEdit(false);
                }}
                className="flex justify-center items-center text-md font-medium p-3 px-8 text-white bg-primary rounded-lg "
              >
                Save
              </button>
            </div>
            <EditMeetingDetails />
          </div>
        ) : (
          <div className="w-full flex flex-col justify-center items-start pt-6">
            <div className="w-[95%] flex flex-row justify-between items-center pt-6">
            <h1 className="font-bold text-lg text-left text-secondary">
              Meeting Details
            </h1>
            <button
              onClick={() => {
                setShowEdit(true);
              }}
              className="flex justify-center items-center text-sm font-medium p-2 border-2 border-gray-400 rounded-full "
            >
              <svg
                className="mx-2"
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.54167 11.4583H2.55104L9.475 4.53438L8.46562 3.525L1.54167 10.449V11.4583ZM0.125 12.875V9.86458L9.475 0.532292C9.61667 0.402431 9.77309 0.302083 9.94427 0.23125C10.1155 0.160417 10.2955 0.125 10.4844 0.125C10.6733 0.125 10.8562 0.160417 11.0333 0.23125C11.2104 0.302083 11.3639 0.408333 11.4937 0.55L12.4677 1.54167C12.6094 1.67153 12.7127 1.825 12.7776 2.00208C12.8425 2.17917 12.875 2.35625 12.875 2.53333C12.875 2.72222 12.8425 2.90226 12.7776 3.07344C12.7127 3.24462 12.6094 3.40104 12.4677 3.54271L3.13542 12.875H0.125ZM8.96146 4.03854L8.46562 3.525L9.475 4.53438L8.96146 4.03854Z"
                  fill="#505050"
                />
              </svg>
              Edit
            </button>
            </div>
            <div className="w-full flex flex-col justify-start items-start grow overflow-y-auto px-8">
              <CardTile
                title="Meeting Platform"
              >
                <div className="w-full flex flex-row justify-between items-center">
                  <button className="flex flex-row justify-start items-center p-2 border border-gray-300 rounded-full focus:border-2 focus:border-black focus:outline-none grayscale focus:grayscale-0 hover:grayscale-0">
                    <img className="w-8 h-8 mx-1" src={zoom} />{" "}
                    <p className="text-sm my-1 mx-1 focus:text-black">Zoom</p>{" "}
                  </button>
                  <button className="flex justify-center items-center text-md font-medium p-2 px-6 text-white bg-primary rounded-lg ">
                    Join Meeting
                  </button>
                </div>
                <div>
                <div className="relative mb-3">
                    <div className={`w-full py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <input disabled={true} type='text' id='meeting Platform' className="block h-16 px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.meetingPlatformName} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Meeting ID</label>
                  </div>
                  </div>
                  <div className="relative mb-3">
                    <div className={`w-full py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <input disabled={true} type='text' id='meeting URL' className="block h-16 px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.meetingURL} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Meeting ID</label>
                  </div>
                  </div>
                  <div className="flex flex-row justify-start gap-x-10 items-center">
                  <div className="relative mb-3">
                    <div className={`w-full py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <input disabled={true} type='text' id='meeting ID' className="block h-16 px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.meetingID} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Meeting ID</label>
                  </div>
                  </div>
                    <div className="relative mb-3">
                    <div className={`w-full py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <input disabled={true} type='text' id='meeting passCode' className="block h-16 px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.passCode} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">passcode</label>
                  </div>
                  </div>
                  </div>
                  <div className="relative mb-3">
                  <div className={`w-full h-60 py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <textarea disabled={true} type='text' id='meeting description' className="block h-full px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.meetingDesc} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Meeting Description</label>
                  </div>
                  </div>
                </div>
              </CardTile>
              <div className="py-6 w-full flex flex-row justify-between items-center gap-4">
                <CardTile
                  title="No of Seats"
                >
                  <div className="relative mb-3">
                    <div className={`w-full py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <input disabled={true} type='text' id='Total Seats' className="block h-16 px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.seats} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Seats Available</label>
                  </div>
                  </div>
                </CardTile>
                <CardTile
                  title="Registration Closes On"
                >
                  <div className="flex flex-row justify-between items-center">
                    <div className="w-[50%]">
                    <div className="relative mb-3">
                    <div className={`w-full py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <input disabled={true} type='text' id='CloseDate' className="block h-16 px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.date} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Date</label>
                  </div>
                  </div>
                    </div>
                      <div className="relative inline-block text-left w-[50%]">
                      <div className="relative mb-3">
                    <div className={`w-full py-2 my-4 flex bg-input-background rounded-lg overflow-hidden`}>
                    <input disabled={true} type='text' id='CloseTime' className="block h-16 px-4 pb-2.5 pt-7 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" value={values.time} />
                    <label className="absolute text-base text-input-placeholder duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-4 peer-focus:text-input-placeholder peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">Time</label>
                  </div>
                  </div>
                      </div>
                  </div>
                </CardTile>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
