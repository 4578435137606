import React, { useEffect, useState } from "react";
import { EditPencilTwo } from "../../../components/icons";
import { Container, Spinner } from "../../../components/ui-components";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, FloatingTextField } from "../../../components/ui-components";
import { DropDownField, Drawer } from "../../../components/ui-components";
import DiscriptionRow from "./DiscriptionRow";
import { updateProfile } from "../../../redux/profile/actionCreator";
import { useDispatch } from "react-redux";

const PersonalInfo = ({profile, countries}) => {
  // const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [personalInformation, setPersonalInformation] = useState([]);
  const [open, setOpen] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formState, setFormState] = useState({
    name: profile?.name,
    email: profile?.email,
    gender: profile?.gender,
    age: profile?.age_limit,
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
  });
  // console.log('countries', profile, countries);
  const handleChange = (e) => {
    // let {name,value} = e.target;
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const onFinish = async (e) => {
    e.preventDefault();
    console.log(formState);
    const newErrors = {};
    setFormSubmit(true);
    let reponse = await dispatch(updateProfile(formState));
    console.log('reponse', reponse);
    if(reponse && reponse.type === "PROFILE_SUCCESS"){
      setFormSubmit(false);
      setOpen(false);
    }else{
      setFormSubmit(false);
    }
  };

  useEffect(()=>{
    setPersonalInformation([
      {
        label:'Full NAME',
        value: profile?.name
      },
      // {
      //   label:'LAST NAME',
      //   value:''
      // },
      {
        label:'AGE',
        value: profile?.age_limit
      },
      {
        label:'GENDER',
        value: profile?.gender
      },
      {
        label:'ADDRESS',
        value: profile?.address
      }
    ]);
  },[profile]);

  return (
    <>
      <div className="border border-gray-200 rounded-xl relative pb-2 mb-4">
        <button
          className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          <p className=" text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>

        <h3 className="text-xl mx-6 mt-6 mb-6 font-semibold">
          Personal Information
        </h3>
        {
          personalInformation.map((item)=>(
            <DiscriptionRow label={item.label} value={item.value} />
          ))
        }
      </div>

      <Drawer open={open} onClose={setOpen} title="Edit Personal Information">
        <form
          onSubmit={onFinish}
          method="POST"
          className="flex flex-col justify-center w-96 m-auto">
          {/* <FloatingLabel variant="filled" className='h-16 mb-1 pl-4 pr-4 w-full bg-input-background border-0 text-input-text placeholder-transparent rounded-xl' label="Email ID / Phone Number" /> */}
          <FloatingTextField
            label="Full NAME"
            type="text"
            onChange={handleChange}
            value={formState.name}
            name={"name"}
            id={"name"}
            placeholder="Enter Full Name"
            // className="w-full"
          />
          {/* <FloatingTextField
            label="EMAIL ID"
            type="email"
            value={formState.email}
            onChange={handleChange}
            name={"email"}
            id={"email"}
            placeholder="demo@gmail.com"
          /> */}

          <DropDownField
            options={[
              { key: 1, value: "Male" },
              { key: 2, value: "Female" },
              { key: 3, value: "Other" },
            ]}
            label="Gender"
            name={"gender"}
            id={"gender"}
            placeholder="Select Gender"
            error={""}
            value={formState.gender}
          />
          <FloatingTextField
            label="Age"
            type="text"
            value={formState.age}
            onChange={handleChange}
            name={"age"}
            id={"age"}
            placeholder="Age"
          />
          <FloatingTextField
            label="Address line 1"
            type="text"
            value={formState.address1}
            onChange={handleChange}
            name={"address1"}
            id={"address1"}
            placeholder="Address Line 1"
          />
          <FloatingTextField
            label="Address Line 2"
            type="text"
            value={formState.address2}
            onChange={handleChange}
            name={"address2"}
            id={"address2"}
            placeholder="Address Line 2"
          />

          <div className="grid grid-cols-2 gap-3">
            <FloatingTextField
              label="City"
              type="text"
              value={formState.city}
              onChange={handleChange}
              name={"city"}
              id={"city"}
              placeholder="City"
            />
            <DropDownField
              options={[
                { key: 1, value: "Delhi" },
                { key: 2, value: "Rajesthan" },
                { key: 3, value: "Punjab" },
              ]}
              label="State"
              name={"state"}
              id={"state"}
              placeholder="State"
              error={""}
              value={formState.state}
            />
            <FloatingTextField
              label="Pincode"
              type="number"
              value={formState.pincode}
              onChange={handleChange}
              name={"pincode"}
              id={"pincode"}
              placeholder="Pincode"
            />
            <DropDownField
              options={[
                { key: 1, value: "India" },
                { key: 2, value: "Usa" },
                { key: 3, value: "Japan" },
              ]}
              label="Country"
              name={"country"}
              id={"country"}
              placeholder="Country"
              error={""}
              value={formState.country}
            />
          </div>
          <Button type='submit' color='primary' variant='solid' className={`m-auto my-6 ${formSubmit?'w-20':'w-52'}`} rounded={formSubmit?true:false}>{formSubmit?<Spinner className={''} color={'white'} />:'SAVE'}</Button>
        </form>
      </Drawer>
    </>
  );
}

export default PersonalInfo;
