import React, { useState } from "react";
import { DropDownField } from "../../../../components/ui-components";
import {Drawer} from "../../../../components/ui-components";
import { FloatingTextField } from "../../../../components/ui-components";
import {EditableTagField} from "../../../../components/ui-components";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export const CardTile = ({ title, description, children }) => (
  <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
    <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
    <p className="text-gray-500 text-sm mb-4">{description}</p>
    <div className="w-full">{children}</div>
  </div>
);



export default function Step1() {
  const [userTopics, setUserTopics] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseSubtitle, setCourseSubtitle] = useState("");
  const [open, setOpen] = useState(false);

  const notificationMethods = [
    { id: "english", title: "English" },
    { id: "spanish", title: "Spanish" },
    { id: "arabic", title: "Arabic" },
    { id: "tamil", title: "Tamil" },
    { id: "Chinese", title: "Chinese" },
    { id: "French", title: "French" },
    { id: "Arabic", title: "Arabic" },
  ];

  const suggestedTags = [
    "UX Design",
    "User Experience",
    "Design Principles",
    "UI/UX",
    "Wireframing",
    "Prototyping",
  ];


  const countLetters = (text) => text.length;

  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedLevelTwo, setSelectedLevelTwo] = useState(null);
  const levels = ["Beginner", "Intermediate", "Expert"];
  const levelsTwo = ["English", "Hindi", "Tamil"];

  const toggleLevel = (level) => {
    setSelectedLevel((prev) => (prev === level ? null : level));
  };
  const toggleLevelTwo = (level) => {
    setSelectedLevelTwo((prev) => (prev === level ? null : level));
  };

  return (
    <>
      <div className="w-full flex flex-col justify-start items- grow overflow-y-auto px-8">
        <h1 className="font-medium text-lg text-left text-secondary mb-4">
          Course Overview
        </h1>

        <CardTile
          title="Course Title and Subtitle"
          description="Add a clear title and a brief subtitle to highlight your course's value"
        >
          <div className="relative">
            <FloatingTextField
              label="Course Title"
              type="text"
              name="Course Title"
              id="courseTitle"
              className="w-full py-2 my-4"
              value={courseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
            />
            <p className="text-end w-full text-sm text-gray-500 pt-1 pb-2">{`${countLetters(
              courseTitle
            )}/50 max`}</p>
          </div>

          <div className="relative">
            <FloatingTextField
              label="Course Subtitle"
              type="text"
              name="Course Subtitle"
              id="courseSubtitle"
              className="w-full py-2 my-4"
              value={courseSubtitle}
              onChange={(e) => setCourseSubtitle(e.target.value)}
            />
            <p className="text-end w-full text-sm text-gray-500 pt-1 pb-2">{`${countLetters(
              courseSubtitle
            )}/200 max`}</p>
          </div>
        </CardTile>

        <CardTile
          title="Course Category and Subtitle"
          description="Choose the course category and sub category"
        >
          <div className="flex flex-row w-full justify-between items-center">
            <div className="w-[48%]">
              <DropDownField
                placeholder="Category"
                options={[
                  { key: 1, value: "Design" },
                  { key: 2, value: "Programming" },
                  { key: 3, value: "Other" },
                ]}
                className="mb-2 border-2 border-gray-300 text-center flex items-center"
              />
            </div>
            <div className="w-[48%]">
              <DropDownField
                placeholder="Sub Category"
                options={[
                  { key: 1, value: "UX/UI Design" },
                  { key: 2, value: "Normal" },
                  { key: 3, value: "Other" },
                ]}
                className="w-96 mb-2 border-2 border-gray-300 text-center flex items-center"
              />
            </div>
          </div>
        </CardTile>
        <CardTile
          title="Course Level"
          description="Select the level of expertise for your course."
        >
          <div className="flex gap-4 py-4">
            {levels.map((level) => (
              <button
                key={level}
                className={`flex items-center gap-2 px-4 py-2 text-[13px] rounded-full border-2 transition-all ${
                  selectedLevel === level
                    ? "bg-secondary text-white font-semibold border-secondary"
                    : "bg-white text-gray-500 border-gray-300"
                }`}
                onClick={() => toggleLevel(level)}
              >
                <input
                  type="checkbox"
                  className={`w-5 h-5 rounded-full appearance-none checked:bg-black checked:border-black checked:ring-2 checked:ring-white`}
                  checked={selectedLevel === level}
                  onChange={() => toggleLevel(level)}
                />
                {level}
              </button>
            ))}
          </div>
        </CardTile>
        <CardTile
          title="Course Language"
          description="Select the language your course will be delivered in."
        >
          <div className="flex gap-4 py-4">
            {levelsTwo.map((level) => (
              <button
                key={level}
                className={`flex items-center gap-2 px-4 py-2 text-[13px] rounded-full border-2 transition-all ${
                  selectedLevelTwo === level
                    ? "bg-secondary text-white font-semibold border-secondary"
                    : "bg-white text-gray-500 border-gray-300"
                }`}
                onClick={() => toggleLevelTwo(level)}
              >
                <input
                  type="checkbox"
                  className={`w-5 h-5 rounded-full appearance-none checked:bg-black checked:border-black checked:ring-2 checked:ring-white`}
                  checked={selectedLevelTwo === level}
                  onChange={() => toggleLevelTwo(level)}
                />
                {level}
              </button>
            ))}
            <button
                className={`flex items-center gap-2 px-4 py-2 text-[20px] rounded-full border-2 transition-all text-black font-semibold border-gray-300`}
                onClick={() => setOpen(true)}
              >
                +
              </button>
          </div>
        </CardTile>
        <CardTile
            title="Course Tags"
            description="Add up to 20 tags to help learners easily find your course. Use keywords related to your course content."
            >
            <div className="flex flex-col justify-between items-stretch rounded-lg p-4 bg-input-background min-h-60">
            <EditableTagField name={'topics'} id={'topics'} placeholder={'Add Tags'} values={userTopics} onChange={setUserTopics} />

          <h4 className="text-sm text-gray-500 mb-2">Suggestions</h4>
          <div className="flex flex-wrap gap-2">
            {suggestedTags.map((tag, index) => (
              <button
                key={index}
                onClick={() => setUserTopics(tag)}
                className={`px-3 py-1 text-sm border rounded-full ${
                  userTopics.includes(tag)
                    ? "bg-gray-300 text-gray-600 border-gray-400 cursor-default"
                    : " text-gray-700 hover:bg-gray-200 border-gray-300"
                }`}
                disabled={userTopics.includes(tag)} // Disable if already selected
              >
                + {tag}
              </button>
            ))}
          </div>
        </div>
        </CardTile>
        <CardTile
          title="Course Description"
          description="Write a brief overview of the course, highlighting it's purpose and what learner's will achieve"
        >
          <div className="relative">
            <FloatingTextField
              label="Course Description"
              type="text"
              name="Course Description"
              id="courseDescription"
              className="w-full min-h-60 py-2 my-4"
              value={courseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
            />
            <p className="text-end w-full text-sm text-gray-500 pt-1 pb-2">{`${countLetters(
              courseTitle
            )}/1200 max`}</p>
          </div>
        </CardTile>
      </div>
      <Drawer open={open} onClose={setOpen} title="Course Language">
        <fieldset>
          <div className="relative w-full">
            <MagnifyingGlassIcon
              className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id={"head-search"}
              type={"text"}
              placeholder="Search language"
              // onClick={() => setOpen(true)}
              // readOnly
              className={
                "bg-white border text-sm border-gray-400 rounded-4xl w-full max-w-md h-10 pl-8 text-input-text placeholder:text-input-placeholder"
              }
            />
          </div>
          <div className="mt-6 space-y-6">
            {notificationMethods.map((notificationMethod) => (
              <div key={notificationMethod.id} className="flex items-center">
                <input
                  id={notificationMethod.id}
                  name="notification-method"
                  type="checkbox"
                  className="w-5 h-5 rounded-full appearance-none checked:bg-black checked:border-black checked:ring-2 checked:ring-white"
                />
                <label
                  htmlFor={notificationMethod.id}
                  className="ml-3 block text-lg font-medium text-gray-900">
                  {notificationMethod.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </Drawer>
    </>
  );
}
