import { Button } from "../../../components/ui-components"


export default function CourseSuccessful () {
    return(
        <>
        <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="w-[60%] flex flex-col justify-center items-center">

            <svg width="95" height="94" viewBox="0 0 95 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0337 21.7914C11.0337 18.756 12.2395 15.8448 14.3859 13.6984C16.5323 11.552 19.4435 10.3462 22.4789 10.3462H27.6813C30.7034 10.3445 33.6021 9.14758 35.745 7.01667L39.3867 3.37501C40.4503 2.3054 41.7149 1.45659 43.1076 0.87738C44.5004 0.298174 45.994 0 47.5024 0C49.0108 0 50.5044 0.298174 51.8971 0.87738C53.2899 1.45659 54.5545 2.3054 55.6181 3.37501L59.2598 7.01667C61.4027 9.14758 64.3014 10.3445 67.3235 10.3462H72.5259C75.5613 10.3462 78.4725 11.552 80.6189 13.6984C82.7653 15.8448 83.9711 18.756 83.9711 21.7914V26.9938C83.9728 30.0159 85.1697 32.9146 87.3006 35.0575L90.9423 38.6992C92.0119 39.7628 92.8607 41.0274 93.4399 42.4201C94.0191 43.8129 94.3173 45.3065 94.3173 46.8149C94.3173 48.3233 94.0191 49.8169 93.4399 51.2096C92.8607 52.6024 92.0119 53.867 90.9423 54.9306L87.3006 58.5723C85.1697 60.7152 83.9728 63.6139 83.9711 66.636V71.8384C83.9711 74.8738 82.7653 77.785 80.6189 79.9314C78.4725 82.0778 75.5613 83.2836 72.5259 83.2836H67.3235C64.3014 83.2853 61.4027 84.4822 59.2598 86.6131L55.6181 90.2548C54.5545 91.3244 53.2899 92.1732 51.8971 92.7524C50.5044 93.3316 49.0108 93.6298 47.5024 93.6298C45.994 93.6298 44.5004 93.3316 43.1076 92.7524C41.7149 92.1732 40.4503 91.3244 39.3867 90.2548L35.745 86.6131C33.6021 84.4822 30.7034 83.2853 27.6813 83.2836H22.4789C19.4435 83.2836 16.5323 82.0778 14.3859 79.9314C12.2395 77.785 11.0337 74.8738 11.0337 71.8384V66.636C11.032 63.6139 9.83508 60.7152 7.70417 58.5723L4.06251 54.9306C2.9929 53.867 2.14409 52.6024 1.56488 51.2096C0.985674 49.8169 0.6875 48.3233 0.6875 46.8149C0.6875 45.3065 0.985674 43.8129 1.56488 42.4201C2.14409 41.0274 2.9929 39.7628 4.06251 38.6992L7.70417 35.0575C9.83508 32.9146 11.032 30.0159 11.0337 26.9938V21.7914Z" fill="#379D46"/>
            <path d="M67.4727 38.7764L44.6201 61.6289L30.8438 47.8526L35.3818 43.3145L44.6201 52.5527L62.9346 34.2383L67.4727 38.7764Z" fill="white"/>
            </svg>

            <h1 className="font-medium text-3xl text-center pt-8">Course Submitted Successfully!</h1>
            <p className="font-medium text-gray-400 w-[80%] text-sm text-center py-8">Your course has been submitted for review. Our team will review it promptly, often within 8 hours, and notify you about the status on the SimpliTrain platform.</p>
            </div>

            <div className="w-[60%] flex flex-col justify-start items-start py-8 bg-white rounded-[24px] px-8">
                <h1 className="font-medium text-xl text-left pt-4">How to becoming UX designer designer </h1>
                <p className="font-medium text-gray-400 text-sm text-left py-4">17 chapter : intermediate : English</p>
                <span className="h-[1px] bg-gray-400 w-[85%]" />
                <button className="flex flex-row justify-start items-center border-t border-gray-400">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#E8E7E7" fill-opacity="0.6"/>
                    <path d="M14.9583 26.0417H16.0865L23.825 18.3031L22.6969 17.175L14.9583 24.9135V26.0417ZM13.375 27.625V24.2604L23.825 13.8302C23.9833 13.6851 24.1582 13.5729 24.3495 13.4937C24.5408 13.4146 24.742 13.375 24.9531 13.375C25.1642 13.375 25.3687 13.4146 25.5667 13.4937C25.7646 13.5729 25.9361 13.6917 26.0813 13.85L27.1698 14.9583C27.3281 15.1035 27.4436 15.275 27.5161 15.4729C27.5887 15.6708 27.625 15.8687 27.625 16.0667C27.625 16.2778 27.5887 16.479 27.5161 16.6703C27.4436 16.8616 27.3281 17.0365 27.1698 17.1948L16.7396 27.625H13.375ZM23.251 17.749L22.6969 17.175L23.825 18.3031L23.251 17.749Z" fill="#5F6368"/>
                    </svg>
                    <p>Edit Course</p>
                </button>
            </div>
            <div className="w-[50%] flex flex-row justify-evenly items-center pt-10">
            <button className="w-max px-4 h-12 text-secondary font-medium border-2 border-gray-800 bg-white rounded-[13px]"> Create another Course</button> 
            <button className="w-max px-4 h-12 text-white font-medium bg-secondary border border-gray-800 rounded-[13px]">View Submitted Courses</button>
            </div>
        </div>
        </>
    )
}