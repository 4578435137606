import { ArrowLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { checkIsMobile } from "../../utils/helpers";

const CourseInfo = () => {
  const isMobile = checkIsMobile();
  return (
    <div className="flex flex-col px-6 gap-5 pt-10 md:pt-0">
      <p className="hidden md:flex flex-row justify-start items-center gap-1 pl-5">
        <a className="text-xs text-text font-medium">Home</a>{" "}
        <ChevronRightIcon className="size-3" />{" "}
        <a className="text-xs text-text font-medium">Information Technology</a>{" "}
        <ChevronRightIcon className="size-3" />{" "}
        <span className="text-xs text-[#00000040] font-medium">
          How to becoming ux designer
        </span>
      </p>
      <div className="flex flex-row justify-between items-start gap-4 -ml-5">
        {!isMobile && (
          <div className="w-10">
            <ArrowLeftIcon className="size-6 mt-1.5" />
          </div>
        )}

        <div className="flex flex-col gap-6 justify-start items-start">
          <div className="flex flex-col md:flex-row justify-between items-start">
            {isMobile && (
              <p className="text-xs text-white bg-[#464646] px-2 rounded-xl">
                BestSeller
              </p>
            )}
            <div>
              <h3 className="text-2xl text-text font-bold">
                How to becoming UX designer <span>Bestseller</span>
              </h3>
              <p className="text-primary">
                Join our comprehensive 2-week online UX Design course, designed
                to introduce you to the essential principles and practices of
                user experience design.{" "}
              </p>
            </div>
            <div className="hidden md:flex flex-row justify-between items-center gap-4">
              <button className="p-2">
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 18.9984L8.55 17.6984C6.86667 16.1818 5.475 14.8734 4.375 13.7734C3.275 12.6734 2.4 11.6859 1.75 10.8109C1.1 9.93594 0.645833 9.13177 0.3875 8.39844C0.129167 7.6651 0 6.9151 0 6.14844C0 4.58177 0.525 3.27344 1.575 2.22344C2.625 1.17344 3.93333 0.648438 5.5 0.648438C6.36667 0.648438 7.19167 0.831771 7.975 1.19844C8.75833 1.5651 9.43333 2.08177 10 2.74844C10.5667 2.08177 11.2417 1.5651 12.025 1.19844C12.8083 0.831771 13.6333 0.648438 14.5 0.648438C16.0667 0.648438 17.375 1.17344 18.425 2.22344C19.475 3.27344 20 4.58177 20 6.14844C20 6.9151 19.8708 7.6651 19.6125 8.39844C19.3542 9.13177 18.9 9.93594 18.25 10.8109C17.6 11.6859 16.725 12.6734 15.625 13.7734C14.525 14.8734 13.1333 16.1818 11.45 17.6984L10 18.9984ZM10 16.2984C11.6 14.8651 12.9167 13.6359 13.95 12.6109C14.9833 11.5859 15.8 10.6943 16.4 9.93594C17 9.1776 17.4167 8.5026 17.65 7.91094C17.8833 7.31927 18 6.73177 18 6.14844C18 5.14844 17.6667 4.3151 17 3.64844C16.3333 2.98177 15.5 2.64844 14.5 2.64844C13.7167 2.64844 12.9917 2.86927 12.325 3.31094C11.6583 3.7526 11.2 4.3151 10.95 4.99844H9.05C8.8 4.3151 8.34167 3.7526 7.675 3.31094C7.00833 2.86927 6.28333 2.64844 5.5 2.64844C4.5 2.64844 3.66667 2.98177 3 3.64844C2.33333 4.3151 2 5.14844 2 6.14844C2 6.73177 2.11667 7.31927 2.35 7.91094C2.58333 8.5026 3 9.1776 3.6 9.93594C4.2 10.6943 5.01667 11.5859 6.05 12.6109C7.08333 13.6359 8.4 14.8651 10 16.2984Z"
                    fill="#5F6368"
                  />
                </svg>
              </button>
              <button className="p-2">
                <svg
                  width="16"
                  height="22"
                  viewBox="0 0 16 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2 22C1.45 22 0.979167 21.8042 0.5875 21.4125C0.195833 21.0208 0 20.55 0 20V9C0 8.45 0.195833 7.97917 0.5875 7.5875C0.979167 7.19583 1.45 7 2 7H5V9H2V20H14V9H11V7H14C14.55 7 15.0208 7.19583 15.4125 7.5875C15.8042 7.97917 16 8.45 16 9V20C16 20.55 15.8042 21.0208 15.4125 21.4125C15.0208 21.8042 14.55 22 14 22H2ZM7 15V3.825L5.4 5.425L4 4L8 0L12 4L10.6 5.425L9 3.825V15H7Z"
                    fill="#5F6368"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex">
            <div className="mr-2 md:mr-4 shrink-0">
              <svg
                width={isMobile ? "40": "52"}
                height={isMobile ? "40": "52"}
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="26"
                  cy="26"
                  r="26"
                  fill="#D9D9D9"
                  fill-opacity="0.7"
                />
              </svg>
            </div>
            <div>
              <h4 className="text-sm md:text-lg font-bold">Rohan Joshi</h4>
              <p className="md:mt-1 text-xs text-primary">Product Designer, at HCL Pvt.Ltd</p>
            </div>
          </div>
          <div className="grid grid-cols-2 m-auto md:m-0 md:flex md:flex-row items-baseline justify-start gap-6">
            {[
              { name: "Level", value: "Beginner" },
              { name: "Total Hours", value: "2 hours 30 mins" },
              { name: "Mode", value: "Online / Classroom" },
              { name: "Language", value: "English" },
            ].map((level, index) => (
              <div key={index} className="flex gap-1 md:gap-3 items-center">
                <div className="shrink-0">
                  <svg
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                      opacity="0.3"
                      width="42"
                      height="42"
                      rx="10"
                      fill="#C6C6C6"
                    />
                  </svg>
                </div>
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    {level.name}
                  </p>
                  <p className="truncate text-sm text-gray-500">
                    {level.value}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
