import React, { useEffect, useState } from "react";
import CategoryMiddle from "./CategoryMiddle";
import CategoryVideo from "./CategoryVideo";
import { Container } from "../../components/ui-components";
import store from "../../redux/store";
import { getCategory, getSubCategoryById } from "../../redux/category/actionCreator";
import { useLoaderData } from "react-router-dom";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getCategoryList = () => {
  return new Promise(async (resolve, reject) => {
    const responce = await store.dispatch(getCategory());
    // console.log("responceresponceresponce", responce);
    if (responce && responce.type == "CATEGORY_SUCCESS") {
      resolve(responce.data);
    } else {
      resolve([]);
    }
  });
};

export const getSubCategoryData = async(userID) => {
  return new Promise(async(resolve, reject)=>{
    const response = await store.dispatch(getSubCategoryById({id:userID}));
    if(response && response.type === 'CATEGORY_SUCCESS'){
      resolve(response.data);
    }else{
      resolve([]);
    }
  })
}

export async function categoryLoader() {
  alert("sasaasasas");
  const category = await getCategoryList();
  console.log("Category Loader Output:", { category });
  return {
    date: new Date().toISOString(),
    // category: category,
  };
}

const Category = () => {
  let loaderData = useLoaderData();
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("")
  const [selectedCategoryId, setSelectedCategoryId] = useState("")
  // console.log("loader Data", loaderData);

  const handleCategoryClick = async (categoryId, categoryName) =>{
    // console.log(categoryId, categoryName);
    setSelectedCategoryId(categoryId)
    setCategoryName(categoryName);
  }

  const getCats = async () => {
    const categoryList = await getCategoryList();
    // console.log("categoryList", categoryList);
    if (categoryList.length > 0) {
      setCategories(categoryList);

      setSelectedCategoryId(categoryList[0].id);
      setCategoryName(categoryList[0].name);
    }
  };


  useEffect(() => {
    getCats();
  }, []);

  return (
    <>
      <section className="py-10 border-b border-b-gray-5">
        <Container className="max-w-full">
          <h2 className="text-2xl text-text font-bold pl-5">
            Explore All Categories
          </h2>
        </Container>
      </section>
      <section className="border-b border-b-gray-5">
        <Container className="max-w-full">
          <div className="flex flex-row">
            <div className="w-[70%] flex ">
              <div className="w-full flex flex-row">
                <nav
                  aria-label="Sidebar"
                  className="w-[30%] h-[70vh] flex flex-col border-r-[1px] py-8 px-3 gap-4 overflow-y-auto custom-scroll">
                  <h2 className="text-base font-semibold mx-2">
                    Browse by category
                  </h2>
                  <ul role="list" className="space-y-1">
                    {categories.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-200 text-primary font-bold text-lg"
                              : "text-gray-3 hover:bg-gray-200 hover:text-primary hover:font-bold",
                            "group flex flex-row items-center rounded-md p-2 pl-3 text-sm text-gray-400 whitespace-nowrap"
                          )} onClick={()=>handleCategoryClick(item.id, item.name)}>
                          {item.name}

                          <span
                            aria-hidden="true"
                            className=" w-9 min-w-max whitespace-nowrap rounded-full px-2 py-1 text-center text-sm font-medium text-gray-600">
                            (120)
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
                <CategoryMiddle categoryId={selectedCategoryId} categoryName={categoryName} />
              </div>
            </div>
            <div className="w-[30%] h-[70vh] flex flex-col p-6 overflow-y-auto">
              <CategoryVideo />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Category;
