import React, { useState } from "react";
import { Container, Button } from "../../../components/ui-components";
import SidePanel from "./SidePanel";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import CourseReview from "./CourseReview";
import CourseSuccessful from "./CourseSuccessful";

export async function createCourseLoader() {
  return {
    date: new Date().toISOString(),
  };
}

export default function CreateCourse() {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 6));
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      case 5:
        return <CourseReview />;
      case 6:
        return <CourseSuccessful />;
      default:
        return null;
    }
  };

  const isSidePanelVisible = currentStep <= 4; // Show SidePanel only for steps 1 to 4

  const buttonText = (() => {
    if (currentStep === 4) return "Review and Submit";
    if (currentStep === 5) return "Submit";
    return "Next ->";
  })();

  return (
    <div className="bg-[#EFEFEF] pt-24 w-full h-full flex flex-col items-center justify-center">
      <section className="flex flex-row justify-center items-start w-full h-full">
        {isSidePanelVisible && (
          <Container className="w-[20%]">
            <SidePanel current_step={currentStep} />
          </Container>
        )}

        <div className={`w-full h-full flex flex-col grow justify-center items-start px-6 ${isSidePanelVisible ? "w-[80%]" : "w-full"}`}>
          {renderContent()} 
          {currentStep < 6 && ( 
            <footer className="w-full flex justify-end items-center py-4">
              <Button
                onClick={nextStep}
                text={buttonText} 
                className="min-w-32 w-max h-[50px]"
              />
            </footer>
          )}
        </div>
      </section>
    </div>
  );
}
