import React from "react";
import { Container } from "../../../components/ui-components";
import {
  ArrowLeftIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import folderPoll from "../../../assets/svgs/folderPoll.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

export const pollsFoldersLoader = async () => {
  return {
    date: new Date().toISOString(),
  };
};

const pollsData = [
  { id: 1, question: "How Can We Improve This Course?" },
  { id: 2, question: "What Topics Would You Like to See Covered Next?" },
  { id: 3, question: "How Satisfied Are You with the Course Content?" },
  { id: 4, question: "How Can We Improve This Course?" },
];

function PollsFolders() {
  const location = useLocation();
  const pollName = location.state?.pollName;
  const navigate = useNavigate();
  return (
    <div className="w-full pt-24 bg-gray-100 h-screen">
      <Container className="flex flex-col gap-6">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center gap-4">
            <ArrowLeftIcon
              className="size-5"
              onClick={() => window.history.back()}
            />
            <h1 className="md:text-xl font-medium">{pollName}</h1>
          </div>
          <button className="text-sm text-white bg-[#0E121D] rounded-xl px-4 py-2" onClick={()=>navigate("/instructor_courses/poll_template")}>
            + Create Poll Template
          </button>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {pollsData.map((item) => (
            <div key={item.id} className="flex flex-col gap-2 bg-white w-full p-4 rounded-xl">
              <img src={folderPoll} alt="" className="w-full rounded-xl h-52" />
              <div className="flex flex-row gap-4 justify-between">
                <h3 className="text-sm font-medium">{item.question}</h3>
                <Menu as="div" className="relative inline-block text-left">
                  <MenuButton className="text-gray-800 text-sm font-semibold rounded-full">
                    <EllipsisVerticalIcon className="size-5" />
                  </MenuButton>
                  <MenuItems
                    transition
                    // right-0 z-10 mt-2
                    className="absolute right-6 md:right-0 lg:right-0 xl:right-0 top-6 md:top-[44px] lg:top-[44px] xl:top-[44px] w-56 origin-top-right divide-y divide-gray-6 rounded-2xl bg-white shadow-3xl transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in flex flex-col justify-center">
                    <div className="pb-3 md:pb-0 lg:pb-0 md:py-1  px-2">
                      <MenuItem>
                        <a className="flex flex-row justify-start items-center px-4 py-2 text-base font-medium text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                          Share
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a className="flex flex-row justify-start items-center px-4 py-2 text-base font-medium text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                          Copy Link
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a className="flex flex-row justify-start items-center px-4 py-2 text-base font-medium text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                          Share via Email
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default PollsFolders;
