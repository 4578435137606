import React from "react";
import { checkIsMobile } from "../../../utils/helpers";

const WhatLearn = () => {
    const isMobile = checkIsMobile();
  return (
    <div className="flex flex-col">
        <h5 className="text-2xl md:text-3xl text-text font-bold mb-5 md:mb-10">What You’ll Learn</h5>
        <div className="grid grid-cols-1 gap-4 md:gap-6">
            <div className={`flex flex-row gap-3 justify-start ${isMobile ? "items-center": "items-start"}`}>
                <div className="flex w-16">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="16" fill="#F7F7F7"/>
                    </svg>
                </div>
                <div className="flex flex-col w-full">
                    <h5 className="text-lg text-text font-medium">User Research</h5>
                    <p className="text-sm text-text">Create products that provide meaningful and relevant experiences to users. This invol the design of the a entire process of acquiring and integrating the product.</p>
                </div>
            </div>
            <div className="flex flex-row gap-3 justify-start items-start">
                <div className="flex w-16">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="16" fill="#F7F7F7"/>
                    </svg>
                </div>
                <div className="flex flex-col w-full">
                    <h5 className="text-lg text-text font-medium">Information Architecture</h5>
                    <p className="text-sm text-text">Create products that provide meaningful and relevant experiences to users. This invol the design of these entire process.</p>
                </div>
            </div>
            <div className="flex flex-row gap-3 justify-start items-start">
                <div className="flex w-16">
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" rx="16" fill="#F7F7F7"/>
                    </svg>
                </div>
                <div className="flex flex-col w-full">
                    <h5 className="text-lg text-text font-medium">Wireframing</h5>
                    <p className="text-sm text-text">Create products that provide meaningful and relevant experiences to users. This invol the designing of th entire process of acquiring.</p>
                </div>
            </div>
        </div>
      </div>
  );
};

export default WhatLearn;
