import { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Datepicker from "react-tailwindcss-datepicker";

const timeline = [
    {
      id: 1,
      content: 'Class 1',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
    {
      id: 2,
      content: 'Class 2',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
    {
      id: 3,
      content: 'Class 3',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'  
    },
    {
      id: 4,
      content: 'Class 4',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
    {
      id: 5,
      content: 'Class 5',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
    {
      id: 6,
      content: 'Class 6',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
    {
      id: 7,
      content: 'Class 7',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
    {
      id: 8,
      content: 'Class 8',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
    {
      id: 9,
      content: 'Class 9',
      start_date :'13 Dec 24, Mon',
      start_time:'10:30 am',
      end_time:'1:30 am'
    },
  ];
  
const Time = ['12:00' , '12:30'  , "1:00" , '1:30' , '2:00' , '2:30']


  export const CardTile = ({ title, description, children }) => (
    <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
      <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
      <p className="text-gray-500 text-sm mb-4">{description}</p>
      <div className="w-full">{children}</div>
    </div>
  );
  

  export default function BatchSchedule() {
     
    const [showEdit , setShowEdit] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime , setStartTime] = useState(null);
    const [endTime , setEndTime] = useState(null);

  const handleDateChange = (range) => {
    const { startDate: newStartDate, endDate: newEndDate } = range;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <>
      <div className="w-full flex flex-col justify-start items-center grow overflow-y-auto px-8">
        {
          showEdit ? 
          <div className="w-full flex flex-col justify-center items-start pt-6">
          <div className="w-full flex flex-row justify-between items-center pt-6">
          <h1 className="font-bold text-lg text-left text-secondary">
          Edit Batch Schedule
          </h1>
          <button onClick={() => {setShowEdit(false)}} className="flex justify-center items-center text-sm font-medium p-2 px-4 text-white bg-primary rounded-lg ">
            Save
          </button>
          </div>
          <div className="w-full flex flex-row justify-center items-center mb-4 pt-8">
        <div className="w-full flex flex-row justify-center items-center mb-4">
           <Datepicker
           placeholder="Start Date"
           popoverDirection="down"
            value={{ startDate, endDate }}
            onChange={handleDateChange}
          />
            <Datepicker
            placeholder="End Date"
            popoverDirection="down"
            value={{ startDate, endDate }}
            onChange={handleDateChange}
          />
        </div>
        <div className="px-2 w-full flex flex-row justify-center items-center mb-4">
        <Menu as="div" className="relative inline-block text-left mt-2">
      <div>
        <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
        <div className={`flex flex-col justify-start items-start px-4 bg-input-background rounded-lg overflow-hidden `}>
          <span className="relative inline-flex items-center bg-transparent  after:absolute  a after:h-5 after:w-2 after:right-0 after:bottom-3.5">Start Time</span>
          <input type='text' id='startTime' className="block h-10 px-4 py-1 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" placeholder='Add Start Time' value={startTime} />
        </div>
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
        {Time.map((value) => (
          <MenuItem>
            <a
              href="#"
              onClick={() => {setStartTime(value)}}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              {value}
            </a>
          </MenuItem>
          ))}
          <MenuItem>
           <div className="flex justify-evenly items-center">
           <a
              href="#"
              onClick={() => {setStartTime("AM")}}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              AM
            </a>
            <a
              href="#"
              onClick={() => {setStartTime("PM")}}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              PM
            </a>
           </div>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
    <Menu as="div" className="relative inline-block text-left mt-2">
      <div>
        <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
        <div className={`flex flex-col justify-start items-start px-4 bg-input-background rounded-lg overflow-hidden `}>
          <span className="relative inline-flex items-center bg-transparent  after:absolute  a after:h-5 after:w-2 after:right-0 after:bottom-3.5">End Time</span>
          <input type='text' id='startTime' className="block h-10 px-4 py-1 w-full text-base text-input-text placeholder-transparent bg-input-background border-0 appearance-none  focus:outline-none focus:ring-0 focus:border-0 peer" placeholder='Add Start Time' value={endTime} />
        </div>
        </MenuButton>
      </div>
      <MenuItems
        transition
        className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {Time.map((value) => (
        <div className="py-1">
          <MenuItem>
            <a
              href="#"
              onClick={() => {setEndTime(value)}}
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              {value}
            </a>
          </MenuItem>
        </div>
          ))}
      </MenuItems>
    </Menu>
        </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center">
            <p className="text-gray-500 text-md mb-4">Select the days classes will take place.</p>
            <div className="flex flex-row items-center justify-start text-gray-400 pb-12">

            <input className="w-5 h-5 mr-2 ml-5 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" /> <label>Mon</label>
            <input className="w-5 h-5 mr-2 ml-5 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" /> <label>Tue</label>
            <input className="w-5 h-5 mr-2 ml-5 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" /> <label>wed</label>
            <input className="w-5 h-5 mr-2 ml-5 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" /> <label>Thu</label>
            <input className="w-5 h-5 mr-2 ml-5 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" /> <label>Fri</label>
            <input className="w-5 h-5 mr-2 ml-5 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" /> <label>Sat</label>
            <input className="w-5 h-5 mr-2 ml-5 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" /> <label>Sun</label>
            </div>
        </div>
        </div>
        
         : 
          <div className="w-full flex flex-row justify-between items-center pt-6">
          <h1 className="font-bold text-lg text-left text-secondary">
            Batch Schedule
          </h1>
          <button onClick={() => {setShowEdit(true)}} className="flex justify-center items-center text-sm font-medium p-2 border-2 border-gray-400 rounded-full ">
            <svg
              className="mx-2"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.54167 11.4583H2.55104L9.475 4.53438L8.46562 3.525L1.54167 10.449V11.4583ZM0.125 12.875V9.86458L9.475 0.532292C9.61667 0.402431 9.77309 0.302083 9.94427 0.23125C10.1155 0.160417 10.2955 0.125 10.4844 0.125C10.6733 0.125 10.8562 0.160417 11.0333 0.23125C11.2104 0.302083 11.3639 0.408333 11.4937 0.55L12.4677 1.54167C12.6094 1.67153 12.7127 1.825 12.7776 2.00208C12.8425 2.17917 12.875 2.35625 12.875 2.53333C12.875 2.72222 12.8425 2.90226 12.7776 3.07344C12.7127 3.24462 12.6094 3.40104 12.4677 3.54271L3.13542 12.875H0.125ZM8.96146 4.03854L8.46562 3.525L9.475 4.53438L8.96146 4.03854Z"
                fill="#505050"
              />
            </svg>
            Edit
          </button>
        </div>
        
        }
        <CardTile
          title="Plan your class schedule"
          description="total of 10 class"
        >
          <div className="flex flex-col justify-start items-start">
            <p className="w-full pl-32 text-md text-gray-400 mt-3 flex flex-row justify-between px-6 item-center">
              <p className="flex flex-col justify-center items-start w-[10%]">Date</p>
              <div className="w-full flex justify-evenly">
                <p className="pl-4 flex flex-col justify-center items-start">
                  Start Time
                </p>
                <p className="flex flex-col justify-center items-start">
                  End Time
                </p>
              </div>
            </p>
            <ul className="w-full mb-8">
              {timeline.map((event) => (
                <li className="w-full h-20" key={event.id}>
                  <div className="w-full flex flex-row items-center justify-start relative pb-1">
                    <p className="font-medium mt-2">{event.content}</p>

                    <div
                      className={`w-[90%] mt-2 relative flex items-center space-x-3 rounded-lg py-2`}
                    >
                      <span
                        aria-hidden="true"
                        className="absolute left-5 top-5 mt-2 h-20 w-0.5 bg-gray-300"
                      />
                      <div>
                        <span
                          className={` mr-2 flex size-4 items-center justify-center rounded-full`}
                        >
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="z-20"
                          >
                            <circle
                              cx="6.80817"
                              cy="7.15192"
                              r="6.01645"
                              fill="#F0F0F0"
                              stroke="#8B929F"
                              strokeWidth="0.802194"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="w-full flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                        <div className="w-full border border-gray-300 rounded-lg min-h-12">
                          <p className="w-full text-md font-medium mt-3 flex flex-row justify-between px-6 item-center">
                            <p className="flex flex-row justify-center items-start">
                              {event.start_date}{" "}
                            </p>
                            <p className="flex flex-row ml-4 justify-center items-start">
                              {event.start_time}
                              <svg
                                className="mt-2.5 ml-2"
                                width="13"
                                height="6"
                                viewBox="0 0 13 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.6875 1L6.1684 5L0.6875 1"
                                  stroke="black"
                                  stroke-width="1.6"
                                />
                              </svg>
                            </p>
                            <p className="flex flex-row justify-center items-start">
                              {event.end_time}
                              <svg
                                className="mt-2.5 ml-2"
                                width="13"
                                height="6"
                                viewBox="0 0 13 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.6875 1L6.1684 5L0.6875 1"
                                  stroke="black"
                                  stroke-width="1.6"
                                />
                              </svg>
                            </p>
                            <svg
                              className="opacity-0 hover:opacity-100 focus:opacity-100 transition-opacity duration-200"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                fill="#5F6368"
                              />
                            </svg>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </CardTile>
      </div>
    </>
  );
}
