import Button from "../../../components/ui-components/Button";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Container } from "../../../components/ui-components";
import {useNavigate} from 'react-router-dom'

export default function MyBatches() {

    const navigate = useNavigate()

    function newBatch (val) {
        navigate(`/createBatch/${val}`);
    }

    return(
        <>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
             <h1 className=" font-semibold text-lg ml-4">My Batches</h1>
            
            <Menu as="div" className="relative inline-block text-left mt-2">
                <div>
                    <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
                    <Button size='md' text='+ New Batch'/>
                    </MenuButton>
                </div>
                <MenuItems
                    transition
                    className="absolute right-8 top-0 z-10 my-4 w-52 origin-top-right rounded-[24px] bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="py-1">
                    <MenuItem>
                        <button
                        onClick={() => newBatch('online')}
                        className="block px-4 py-2 text-sm text-black font-medium data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                        + Online Batch
                        </button>
                    </MenuItem>
                    <MenuItem>
                        <button
                         onClick={() => newBatch('classroom')}
                        className="block px-4 py-2 text-sm text-black font-medium data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                        + Classroom Batch
                        </button>
                    </MenuItem>
                    <MenuItem>
                        <button
                        onClick={() => newBatch('one_on_one')}
                        className="block px-4 py-2 text-sm text-black font-medium data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                        >
                        + One On One Batch
                        </button>
                    </MenuItem>
                    </div>
                </MenuItems>
            </Menu>

          </div>
          <div className="ml-2 grid grid-flow-col auto-cols-[170px] gap-4 items-start py-4">
            <Container className='bg-gray-200 h-[123px] w-[170px] rounded-[30px] flex flex-col items-start justify-between'> 
                <p className="font-semibold mt-4 text-text">7</p>
                <p className="text-sm font-medium mb-4">Active</p>
            </Container>
            <Container className='bg-gray-200 h-[123px] w-[170px] rounded-[30px] flex flex-col items-start justify-between'> 
                
                <p className="font-semibold mt-4 text-text">3</p>
                <p className="text-sm font-medium mb-4">Upcoming</p>
            </Container>
            <Container className='bg-gray-200 h-[123px] w-[170px] rounded-[30px] flex flex-col items-start justify-between'> 
                <p className="font-semibold mt-4 text-text">2704</p>
                <p className="text-sm font-medium mb-4">Total Enrolled</p>
            </Container>
            <Container className='bg-gray-200 h-[123px] w-[170px] rounded-[30px] flex flex-col items-start justify-between'> 
                <div className="flex flex-row p-1 mt-4 items-start w-full ">
                    <svg className="mr-2" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4765 17.4271L8.27411 19.9382C8.0862 20.0407 7.90256 20.0834 7.72319 20.0663C7.54382 20.0493 7.3858 19.9895 7.24914 19.887C7.11248 19.7845 7.00998 19.6435 6.94165 19.4642C6.87332 19.2848 6.86478 19.1012 6.91603 18.9133L8.01787 14.224L4.30236 11.0723C4.14862 10.9356 4.05466 10.7776 4.0205 10.5982C3.98633 10.4188 3.99488 10.2438 4.04612 10.0729C4.09737 9.90209 4.19133 9.76116 4.32799 9.65012C4.46465 9.53909 4.63548 9.47503 4.84047 9.45794L9.70906 9.02233L11.6309 4.56373C11.7163 4.37582 11.8359 4.23489 11.9896 4.14093C12.1434 4.04698 12.3056 4 12.4765 4C12.6473 4 12.8096 4.04698 12.9633 4.14093C13.1171 4.23489 13.2367 4.37582 13.3221 4.56373L15.2439 9.04796L20.1125 9.45794C20.3175 9.47503 20.4883 9.54336 20.6249 9.66294C20.7616 9.78252 20.8556 9.92772 20.9068 10.0985C20.9581 10.2694 20.9623 10.4402 20.9196 10.611C20.8769 10.7819 20.7787 10.9356 20.6249 11.0723L16.9351 14.224L18.0369 18.9133C18.0882 19.1012 18.0796 19.2848 18.0113 19.4642C17.943 19.6435 17.8405 19.7845 17.7038 19.887C17.5671 19.9895 17.4091 20.0493 17.2297 20.0663C17.0504 20.0834 16.8667 20.0407 16.6788 19.9382L12.4765 17.4271Z" fill="#5F6368"/>
                    </svg>
                    <p className="font-semibold text-text">4.8 (188)</p>
                </div>
                <p className="text-sm font-medium ml-2 mb-4">Ratings</p>
            </Container>
          </div>
        </div>
        </>
    );
}