import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Tabs } from 'antd';

const courseData = [
    { title: "How to become a UX Designer", description: "17 Chapter | intermediate | English" , Batches : "3" , Enrolled:"240" , Ratings:"4.7" },
    { title: "Advance UI Design Principles", description: "27 Chapter | intermediate | English" , Batches : "7" , Enrolled:"322" , Ratings:"4.2" },
    { title: "Become a Design Leader", description: "19 Chapter | Difficult | English" , Batches : "0" , Enrolled:"74" , Ratings:"4.8" },
    { title: "Building Effective Prototypes", description: "27 Chapter | Beginner | English" , Batches : "3" , Enrolled:"245" , Ratings:"4.3" },
    { title: "Designing for Accessibility", description: "20 Chapter | intermediate | English" , Batches : "4" , Enrolled:"341" , Ratings:"4.6" },
  ];

const Card = ({ title, description , Batches , Enrolled , Ratings }) => (
    <div className="bg-white p-4 rounded-[34px] mb-4 flex justify-between h-[149px] w-full">
        <div className='bg-gray-200 h-[106px] w-[140px] rounded-[30px] flex flex-col items-start justify-between'></div>
        <div className='flex flex-col items-start justify-evenly mb-4 ml-6 w-[40%]'>
            <h3 className="font-semibold text-lg">{title}</h3>
            <p className="text-gray-500 text-sm">{description}</p>
            <p className="text-gray-400 text-center text-[10px] w-[62px] mt-2 border-gray-300 border-2 p-1 rounded-full">DESIGN</p>
        </div>
        <div className='flex justify-evenly items-center p-2 mb-8 w-[60%]'>
            <div>
                <p className="text-text text-lg font-bold">{Batches}</p>
                <p className="text-gray-500 text-sm mt-1">Batches</p>
            </div>
            <div>
                <p className="text-text text-lg font-bold">{Enrolled}</p>
                <p className="text-gray-500 text-sm mt-1">Enrolled</p>
            </div>
            <div>
                <p className="text-text text-lg font-bold">{Ratings}</p>
                <p className="text-gray-500 text-sm mt-1">Ratings</p>
            </div>
        </div>
        <Menu as="div" className="relative inline-block text-left mt-4">
          <div>
            <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
              <EllipsisVerticalIcon aria-hidden="true" className="size-6" />
            </MenuButton>
          </div>
          <MenuItems
            transition
            className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
            <div className="py-1">
              <MenuItem>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                  + New Batch
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                  Archive
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                  Edit
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                  Delete
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
        
    </div>
);

const Tab1Content = () => (
    <div className='w-full'>
      {courseData.map((course, index) => (
        <Card key={index} title={course.title} description={course.description} Batches={course.Batches} Enrolled={course.Enrolled} Ratings={course.Ratings} />
      ))}
    </div>
  );
const Tab2Content = () => <div></div>;
const Tab3Content = () => <div></div>;

const items = [
    {
      key: '1',
      label: 'Active Courses(7)',
      children: <Tab1Content />, // Render mapped JSON data in Tab 1
    },
    {
      key: '2',
      label: 'Draft',
      children: <Tab2Content />, // Blank
    },
    {
      key: '3',
      label: '...',
      children: <Tab3Content />, // Blank
    },
  ];


export default function ActiveCourse() {
    return (
        <>
        <div className="relative w-full py-4">
    {/* Tabs Component */}
    <Tabs
      defaultActiveKey="1"
      items={items}
      tabBarStyle={{
        fontWeight: 'bold',
        color: 'black',
      }}
      className="text-black font-medium p-4"
    />

    {/* Sort Button */}
    <div
      className="absolute top-4 right-4 flex flex-row justify-center items-start border-2 border-gray-300 rounded-full h-10 w-24"
    >
      <svg
        className="mt-3"
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.81945 12.1001V10.241H10.5377V12.1001H6.81945ZM3.10119 7.45226V5.59313H14.256V7.45226H3.10119ZM0.3125 2.80444V0.945312H17.0447V2.80444H0.3125Z"
          fill="#5F6368"
        />
      </svg>
      <p className="font-medium text-sm ml-2 py-2">Sort</p>
    </div>
  </div>
        </>
    );
}