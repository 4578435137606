import { useSelector } from "react-redux";
import { redirect, useLocation } from "react-router-dom";

export const isAuthenticated = async () => {
  const token = sessionStorage.getItem("simplitrain_token");
  if (token) throw redirect("/");
  return null;
};

export const handleProtected = async () => {
    const token = sessionStorage.getItem("simplitrain_token");
    if (token) throw redirect("/signin");
    return null;
};

export const checkIsMobile = () =>{
  const width = window.innerWidth;
  if(width < 768){
    return true;
  }else{
    return false
  }
}


export const sum = (a, b) => a + b;



