
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    PROFILE_BEGIN:'PROFILE_BEGIN',
    PROFILE_ERR:'PROFILE_ERR',
    PROFILE_SUCCESS:'PROFILE_SUCCESS'
}

export default actiontyps;