
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    GOALS_BEGIN:'GOALS_BEGIN',
    GOALS_ERR:'GOALS_ERR',
    GOALS_SUCCESS:'GOALS_SUCCESS'
}

export default actiontyps;