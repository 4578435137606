import React from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Container } from "../../../components/ui-components";
import Language from "./Language";
import TimeZone from "./TimeZone";
import LearningMode from "./LearningMode";
import SettingNotification from "./SettingNotification";
import Reminder from "./Reminder";
import Payment from "./Payment";
import Password from "./Password";
import Security from "./Security";
import DeleteAC from "./DeleteAC";
import {checkIsMobile} from "../../../utils/helpers";
import reviewLeftArrow from "../../../assets/svgs/reviewLeftArrow.svg"
// import DashboardLayout from '../dashboard';

export async function settingLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function Setting() {
  // const data = useLoaderData();
  const isMobile = checkIsMobile();
  const navigate = useNavigate();
  return (
    <section className='py-6 w-full'>
        <Container>
          {
            isMobile && (
              <button onClick={()=>navigate("/dashboard")} className="pb-5">
                <img src={reviewLeftArrow} alt="" className="py-2 px-2 bg-gray-100 rounded-full" />
              </button>
            )
          }
            <h1 className='text-3xl md:text-2xl font-semibold text-secondary'>Settings</h1>
            <div className='w-full max-w-xl flex flex-col  md:gap-4'>
                <Language />
                <TimeZone />
                <LearningMode />
                <SettingNotification />
                <Reminder />
                <Payment />
                <Password />
                <DeleteAC />
                <Security />
            </div>
        </Container>
    </section>
       
  )
}

export default Setting;
