import React from "react";
import { useState } from "react";
import groupCircles from "../../../assets/svgs/groupCircles.svg";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { checkIsMobile } from "../../../utils/helpers";

const days = [
  { date: "2021-12-27" },
  { date: "2021-12-28" },
  { date: "2021-12-29" },
  { date: "2021-12-30" },
  { date: "2021-12-31" },
  { date: "2022-01-01", isCurrentMonth: true },
  { date: "2022-01-02", isCurrentMonth: true },
  { date: "2022-01-03", isCurrentMonth: true },
  { date: "2022-01-04", isCurrentMonth: true },
  { date: "2022-01-05", isCurrentMonth: true },
  { date: "2022-01-06", isCurrentMonth: true },
  { date: "2022-01-07", isCurrentMonth: true },
  { date: "2022-01-08", isCurrentMonth: true },
  { date: "2022-01-09", isCurrentMonth: true },
  { date: "2022-01-10", isCurrentMonth: true },
  { date: "2022-01-11", isCurrentMonth: true },
  { date: "2022-01-12", isCurrentMonth: true },
  { date: "2022-01-13", isCurrentMonth: true },
  { date: "2022-01-14", isCurrentMonth: true },
  { date: "2022-01-15", isCurrentMonth: true },
  { date: "2022-01-16", isCurrentMonth: true },
  { date: "2022-01-17", isCurrentMonth: true },
  { date: "2022-01-18", isCurrentMonth: true },
  { date: "2022-01-19", isCurrentMonth: true },
  { date: "2022-01-20", isCurrentMonth: true, isToday: true },
  { date: "2022-01-21", isCurrentMonth: true },
  { date: "2022-01-22", isCurrentMonth: true, isSelected: true },
  { date: "2022-01-23", isCurrentMonth: true },
  { date: "2022-01-24", isCurrentMonth: true },
  { date: "2022-01-25", isCurrentMonth: true },
  { date: "2022-01-26", isCurrentMonth: true },
  { date: "2022-01-27", isCurrentMonth: true },
  { date: "2022-01-28", isCurrentMonth: true },
  { date: "2022-01-29", isCurrentMonth: true },
  { date: "2022-01-30", isCurrentMonth: true },
  { date: "2022-01-31", isCurrentMonth: true },
  { date: "2022-02-01" },
  { date: "2022-02-02" },
  { date: "2022-02-03" },
  { date: "2022-02-04" },
  { date: "2022-02-05" },
  { date: "2022-02-06" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const courseData = [
  {
    title: "How to becoming UX designer",
    timing: "Today 10am - 11am",
    time: "60min",
    class: "Class 2",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "42 Enrolled",
    classMode: "Online",
  },
  {
    title: "How to becoming UX designer",
    timing: "Today 8am - 10am",
    time: "2 hour",
    // class: "Class 5",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "501 Enrolled",
    classMode: "Offline",
  },
];

const courseTomorrowData = [
  {
    title: "How to becoming UX designer",
    timing: "Today 10am - 11am",
    time: "60min",
    class: "Class 2",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "42 Enrolled",
    classMode: "Offline",
  },
];

const restAllData = [
  {
    title: "How to becoming UX designer",
    timing: "Today 10am - 11am",
    time: "60min",
    class: "Class 2",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "42 Enrolled",
    classMode: "Offline",
  },
];

function DailySchedule() {
  const navigate = useNavigate();
  const [onlineClass, setOnlineClass] = useState(true);
  const isMobile = checkIsMobile();

  const openCourseDetail = (obj) => {
    navigate("/dashboard/courses/courseid");
  };

  return (
    <div className="flex flex-row gap-6 w-full md:pl-12 mt-4 mb-8 h-screen">
      <div className="w-full md:w-[70%] flex flex-col gap-2 overflow-y-auto border-r border-gray-100 md:pr-4">
        {/* today data */}
        <h1 className="text-sm text-secondary font-medium">Today</h1>
        {courseData.map((item, index) => (
          <div
            key={index}
            onClick={() => openCourseDetail(item)}
            className="w-full cursor-pointer relative flex flex-row gap-2 border shadow-lg p-3 md:p-6 rounded-xl">
            <div className="bg-gray-100 w-28 h-28 rounded-2xl"></div>
            <div className="w-full flex flex-col gap-2 ">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row flex-wrap gap-1 md:gap-2">
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.timing}
                  </p>
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.time}
                  </p>
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.class}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="leading-tight md:leading-none text-base md:text-xl text-secondary font-medium">
                    {item.title}
                  </h1>
                  <p className="text-xs md:text-sm text-primary">
                    {item.classType}
                  </p>
                </div>
                <div className="flex flex-row gap-1 md:gap-4">
                  <div className="flex flex-row gap-1 md:gap-2">
                    <div className="w-5 h-5 bg-gray-100 rounded-full"></div>
                    <p className="text-xs text-primary">{item.name}</p>
                  </div>
                  <div className="flex flex-row gap-1 md:gap-2">
                    <img src={groupCircles} alt="" />
                    <p className="text-xs text-primary">{item.students}</p>
                  </div>
                </div>
              </div>
              {item.classMode === "Online" ? (
                <button className="w-fit md:absolute bottom-6 right-6 text-sm bg-black text-white px-5 py-2 md:py-1 rounded-2xl md:h-10 whitespace-nowrap">
                  Join Class
                </button>
              ) : null}
            </div>
          </div>
        ))}

        {/* tomorrow data */}
        <h1 className="text-sm text-secondary font-medium mt-8">Tomorrow</h1>
        {courseTomorrowData.map((item, index) => (
          <div
            key={index}
            onClick={() => openCourseDetail(item)}
            className="w-full cursor-pointer relative flex flex-row gap-2 border shadow-lg p-3 md:p-6 rounded-xl">
            <div className="bg-gray-100 w-28 h-28 rounded-2xl"></div>
            <div className="w-full flex flex-col gap-2 ">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row flex-wrap gap-1 md:gap-2">
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.timing}
                  </p>
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.time}
                  </p>
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.class}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="leading-tight md:leading-none text-base md:text-xl text-secondary font-medium">
                    {item.title}
                  </h1>
                  <p className="text-xs md:text-sm text-primary">
                    {item.classType}
                  </p>
                </div>
                <div className="flex flex-row gap-1 md:gap-4">
                  <div className="flex flex-row gap-1 md:gap-2">
                    <div className="w-5 h-5 bg-gray-100 rounded-full"></div>
                    <p className="text-xs text-primary">{item.name}</p>
                  </div>
                  <div className="flex flex-row gap-1 md:gap-2">
                    <img src={groupCircles} alt="" />
                    <p className="text-xs text-primary">{item.students}</p>
                  </div>
                </div>
              </div>
              {item.classMode === "Online" ? (
                <button className="w-fit md:absolute bottom-6 right-6 text-sm bg-black text-white px-5 py-2 md:py-1 rounded-2xl md:h-10 whitespace-nowrap">
                  Join Class
                </button>
              ) : null}
            </div>
          </div>
        ))}

        {/* Rest of all days */}
        <h1 className="text-sm text-secondary font-medium mt-8">
          Sunday, Aug 08
        </h1>
        {restAllData.map((item, index) => (
          <div
            key={index}
            onClick={() => openCourseDetail(item)}
            className="w-full cursor-pointer relative flex flex-row gap-2 border shadow-lg p-3 md:p-6 rounded-xl">
            <div className="bg-gray-100 w-28 h-28 rounded-2xl"></div>
            <div className="w-full flex flex-col gap-2 ">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row flex-wrap gap-1 md:gap-2">
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.timing}
                  </p>
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.time}
                  </p>
                  <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                    {item.class}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="leading-tight md:leading-none text-base md:text-xl text-secondary font-medium">
                    {item.title}
                  </h1>
                  <p className="text-xs md:text-sm text-primary">
                    {item.classType}
                  </p>
                </div>
                <div className="flex flex-row gap-1 md:gap-4">
                  <div className="flex flex-row gap-1 md:gap-2">
                    <div className="w-5 h-5 bg-gray-100 rounded-full"></div>
                    <p className="text-xs text-primary">{item.name}</p>
                  </div>
                  <div className="flex flex-row gap-1 md:gap-2">
                    <img src={groupCircles} alt="" />
                    <p className="text-xs text-primary">{item.students}</p>
                  </div>
                </div>
              </div>
              {item.classMode === "Online" ? (
                <button className="w-fit md:absolute bottom-6 right-6 text-sm bg-black text-white px-5 py-2 md:py-1 rounded-2xl md:h-10 whitespace-nowrap">
                  Join Class
                </button>
              ) : null}
            </div>
          </div>
        ))}
      </div>

      {/* calendar */}
      {!isMobile && (
        <div className="hidden max-w-md w-[30%]  flex-none  px-6 py-10 md:block">
          <div className="flex items-center text-center text-gray-900">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="size-5" aria-hidden="true" />
            </button>
            <div className="flex-auto text-sm font-semibold">January 2022</div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="size-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-center text-xs/6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {days.map((day, dayIdx) => (
              <button
                key={day.date}
                type="button"
                className={classNames(
                  "py-1.5 hover:bg-gray-100 focus:z-10",
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-900",
                  !day.isSelected &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-400",
                  day.isToday && !day.isSelected && "text-indigo-600",
                  dayIdx === 0 && "rounded-tl-lg",
                  dayIdx === 6 && "rounded-tr-lg",
                  dayIdx === days.length - 7 && "rounded-bl-lg",
                  dayIdx === days.length - 1 && "rounded-br-lg"
                )}>
                <time
                  dateTime={day.date}
                  className={classNames(
                    "mx-auto flex size-7 items-center justify-center rounded-full",
                    day.isSelected && day.isToday && "bg-indigo-600",
                    day.isSelected && !day.isToday && "bg-gray-900"
                  )}>
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DailySchedule;
