import React, { useEffect, useRef, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import Sortable from "sortablejs";

export const CardTile = ({ children }) => (
  <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
    <div className="bg-[#F0F0F0] w-full">{children}</div>
  </div>
);

const SortableList = ({ chapters, setChapters }) => {
    
    const [activeSection, setActiveSection] = useState(false);
    const chaptersRef = useRef(null);
    const topicsRefs = useRef({}); // Refs for each chapter's topic list

    useEffect(() => {
      // Enable dragging for chapters
      if (chaptersRef.current) {
        const sortable = new Sortable(chaptersRef.current, {
          animation: 150,
          handle: ".chapter-drag-handle",
          onEnd: (evt) => {
            const newOrder = [...chapters];
            const [movedItem] = newOrder.splice(evt.oldIndex, 1);
            newOrder.splice(evt.newIndex, 0, movedItem);
            setChapters(newOrder);
          },
        });
  
        return () => {
          sortable.destroy();
        };
      }
    }, [chapters, setChapters]);
  
    useEffect(() => {
        // Enable dragging for topics in each chapter
        const sortableInstances = Object.keys(topicsRefs.current).map((chapterId) => {
          const chapterIndex = chapters.findIndex(
            (chapter) => chapter.id.toString() === chapterId
          );
      
          if (topicsRefs.current[chapterId] && chapterIndex >= 0) {
            return new Sortable(topicsRefs.current[chapterId], {
              animation: 150,
              handle: ".topic-drag-handle",
              onEnd: (evt) => {
                const chapter = chapters[chapterIndex];
                const newTopics = [...chapter.topics];
      
                // Correctly reorder topics
                const [movedTopic] = newTopics.splice(evt.oldIndex, 1);
                if (!movedTopic) {
                    console.error("Invalid topic move operation", { evt, newTopics });
                    return;
                  }
                newTopics.splice(evt.newIndex, 0, movedTopic);
      
                // Update the specific chapter's topics
                const updatedChapters = [...chapters];
                updatedChapters[chapterIndex] = {
                  ...chapter,
                  topics: newTopics,
                };
      
                setChapters(updatedChapters);
              },
            });
          }
      
          return null;
        });
      
        return () => {
          // Destroy all Sortable instances
          sortableInstances.forEach((instance) => {
            if (instance) instance.destroy();
          });
        };
      }, [chapters, setChapters]);
      
  
    const toggleTopicsVisibility = (chapterId) => {
      setChapters((prevChapters) =>
        prevChapters.map((chapter) =>
          chapter.id === chapterId
            ? { ...chapter, collapsed: !chapter.collapsed }
            : chapter
        )
      );
    };
  
    const handleAddTopic = (chapterId) => {
      setChapters((prevChapters) =>
        prevChapters.map((chapter) =>
          chapter.id === chapterId
            ? {
                ...chapter,
                topics: [
                  ...chapter.topics,
                  { id: Date.now(), title: "New Topic" },
                ],
              }
            : chapter
        )
      );
    };
  
    const handleTopicTitleChange = (chapterId, topicId, newTitle) => {
      setChapters((prevChapters) =>
        prevChapters.map((chapter) =>
          chapter.id === chapterId
            ? {
                ...chapter,
                topics: chapter.topics.map((topic) =>
                  topic.id === topicId ? { ...topic, title: newTitle } : topic
                ),
              }
            : chapter
        )
      );
    };

    const handleAddChapter = () => {
      setChapters((prevChapters) => [
        ...prevChapters,
        {
          id: Date.now(),
          title: "Untitled Chapter",
          topics: [],
          collapsed: false,
        },
      ]);
    };

    return (
      <>
        <ul ref={chaptersRef} className="w-full flex flex-col">
          {chapters.map((chapter) => (
            <li
              key={chapter.id}
              className="w-full flex flex-row justify-between items-center mb-4 chapter-drag-handle cursor-grab "
            >

                <svg
                  className="w-5 h-5 text-gray-400 shrink-0 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="9" cy="12" r="1"></circle>
                  <circle cx="9" cy="5" r="1"></circle>
                  <circle cx="9" cy="19" r="1"></circle>
                  <circle cx="15" cy="12" r="1"></circle>
                  <circle cx="15" cy="5" r="1"></circle>
                  <circle cx="15" cy="19" r="1"></circle>
                </svg>
                <div className="w-full flex flex-col justify-center items-center mb-4 bg-white border pb-4 pt-8 px-4 border-gray-200 rounded-[24px] ">
                    <p className="w-full text-left text-sm text-gray-400 p-2"> CHAPTER 1</p>
            <div className="bg-[#F1F1F1] w-full h-max rounded-[24px]">

              <div className="w-full flex flex-row justify-between items-center p-4 chapter-drag-handle cursor-grab ">
                <div className="w-full flex flex-col justify-center items-center p-2">

                <input
                  type="text"
                  value={chapter.title}
                  onChange={(e) =>
                    setChapters((prevChapters) =>
                      prevChapters.map((ch) =>
                        ch.id === chapter.id
                          ? { ...ch, title: e.target.value }
                          : ch
                      )
                    )
                  }
                  className="w-full text-2xl font-semibold bg-transparent border-none focus:outline-none"
                  placeholder="Untitled Chapter"
                />
                <input
                  type="text"
                  value={chapter.description}
                  onChange={(e) =>
                    setChapters((prevChapters) =>
                      prevChapters.map((ch) =>
                        ch.id === chapter.id
                          ? { ...ch, description: e.target.value }
                          : ch
                      )
                    )
                  }
                  className="w-full text-md font-semibold bg-transparent border-none focus:outline-none"
                  placeholder="Description"
                />
                </div>
                <button
                  onClick={() => toggleTopicsVisibility(chapter.id)}
                  className="w-[20%] ml-4 text-sm text-gray-500"
                >
                    <div className="flex flex-row justify-between items-center">
                    <p>2 Topics . 30 min </p>
                    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.53516 1.5L7.71583 7.5L13.8965 1.5" stroke="#2A3140" stroke-opacity="0.7" stroke-width="2"/>
                    </svg>
                    </div>

                
                </button>
              </div>
              {!chapter.collapsed && (
                <ul
                  ref={(el) => (topicsRefs.current[chapter.id] = el)}
                  className="w-full flex flex-col justify-center items-center p-4"
                >
                <span className="w-[95%] flex flex-row justify-between items-center p-2">
                    <p className="text-gray-400 text-sm p-1">TOPIC</p>
                    <p className="text-gray-400 text-sm p-1 pr-6">DURATION</p>
                </span>
                  {chapter.topics.map((topic) => (
                    <li
                      key={topic.id}
                      className="w-full flex items-center bg-gray-50 p-2 mb-2 rounded-full border border-gray-200 topic-drag-handle cursor-grab"
                    >
                      <svg
                        className="w-5 h-5 text-gray-400 shrink-0 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="9" cy="12" r="1"></circle>
                        <circle cx="9" cy="5" r="1"></circle>
                        <circle cx="9" cy="19" r="1"></circle>
                        <circle cx="15" cy="12" r="1"></circle>
                        <circle cx="15" cy="5" r="1"></circle>
                        <circle cx="15" cy="19" r="1"></circle>
                      </svg>
                      <input
                        type="text"
                        value={topic.title}
                        onChange={(e) =>
                          handleTopicTitleChange(
                            chapter.id,
                            topic.id,
                            e.target.value
                          )
                        }
                        className="w-full bg-transparent border-none focus:outline-none"
                        placeholder="Topic Title"
                      />
                    <Menu as="div" className="w-[15%] relative inline-block text-left mt-2">
                        <div>
                            <MenuButton className="flex flex-row justify-between items-center rounded-full text-primary hover:text-gray-600 ">
                                <p className="mr-2 mb-1">30 min</p>
                                <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.53516 1.5L7.71583 7.5L13.8965 1.5" stroke="#2A3140" stroke-opacity="0.7" stroke-width="2"/>
                                </svg>
                            </MenuButton>
                        </div>
                        <MenuItems
                            transition
                            className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <div className="py-1">
                            <MenuItem>
                                <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                >
                                30 min
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                >
                                45 min
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                >
                                60 min
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                >
                                Custom
                                </a>
                            </MenuItem>
                            </div>
                        </MenuItems>
                    </Menu>
                    </li>
                  ))}
                  <button
                    onClick={() => handleAddTopic(chapter.id)}
                    className="flex justify-start text-gray-400 text-sm mt-2"
                  >
                    <p>+ Add New Topic</p>
                  </button>
                
                {activeSection === true && (
                    <div className="w-full flex flex-col justify-center items-start">

                  <div className="w-full flex flex-col mt-4">
                    <label className="text-gray-400 text-sm p-1">LEARNING MATERIALS(3)</label>
                    <input
                      type="text"
                     className="w-full text-sm text-secondary font-semibold bg-transparent border-none focus:outline-none"
                      placeholder="Typography Basic in Figma"
                    />
                    <input
                      type="text"
                     className="w-full text-sm text-secondary font-semibold bg-transparent border-none focus:outline-none"
                      placeholder="Figma ShortCut cheetsheet (PDF)"
                    />
                    <input
                      type="text"
                     className="w-full text-sm text-secondary font-semibold bg-transparent border-none focus:outline-none"
                      placeholder="Intro Sheet (PDF)"
                    />
                  </div> 
                  <div className="w-full flex flex-col mt-4">
                    <label className="text-gray-400 text-sm p-1">POLL(1)</label>
                    <input
                      type="text"
                     className="w-full text-sm text-secondary font-semibold bg-transparent border-none focus:outline-none"
                      placeholder="Which Aspect of Figma Design you find most challenging?"
                    />
                  </div>
                  <div className="w-full flex flex-col mt-4">
                    <label className="text-gray-400 text-sm p-1">QUIZ(1)</label>
                    <input
                      type="text"
                     className="w-full text-sm text-secondary font-semibold bg-transparent border-none focus:outline-none"
                      placeholder="Do you understand the basic principle of Figma Designing?"
                    />
                  </div>
                  <div className="w-full flex flex-col mt-4">
                    <label className="text-gray-400 text-sm p-1">ASSIGNMENT(1)</label>
                    <input
                      type="text"
                     className="w-full text-sm text-secondary font-semibold bg-transparent border-none focus:outline-none"
                      placeholder="Programming in Java primary Assignment."
                    />
                  </div>
                    </div>
              
                )}
                <div className={`${activeSection ?'hidden' :'flex' } w-full p-4 flex-row justify-start items-center`} >
                    <button onClick={() => setActiveSection(true)} className="text-gray-400 text-center mt-4 text-[13px] w-max px-2 p-2 rounded-full">+ Add Learning Materials</button>
                    <button onClick={() => setActiveSection(true)} className="text-gray-400 text-center mt-4 text-[13px] w-max px-2 p-2 rounded-full">+ Add Assignments</button>
                    <button onClick={() => setActiveSection(true)} className="text-gray-400 text-center mt-4 text-[13px] w-max px-2 p-2 rounded-full">+ Add Quiz</button>
                    <button onClick={() => setActiveSection(true)} className="text-gray-400 text-center mt-4 text-[13px] w-max px-2 p-2 rounded-full">+ Add Poll</button>
                </div>
                </ul>
              )}
            </div>
                </div>
            </li>
          ))}
        </ul>
        <button
          onClick={handleAddChapter}
          className="text-gray-400 text-center mt-4 text-[15px] w-max border-gray-300 border-2 p-2 rounded-full"
        >
          + New Chapter
        </button>
      </>
    );
  };
  

export default function Step3() {
  const [chapters, setChapters] = useState([
    { id: 1, title: "", description:"" , topics: [{ id: 101, title: "Topic 1" }] },
  ]);

  return (
    <div className="w-full flex flex-col justify-start items-start grow overflow-y-auto px-8">
      <h1 className="font-medium text-lg text-left text-secondary mb-4">
        Curriculum
      </h1>
      <SortableList chapters={chapters} setChapters={setChapters} />
    </div>
  );
}
