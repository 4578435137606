import React from "react";
import { Container } from '../../../components/ui-components';
import MyBatches from "./MyBatches";
import BatchList from "./BatchList";



export async function batchListingLoader() {
   return {
     date: new Date().toISOString(),
   };
}

export default function BatchListing() {

   return(
       <section className="bg-gray-100 pt-24 w-full">
           <Container>
               <MyBatches />
           </Container>
           
           <Container>
               <BatchList />
           </Container>
        </section>
   )

}