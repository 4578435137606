import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import CourseCard from "../courses/courseCard";
import { Link } from "react-router-dom";
import { checkIsMobile } from "../../utils/helpers";

const TrendingCourses = () => {
  const isMobile = checkIsMobile();
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center mb-3">
        <h5 className="text-2xl md:text-3xl font-bold ">Trending Courses</h5>
        <button className="w-20 flex flex-row justify-between items-center">
          <span className="text-lg font-medium text-text">See All</span>
          <ArrowRightIcon className="size-5" />
        </button>
      </div>
      {!isMobile && (
        <div className="flex flex-row gap-5">
          <span className="text-sm font-medium bg-black border border-black py-1 px-2 text-white rounded-full">
            Artificial Intelligence
          </span>
          <span className="text-sm font-medium bg-gray-100 border border-gray-300 py-1 px-2 text-text rounded-full">
            Product Design
          </span>
          <span className="text-sm font-medium bg-gray-100 border border-gray-300 py-1 px-2 text-text rounded-full">
            UX Research
          </span>
          <span className="text-sm font-medium bg-gray-100 border border-gray-300 py-1 px-2 text-text rounded-full">
            Prototyping
          </span>
        </div>
      )}

      <div className={`md:grid md:grid-cols-3 gap-10 py-5 md:py-10 ${isMobile && "flex flex-row gap-5 overflow-x-auto scroll-smooth custom-scroll"}`}>
        {["", "", ""].map((item, index) => (
          <Link to={"/courses/courseid"} key={index + 1} className={`${isMobile && "shrink-0 w-80"}`}>
            <CourseCard type={index} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TrendingCourses;
