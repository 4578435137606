import { CheckIcon } from '@heroicons/react/20/solid';

const timeline = [
  {
    id: 1,
    content: 'STEP 1',
    target: 'Course Overview',
    href: '#',
    iconBackground: 'bg-gray-400',
  },
  {
    id: 2,
    content: 'STEP 2',
    target: 'Course Essentials',
    href: '#',
    iconBackground: 'bg-blue-500',
  },
  {
    id: 3,
    content: 'STEP 3',
    target: 'Curriculum',
    href: '#',
    iconBackground: 'bg-green-500',
  },
  {
    id: 4,
    content: 'STEP 4',
    target: 'Gallery',
    href: '#',
    iconBackground: 'bg-blue-500',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SidePanel({ current_step }) {
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  aria-hidden="true"
                  className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-300"
                />
              ) : null}
              <div className={`relative flex space-x-3 rounded-lg py-2 ${current_step == event.id ? "bg-[#D9D9D980]" : ""}`}>
                <div>
                  {/* Conditional Rendering */}
                  <span
                    className={`mt-2 ml-2 flex size-4 items-center justify-center rounded-full ${current_step == event.id ? "" : current_step > event.id ? "bg-green-500" : "bg-gray-400"}`}
                  >
                    {current_step === event.id ? (
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className='z-20'>
                      <circle cx="9.5" cy="9.5" r="9.0989" fill="#F0F0F0" stroke="#D67A18" stroke-width="0.802194"/>
                      <circle cx="9.5" cy="9.5" r="5.5" fill="#D67A18"/>
                      </svg>
                      
                    ) : current_step > event.id ? (
                      <CheckIcon className="w-4 h-4 text-white" />
                    ) : (
                      <svg width="19" height="19" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='z-20'
                      >
                        <circle cx="6.80817" cy="7.15192" r="6.01645" fill="#F0F0F0" stroke="#8B929F" strokeWidth="0.802194"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className=" text-sm text-gray-500">
                        <p className='flex flex-col justify-center items-start'>
                      {event.content}{' '}
                        </p>
                      <a href={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
