import React from "react";
import { useState } from "react";
import { EditPencilTwo } from "../../../../components/icons";
import MobDrawer from "../../../../components/ui-components/MobDrawer";
import { Button } from "../../../../components/ui-components";

function MobBio() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="bg-white rounded-xl relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">Bio</h3>
        <button
          className="border  border-gray-300 px-1 py-1 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center"
          onClick={() => setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <p className="px-6 mb-6 text-base text-gray-600">
          I&#39;m a recent graduate with a passion for data. I&#39;m eager to
          learn Full Stack Web Development techniques and build a strong
          foundation in this exciting field. I&#39;m excited to explore the
          courses offered on SimpliTrain and gain the necessary skills to
          kickstart my Developer career.
        </p>
      </div>

      <MobDrawer
        open={open}
        onClose={setOpen}
        title="Edit Bio">
        <div className="flex flex-col justify-between h-full px-5">
          <textarea
            name=""
            className="text-lg rounded-md bg-gray-100 border-none shadow-sm focus:border-indigo-500 focus:ring-indigo-500 h-44 overflow-y-auto"
            placeholder="Bio"></textarea>
          <Button
            type="submit"
            color="primary"
            variant="solid"
            className="w-full my-4 m-auto text-2xl">
            SAVE
          </Button>
        </div>
      </MobDrawer>
    </>
  );
}

export default MobBio;
