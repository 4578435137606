import React, { useState } from "react";
import rightArrow from "../../../assets/svgs/rightArrow.svg";
import { Drawer } from "../../../components/ui-components";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Button } from "../../../components/ui-components";
import { checkIsMobile } from "../../../utils/helpers";
import MobDrawer from "../../../components/ui-components/MobDrawer";

function Language() {
  const [open, setOpen] = useState(false);
  const isMobile = checkIsMobile();

  const notificationMethods = [
    { id: "english", title: "English" },
    { id: "spanish", title: "Spanish" },
    { id: "arabic", title: "Arabic" },
    { id: "tamil", title: "Tamil" },
    { id: "hindi", title: "Hindi" },
  ];

  return (
    <>
      <div
        className="w-full flex flex-row justify-between items-center md:items-start transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer mt-4 md:mt-8 border-b pb-4 md:border-none md:pb-0"
        onClick={() => setOpen(true)}>
        {isMobile ? (
          <>
            <div>
              <p className="text-lg text-primary">Language Preference</p>
              <p className="text-sm text-gray-500">English</p>
            </div>
            <div>
              <img src={rightArrow} alt="" />
            </div>
          </>
        ) : (
          <>
            <p className="text-base text-primary">Language Preference</p>
            <div className="flex flex-row justify-center gap-2">
              <p className="text-base text-primary">English</p>
              <img src={rightArrow} alt="" />
            </div>
          </>
        )}
      </div>

      {isMobile ? (
        <MobDrawer open={open} onClose={setOpen} title="Language Preference">
          <div className="flex flex-col justify-between h-full">
            <fieldset>
              <div className="relative w-full">
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id={"head-search"}
                  type={"text"}
                  placeholder="Search language"
                  // onClick={() => setOpen(true)}
                  // readOnly
                  className={
                    "bg-white border text-sm border-gray-400 rounded-4xl w-full max-w-md h-10 pl-8 text-input-text placeholder:text-input-placeholder"
                  }
                />
              </div>
              <div className="mt-6 space-y-5">
                {notificationMethods.map((notificationMethod) => (
                  <div
                    key={notificationMethod.id}
                    className="flex items-center justify-between border-b pb-2">
                    
                    <label
                      htmlFor={notificationMethod.id}
                      className="block text-lg font-normal text-gray-900">
                      {notificationMethod.title}
                    </label>
                    <input
                      id={notificationMethod.id}
                      name="notification-method"
                      type="checkbox"
                      className="text-black"
                    />
                  </div>
                ))}
              </div>
            </fieldset>
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="w-48 text-sm m-auto my-6 rounded-xl">
              ADD
            </Button>
          </div>
        </MobDrawer>
      ) : (
        <Drawer open={open} onClose={setOpen} title="Language Preference">
          <div className="flex flex-col justify-between h-full">
            <fieldset>
              <div className="relative w-full">
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute left-2 top-2.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <input
                  id={"head-search"}
                  type={"text"}
                  placeholder="Search language"
                  // onClick={() => setOpen(true)}
                  // readOnly
                  className={
                    "bg-white border text-sm border-gray-400 rounded-4xl w-full max-w-md h-10 pl-8 text-input-text placeholder:text-input-placeholder"
                  }
                />
              </div>
              <div className="mt-6 space-y-6">
                {notificationMethods.map((notificationMethod) => (
                  <div
                    key={notificationMethod.id}
                    className="flex items-center">
                    <input
                      id={notificationMethod.id}
                      name="notification-method"
                      type="radio"
                      className="relative size-4 appearance-none rounded-lg border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-lg before:bg-white checked:border-indigo-600 checked:bg-indigo-600  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                    />
                    <label
                      htmlFor={notificationMethod.id}
                      className="ml-3 block text-lg font-medium text-gray-900">
                      {notificationMethod.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="w-48 text-sm m-auto my-6 rounded-xl">
              ADD
            </Button>
          </div>
        </Drawer>
      )}
    </>
  );
}

export default Language;
