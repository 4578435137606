import React, { useState ,useEffect } from "react";
import InstructorSidebar from "../../components/shared-components/InstructorSidebar";
import { Outlet, useLocation } from "react-router-dom";



export async function instructorDashboardLoader() {
   return {
     date: new Date().toISOString(),
   };
}

export default function InstructorDashboard() {
    let location = useLocation();
    const [isProfile, setIsProfile] = useState(false);

    useEffect(() => {
    // console.log("checkHome", location);
    if (location && location.pathname == "/Instructor_courses/dashboard/batch_details" || location.pathname == "/Instructor_courses/dashboard/course_details") {
        setIsProfile(false);
    }else{
        setIsProfile(true)
    }

    
    }, []);

    return (
        <div className="bg-white flex flex-row items-start justify-start">
            <div className={`flex h-[calc(100vh-64px)] sticky top-0 ${isProfile?'w-16':'w-80'}`}>
                <InstructorSidebar isProfile={isProfile} />
            </div>
            <div className='flex w-full'>
                <Outlet />
            </div>  
        </div>
  );
}