import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import CourseCard from "../courses/courseCard";
import { Link } from "react-router-dom";

const SimilarCourses = () => {

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center md:mb-3">
                <h5 className="text-2xl md:text-3xl font-bold ">Similar Courses</h5>
                <button className="w-20 flex flex-row justify-between items-center"><span className="text-lg font-medium text-text">See All</span><ArrowRightIcon className="size-5" /></button>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-10 py-5 md:py-10'>
                {
                    ['','',''].map((item, index)=>(
                        <Link to={'/courses/courseid'} key={index+1}>
                            <CourseCard type={index} />
                        </Link>
                    ))
                }
            </div>
        </div>
    );
};

export default SimilarCourses;
