import React from "react";
import {
  Button,
  Container,
  EditableTagField,
} from "../../components/ui-components";
import bell from "../../assets/svgs/bell.svg";
import verified from "../../assets/svgs/verified.svg";
import heart from "../../assets/svgs/heart.svg";
import inBox from "../../assets/svgs/inBox.svg";
import InstructorInfo from "./InstructorInfo";
import NotifyMe from "./NotifyMe";
import Reviews from "./Reviews";
import SimilarInstructor from "./SimilarInstructor";
import NewsLetter from "./NewsLetter";
import MyCourses from "./MyCourses";
import ExpertIn from "./ExpertIn";
import TeachingLanguage from "./TeachingLanguage";
import InstructorEducation from "./InstructorEducation";
import InstructorExperience from "./InstructorExperience";

export async function InstructorProfileLoader() {
  return {
    date: new Date().toISOString(),
  };
}

function InstructorProfile() {
  return (
    <div className="w-full">
      <div className="bg-[#F0F0F0] h-[30vh] w-full top-0 left-0 z-0"></div>

      <section className="pl-10">
        <Container className="mb-8">
          <div className="flex flex-row justify-between relative">
            <div className="w-44 h-44 absolute -top-20 bg-[#E5E5E5] rounded-full"></div>
            {/* left box */}
            <InstructorInfo />

            {/* right box */}
            <NotifyMe />
          </div>
        </Container>
        <Container className="mb-8">
          <MyCourses />
        </Container>
        <Container className="mb-8">
          <InstructorEducation />
        </Container>
        <Container className="mb-8">
          <InstructorExperience />
        </Container>
      </section>

      <section className="py-10">
        <Container>
          <Reviews />
        </Container>
      </section>
      <section className="py-10">
        <Container>
          <SimilarInstructor />
        </Container>
      </section>
      <section className="bg-[#F4F4F4] py-10">
        <Container>
          <NewsLetter />
        </Container>
      </section>
    </div>
  );
}

export default InstructorProfile;
