
export default function CourseReview() {
    
    const CourseDetails = {
        courseTitle : "How to become a UX designer",
        courseDescription : "Join our comprehensive 2-week online UX Design course, designed to introduce you to the essential principles and practices of user experience design.",
        instructorName:'Rohan Joshi',
        Rating:"4.8(188)",
        level:"Beginner",
        totalHours:"2 hours 30 min",
        mode:"Online/Classroom",
        language:"English",
        aboutCourse: 'User Experience (UX) Design is a multidisciplinary field focused on the interaction between users and digital or a their they physical products. The goal of UX design is to create products that provide meaningful and relevant experiencel to a users. This involves the design of the entire process of acquiring and integrating the product, including aspect of arel branding, design, usability, and function. The goal of UX design is to create products that provide meaningful and relevant experiences to users. This invol they design of the entire process of acquiring and integrating the product, including aspect of branding, designin usability and function.",' ,
        courseInclude:["30 live Sessions" , "5 Assignment" , "Course Certificate" ,"5 Topic", "Chat with Instructor" , "5 Polls" ,"Community" , "Chat with instructor"],
        prerequisites:[
            {title:"Figma Installed" , description:"Ensure Figma is installed on your device, as it will be the primary design tool used in this course."},
            {title:"Computer" , description:"Ensure Figma is installed on your device, as it will be the primary design tool used in this course."},
            {title:"Reliable Internet Access" , description:"Ensure Figma is installed on your device, as it will be the primary design tool used in this course."},
            {title:"Photoshop Installed" , description:"Ensure Figma is installed on your device, as it will be the primary design tool used in this course."},
        ],
        courseFor:["Aspiring UX Designer", "Product Manager and Developer", "Individuals looking to start a career in UX design"],
        whatWillYouLearn:[
            {title:"User Research" , description:"Create products that provide meaningful and relevant experiences to users. This invol the design of the a entire process of acquiring and integrating the product."},
            {title:"Information Architecture" , description:"Ensure Figma is installed on your device, as it will be the primary design tool used in this course."},
            {title:"Wireframing" , description:"Ensure Figma is installed on your device, as it will be the primary design tool used in this course."},
        ],
        curriculum:{
            milestones:[{milestone : "MILESTONE 1" , chapters : [{ ch:'1' , title:"What is UI/UX & Benefits" , duration:"2Topics . 30min"}, { ch:'2' , title:"UX Design Thinking Process" , duration:"2Topics . 30min"}, { ch:'3' , title:"Heuristic Evaluation in UX" , duration:"2Topics . 30min"}]} , 
                        {milestone : "MILESTONE 2" , chapters : [{ ch:'4' , title:"User Research *& Documentation" , duration:"2Topics . 30min"}, { ch:'5' , title:"User Persona & Empathy" , duration:"2Topics . 30min"}, { ch:'6' , title:"Information Architecture" , duration:"2Topics . 30min"}]} ,
                        {milestone : "MILESTONE 3" , chapters : [{ ch:'7' , title:"Brading " , duration:"2Topics . 30min"}, { ch:'8' , title:"Color Theory" , duration:"2Topics . 30min"}, { ch:'9' , title:"Typography" , duration:"2Topics . 30min"}]}
                    ]} }

    return (
        <div className="w-full h-full flex flex-col justify-start items-center grow overflow-y-auto px-8">
           <h1 className="font-semibold text-md text-primary">Final Review</h1>
           <p className="w-[30%] text-center font-medium text-sm text-gray-400 py-2 mb-10">Review your course details and Confirm everything is ready to submit</p>
           <div className="w-[70%] bg-white rounded-[30px] flex flex-col justify-start items-start">

{/* Section 1 */}

            <section id="Introduction" className="bg-[#F0F0F099] p-8 px-12">
              <h1 className="font-semibold text-md text-2xl text-secondary my-4">{CourseDetails.courseTitle}</h1>
              <p className="w-[70%] font-medium text-sm text-gray-400">{CourseDetails.courseDescription}</p>

              <div className="py-8 flex flex-row items-center justify-start">
                <span className="bg-[#D9D9D9B2] w-16 h-16 rounded-full"></span>
                <div className="flex-col flex justify-start px-2 items-start">
                    <div className="flex flex-row justify-start items-center">
                        <p className="font-medium text-md mb-2 text-primary">{CourseDetails.instructorName}</p>
                        <svg className="mx-2 mb-1" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.47596 10.0849L3.31961 11.971C3.17847 12.048 3.04054 12.0801 2.90582 12.0672C2.7711 12.0544 2.65241 12.0095 2.54977 11.9325C2.44712 11.8555 2.37014 11.7497 2.31882 11.615C2.26749 11.4802 2.26108 11.3423 2.29957 11.2012L3.12715 7.67916L0.336478 5.3119C0.221002 5.20925 0.150433 5.09057 0.124772 4.95585C0.0991105 4.82113 0.105526 4.68961 0.144018 4.56131C0.18251 4.433 0.253079 4.32715 0.355724 4.24375C0.458369 4.16035 0.586676 4.11223 0.740644 4.0994L4.39739 3.77222L5.84084 0.423412C5.90499 0.282275 5.99481 0.176422 6.11028 0.105853C6.22576 0.0352844 6.34765 0 6.47596 0C6.60426 0 6.72615 0.0352844 6.84163 0.105853C6.95711 0.176422 7.04692 0.282275 7.11107 0.423412L8.55452 3.79146L12.2113 4.0994C12.3652 4.11223 12.4935 4.16355 12.5962 4.25337C12.6988 4.34318 12.7694 4.45224 12.8079 4.58055C12.8464 4.70886 12.8496 4.83716 12.8175 4.96547C12.7854 5.09378 12.7117 5.20925 12.5962 5.3119L9.82476 7.67916L10.6523 11.2012C10.6908 11.3423 10.6844 11.4802 10.6331 11.615C10.5818 11.7497 10.5048 11.8555 10.4021 11.9325C10.2995 12.0095 10.1808 12.0544 10.0461 12.0672C9.91137 12.0801 9.77344 12.048 9.6323 11.971L6.47596 10.0849Z" fill="#5F6368"/>
                        </svg>
                        <p className="font-medium text-md mb-2 text-primary">{CourseDetails.Rating}</p>
                    </div>
                    <p className="font-medium text-sm text-gray-400">Product Designer, at HCL Pvt.Ltd</p>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-2 py-4">
                <div className="flex flex-row items-start justify-start">
                  <span className="bg-[#D9D9D9B2] w-12 h-12 rounded-lg"></span>
                  <div className="flex flex-col justify-start items-start pl-2">
                    <p className="font-medium text-md mb-1 text-primary">Level</p>
                    <p className="font-medium text-sm text-gray-400">{CourseDetails.level}</p>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start">
                  <span className="bg-[#D9D9D9B2] w-12 h-12 rounded-lg"></span>
                  <div className="flex flex-col justify-start items-start pl-2">
                    <p className="font-medium text-md mb-1 text-primary">Total Hours</p>
                    <p className="font-medium text-sm text-gray-400">{CourseDetails.totalHours}</p>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start">
                  <span className="bg-[#D9D9D9B2] w-12 h-12 rounded-lg"></span>
                  <div className="flex flex-col justify-start items-start pl-2">
                    <p className="font-medium text-md mb-1 text-primary">Mode</p>
                    <p className="font-medium text-sm text-gray-400">{CourseDetails.mode}</p>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start">
                  <span className="bg-[#D9D9D9B2] w-12 h-12 rounded-lg"></span>
                  <div className="flex flex-col justify-start items-start pl-2">
                    <p className="font-medium text-md mb-1 text-primary">language</p>
                    <p className="font-medium text-sm text-gray-400">{CourseDetails.language}</p>
                  </div>
                </div>
              </div>
            </section>

            <section id="AboutCourse" className="pt-8 px-12">
                <h1 className="font-semibold text-2xl mb-2 text-primary">About Course</h1>
                <p className="pt-4 text-sm text-left text-secondary">{CourseDetails.aboutCourse}</p>
            </section>

            <section id="CourseInclude" className="w-full py-8 px-12 flex flex-col justify-center items-center">
                <h1 className="w-full text-left font-semibold text-lg mb-8 text-secondary">Course Includes</h1>
                <div className="w-full grid grid-cols-3 gap-x-4 gap-y-6">
                {CourseDetails.courseInclude.map((item) => (
                        <div className="flex flex-row justify-start items-center">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 10.5C2 9.59347 2 8.53946 2 7.49988C2 4.73846 4.23858 2.5 7 2.5L17 2.5C19.7614 2.5 22 4.73858 22 7.5L22 17.5C22 20.2614 19.7614 22.5 17 22.5L7 22.5C4.23858 22.5 2 20.2614 2 17.5L2 14.5" stroke="#1B1B28" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M9.5 13L11.5 15L15.5 11" stroke="#1B1B28" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p className="ml-3 ">{item}</p>
                        </div>
                ))}
                </div>
            </section>

            <section id="Prerequisite" className="w-full py-8 px-12 flex flex-col justify-center items-center">
                <h1 className="w-full text-left font-semibold text-lg mb-3 text-secondary">Prerequisites (What You’ll Need Before Starting)</h1>
                {CourseDetails.prerequisites.map((item, index) => (
                    <div className="flex flex-row justify-start items-center">
                        <div key={index} className="flex flex-col justify-center items-start py-2">
                        <p className="ml-3 font-semibold">{`${index + 1}. ${item.title}`}</p>
                        <p className="ml-6">{item.description}</p>
                        </div>
                        </div>
                ))}
            </section>

            <section id="WhoThisCourseFor" className="w-full py-8 px-12 flex flex-col justify-center items-start">
                <h1 className="w-full text-left font-semibold text-lg mb-3 text-secondary">Whom is this Course for?</h1>
                {CourseDetails.courseFor.map((item, index) => (
                    <div key={index} className="flex flex-col justify-start items-start">
                        <div key={index} className="flex flex-row justify-start items-center py-2">
                        <span className="bg-[#D9D9D9B2] w-4 h-4"></span>
                        <p className="ml-6">{item}</p>
                        </div>
                    </div>
                ))}
            </section>

            <section id="Learnings" className="w-full py-8 px-12 flex flex-col justify-center items-center">
                <h1 className="w-full text-left font-semibold text-2xl mb-3 text-secondary">What You’ll Learn</h1>
                {CourseDetails.whatWillYouLearn.map((item, index) => (
                    <div className="flex flex-row justify-start items-center w-full py-4">
                        <span className="bg-[#ececec] w-12 h-12 rounded-lg"></span>
                        <div key={index} className="w-[80%] flex flex-col justify-center items-start pl-6 py-2">
                        <p className="text-md font-semibold mb-2">{`${item.title}`}</p>
                        <p className="text-sm">{item.description}</p>
                        </div>
                        </div>
                ))}
                <p className="underline text-sm text-left w-full">See more(2)</p>
            </section>

            <section id="Curriculum" className="w-full py-8 px-12 flex flex-col justify-start items-start">
                <h1 className="w-full text-left font-semibold text-2xl mb-3 text-secondary">Curriculum</h1>
                {CourseDetails.curriculum.milestones.map((item, index) => (
                    <div key={index} className="w-full flex flex-col justify-center items-start py-2">
                        <div className="w-full flex flex-row justify-between items-center py-6">
                            <p className="w-[20%] ml-3 font-semibold ">{`${item.milestone}`}</p>
                            <span className="h-[2px] bg-gray-400 w-[95%]" />
                            <span className="h-[9px] w-[9px] rounded-full bg-gray-400" />
                        </div>
                        {item.chapters.map((chapter) => (
                         <div className="w-full flex flex-row justify-between items-center py-6">
                            <p className="w-[10%] text-center font-semibold">{`${chapter.ch}`}</p>
                            <p className="w-[70%] text-left font-semibold">{`${chapter.title}`}</p>
                            <button className="w-[25%] ml-4 text-sm text-gray-500">
                                <div className="flex flex-row justify-between items-center">
                                <p className="text-primary">{chapter.duration}</p>
                                <svg className="text-primary mr-6" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.53516 1.5L7.71583 7.5L13.8965 1.5" stroke="#2A3140" stroke-opacity="0.7" stroke-width="2"/>
                                </svg>
                                </div>
                            </button>
                        </div>
                        ))}
                    </div>
                ))}
            </section>
           </div>
        </div>
    )
}