import React from "react";
import groupCircles from "../../../assets/svgs/groupCircles.svg";
import { Progress } from "antd";
import { checkIsMobile } from "../../../utils/helpers";

const activeCoursesData = [
  {
    title: "How to becoming UX designer",
    timing: "Today 10am - 11am",
    time: "60min",
    class: "Class 2",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "42 Enrolled",
    details: "9 Chapters | From 8 Jun - 8 Aug | 48hrs 45mins",
    classMode: "Offline",
    completed: "40",
  },
  {
    title: "How to becoming UX designer",
    timing: "Today 10am - 11am",
    time: "60min",
    class: "Class 2",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "42 Enrolled",
    details: "9 Chapters | From 8 Jun - 8 Aug | 48hrs 45mins",
    classMode: "Offline",
    completed: "60",
  },
  {
    title: "How to becoming UX designer",
    timing: "Today 10am - 11am",
    time: "60min",
    class: "Class 2",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "42 Enrolled",
    details: "9 Chapters | From 8 Jun - 8 Aug | 48hrs 45mins",
    classMode: "Offline",
    completed: "50",
  },
  {
    title: "How to becoming UX designer",
    timing: "Today 10am - 11am",
    time: "60min",
    class: "Class 2",
    classType: "Online | Group Class | Chapter 3 & 4",
    name: "Rohan Joshi",
    students: "42 Enrolled",
    details: "9 Chapters | From 8 Jun - 8 Aug | 48hrs 45mins",
    classMode: "Offline",
    completed: "30",
  },
];

function ActiveCourses() {
  const isMobile = checkIsMobile();
  return (
    <div className="w-full flex flex-col gap-4 overflow-y-auto md:px-14 mt-6 h-screen">
      <h1 className="text-sm text-secondary font-medium md:mb-4">
        You have 5 active courses
      </h1>
      {activeCoursesData.map((item, index) => (
        <div className="w-full flex flex-row gap-4  relative border shadow-lg p-3 md:p-6 rounded-xl">
          <div className="bg-gray-100 w-28 h-28 rounded-2xl"></div>
          <div className="md:w-[60%] flex flex-row gap-6 items-center">
            <div className="flex flex-col gap-2 md:gap-4">
              <div className="flex flex-row flex-wrap gap-1 md:gap-2">
                <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                  {item.timing}
                </p>
                <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                  {item.time}
                </p>
                <p className=" border border-gray-300 text-xs px-3 py-1 text-primary rounded-full">
                  {item.class}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <h1 className="text-base md:text-xl text-secondary font-medium leading-tight md:leading-none">
                  {item.title}
                </h1>
                {/* <p className="text-sm text-primary">{item.classType}</p> */}
              </div>
              <div className="flex flex-col md:flex-row gap-1 md:gap-4">
                {isMobile && (
                  <p className="text-xs text-primary">{item.details}</p>
                )}
                <div className="flex flex-row gap-1">
                  <div className="flex flex-row gap-1">
                    <div className="w-5 h-5 bg-gray-100 rounded-full"></div>
                    <p className="text-xs text-primary md:mr-4">{item.name}</p>
                  </div>
                  {isMobile ? (
                    <div className="flex flex-row items-center ">
                      <img src={groupCircles} alt="" />
                      <p className="text-sm text-primary">{item.students}</p>
                    </div>
                  ) : (
                    <p className="text-xs text-primary">{item.details}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-row gap-2 items-center">
                <Progress
                  percent={item.completed}
                  type="line"
                  strokeColor="black"
                />
                <span className="text-sm text-gray-500 font-medium">
                  Completed
                </span>
              </div>
            </div>
          </div>
          {
            !isMobile && (<div className='absolute right-60 w-[2px] h-36 m-auto bg-gray-200'></div>)
          }
          
            
          {!isMobile && (
            <div className="flex flex-col gap-2 absolute bottom-6 xl:right-20 lg:right-6">
              <div className="flex flex-row items-center gap-1 ">
                <img src={groupCircles} alt="" />
                <p className="text-sm text-primary">{item.students}</p>
              </div>
              <button className="text-base text-secondary px-2">
                Course Details &#62;
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ActiveCourses;
