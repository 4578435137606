import React from "react";

function InstructorBio() {
  return (
    <div className="w-full border border-gray-200 rounded-xl my-4 relative">
      <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">Bio</h3>

      <p className="px-4 mb-6 text-lg text-gray-600">
        I&#39;m a recent graduate with a passion for data. I&#39;m eager to
        learn Full Stack Web Development techniques and build a strong
        foundation in this exciting field. I&#39;m excited to explore the
        courses offered on SimpliTrain and gain the necessary skills to
        kickstart my Developer career.
      </p>
    </div>
  );
}

export default InstructorBio;
