import { StarIcon } from '@heroicons/react/20/solid'
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";



const reviews = {
  average: 4,
  totalCount: 1624,
  counts: [
    { rating: 5, count: 1019 },
    { rating: 4, count: 162 },
    { rating: 3, count: 97 },
    { rating: 2, count: 199 },
    { rating: 1, count: 147 },
  ],
  featured: [
    {
      id: 1,
      rating: 4,
      content: `
        <p>This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.</p>
      `,
      author: 'Emily Selman',
    },
    {
        id: 1,
        rating: 4,
        content: `
          <p>This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.</p>
        `,
        author: 'Emily Selman',
      },
      {
        id: 1,
        rating: 5,
        content: `
          <p>This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.</p>
        `,
        author: 'Emily Selman',
      },

  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Review() {
  return (
    <div className="bg-white">
      <div className="mx-auto w-full px-4 py-16 flex flex-col justify-start items-start">
        <div className="w-[90%]">
          <h2 className="text-xl font-bold text-primary">Reviews</h2>

          <div className='flex flex-row justify-evenly items-center gap-x-10 w-full py-8'>
          <div className="mt-3 flex flex-col justify-center items-center">
            <p className='text-6xl font-semibold py-2 text-secondary'>4.8</p>
            <div>
              <div className="flex items-center">
                {[0, 1, 2, 3, 4].map((rating) => (
                  <StarIcon
                    key={rating}
                    aria-hidden="true"
                    className='size-5 shrink-0 text-primary'
                   />
                ))}
              </div>
              <p className="sr-only">11.6k Reviews</p>
            </div>
            <p className="ml-2 text-sm text-gray-900">11.6k Reviews</p>
          </div>

          <div className="mt-6 w-full px-2">
            <h3 className="sr-only">Review data</h3>

            <dl className="space-y-2">
              {reviews.counts.map((count) => (
                <div key={count.rating} className="flex items-center text-sm">
                  <dt className="flex flex-1 items-center">
                    <p className="w-3 font-medium text-gray-900">
                      {count.rating}
                      <span className="sr-only"> star reviews</span>
                    </p>
                    <div aria-hidden="true" className="ml-1 flex flex-1 items-center">

                      <div className="relative ml-3 flex-1">
                        <div className="h-3 rounded-full border border-gray-200 bg-gray-100" />
                        {count.count > 0 ? (
                          <div
                            style={{ width: `calc(${count.count} / ${reviews.totalCount} * 100%)` }}
                            className="absolute inset-y-0 rounded-full border border-gray-600 bg-gray-600"
                          />
                        ) : null}
                      </div>
                    </div>
                  </dt>
                  
                </div>
              ))}
            </dl>
          </div>
          </div>
          
        </div>

        <div className="mt-16 lg:col-span-7 lg:col-start-6 lg:mt-0">
          <div className='flex flex-row justify-between items-center'>
          <h3 className="text-xl font-bold py-6 text-primary">Featured reviews</h3>
          <div className='flex flex-row justify-evenly gap-x-2 items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
          </div>
          </div>

          <div className="flow-root">
            <div className="flex space-x-8 overflow-x-auto py-4">
                {reviews.featured.map((review) => (
                <div key={review.id} className="flex-shrink-0 w-[50%] min-h-72 border rounded-lg p-4 bg-white">
                    <div className="flex flex-col justify-start items-start">
                    <div className="w-full flex items-center py-6">
                        <span className="h-12 w-12 bg-gray-200 rounded-full"
                        />
                        <h4 className="text-md ml-4  w-[30%] font-bold text-gray-900">{review.author}</h4>
                        <div className='flex justify-end w-[60%]'>
                            <span className="h-12 w-12 bg-gray-200 rounded-full flex justify-center items-center">
                                <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 9L12 11V13H7V19L6 20L5 19V13H0V11L2 9V2H1V0H11V2H10V9ZM2.85 11H9.15L8 9.85V2H4V9.85L2.85 11Z" fill="#5F6368"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                        <div className="mt-1 flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                            key={rating}
                            aria-hidden="true"
                            className={classNames(
                                review.rating > rating ? 'text-primary' : 'text-gray-300',
                                'h-5 w-5 shrink-0',
                            )}
                            />
                        ))}
                        </div>
                        <p className="sr-only">{review.rating} out of 5 stars</p>
                    </div>
                    <div
                    dangerouslySetInnerHTML={{ __html: review.content }}
                    className="mt-4 space-y-6 text-base text-gray-600"
                    />
                </div>
                ))}
            </div>
            </div>

        </div>


        {/* All reviews */}

        <div className="mt-16 lg:col-span-7 lg:col-start-6 lg:mt-0">
          <div className='flex flex-row justify-between items-center'>
          <h3 className="text-xl font-bold py-6 text-primary">All reviews</h3>
          <div className='flex flex-row justify-evenly gap-x-2 items-center'>
          <button className="flex justify-center items-center text-md font-medium p-2 px-4 text-primary border-2 border-gray-300 rounded-full ">Newest</button>
          <button className="flex justify-center items-center text-md font-medium p-2 px-4 text-primary border-2 border-gray-300 rounded-full ">Highest</button>
          <button className="flex justify-center items-center text-md font-medium p-2 px-4 text-primary border-2 border-gray-300 rounded-full ">Lowest</button>
          <button className="flex justify-center items-center text-sm font-medium p-3 px-4 gap-x-2 text-primary border-2 border-gray-300  rounded-full ">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M8 18V12H10V14H18V16H10V18H8ZM0 16V14H6V16H0ZM4 12V10H0V8H4V6H6V12H4ZM8 10V8H18V10H8ZM12 6V0H14V2H18V4H14V6H12ZM0 4V2H10V4H0Z" fill="#363A49"/>
            </svg>
            FIlter
          </button>
          </div>
          </div>

          <div className="flow-root">
            <div className="flex space-x-8 overflow-x-auto py-4">
                {reviews.featured.map((review) => (
                <div key={review.id} className="flex-shrink-0 w-[50%] min-h-72 border rounded-lg p-4 bg-white">
                    <div className="flex flex-col justify-start items-start">
                    <div className="w-full flex items-center py-6">
                        <span className="h-12 w-12 bg-gray-200 rounded-full"
                        />
                        <h4 className="text-md ml-4 w-[30%] font-bold text-gray-900">{review.author}</h4>
                        <div className='flex justify-end w-[60%]'>
                        <EllipsisVerticalIcon aria-hidden="true" className="size-6" />      
                        </div>
                    </div>
                        <div className="mt-1 flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                            key={rating}
                            aria-hidden="true"
                            className={classNames(
                                review.rating > rating ? 'text-primary' : 'text-gray-300',
                                'h-5 w-5 shrink-0',
                            )}
                            />
                        ))}
                        </div>
                        <p className="sr-only">{review.rating} out of 5 stars</p>
                    </div>
                    <div
                    dangerouslySetInnerHTML={{ __html: review.content }}
                    className="mt-4 space-y-6 text-base text-gray-600"
                    />
                </div>
                ))}
            </div>
            </div>

        </div>
      </div>
    </div>
  )
}
