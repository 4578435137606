import React from "react";

const Prerequisites = () => {
  return (
    <div className="flex flex-col md:px-6">
        <h5 className="text-lg font-bold mb-5">Prerequisites (What You’ll Need Before Starting)</h5>
        <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-row gap-2 justify-start items-start">
            <div className="flex w-3"><span className="text-lg text-text font-medium">1.</span></div>
            <div className="flex flex-col w-full">
                <h5 className="text-base md:text-lg text-text font-medium">Figma Installed</h5>
                <p className="text-sm text-text">Ensure Figma is installed on your device, as it will be the primary design tool used in this course.</p>
            </div>
            </div>
            <div className="flex flex-row gap-2 justify-start items-start">
            <div className="flex w-3"><span className="text-lg text-text font-medium">2.</span></div>
            <div className="flex flex-col w-full">
                <h5 className="text-base md:text-lg text-text font-medium">Computer</h5>
                <p className="text-sm text-text">A laptop or desktop computer is required for optimal learning and design work. Mobile devices may not support all necessary functions.</p>
            </div>
            </div>
            <div className="flex flex-row gap-2 justify-start items-start">
            <div className="flex w-3"><span className="text-lg text-text font-medium">3.</span></div>
            <div className="flex flex-col w-full">
                <h5 className="text-base md:text-lg text-text font-medium">Reliable Internet Access</h5>
                <p className="text-sm text-text">A stable internet connection is essential for accessing course materials, joining live sessions, and uploading your assignments.</p>
            </div>
            </div>
        </div>
    </div>
  );
};

export default Prerequisites;
