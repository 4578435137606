import React from "react";
import { Button } from "../../../components/ui-components";
import { Input } from "antd";
import { useState } from "react";
import { checkIsMobile } from "../../../utils/helpers";

function DeletePassword() {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const isMobile = checkIsMobile();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(password);
  };

  return (
    <div className="md:max-w-[65%] h-full m-auto flex flex-col gap-4 justify-center">
      {deleteSuccess ? (
        <div className="md:max-w-[70%] m-auto flex flex-col gap-3 justify-center items-center text-center">
          <div className="bg-input-background rounded-full w-16 h-16 md:w-32 md:h-32"></div>
          <h1 className="text-3xl md:text-2xl font-medium text-secondary">
            Your Account{isMobile && <br />} has been deleted
          </h1>
          <p className="text-xs md:text-base text-primary">
            Your account has been deactivated successfully, you can reactivate
            it within 30 days. After that, your account will be permanently
            deleted.
          </p>
        </div>
      ) : (
        <>
          <h1 className="text-3xl font-medium text-secondary">
            Enter Your Password
          </h1>
          <p className="text-xs md:text-base text-primary">
            To proceed with deactivating your account, please enter your
            password below. This step is necessary to verify your identity and
            ensure the security of your account
          </p>
          <form onSubmit={handleSubmit}>
            <Input.Password
              onChange={handleChange}
              placeholder="Set Password"
              className="border-none bg-input-background rounded-xl px-4 py-4 text-primary"
            />

            <Button
              type="submit"
              color="primary"
              variant="solid"
              className="w-full m-auto my-20 rounded-xl"
                onClick={() => setDeleteSuccess(true)}
            >
              Continue
            </Button>
          </form>
        </>
      )}
    </div>
  );
}

export default DeletePassword;
