 import React from "react";
 import { Container } from '../../../components/ui-components';
 import MyCourse from "./MyCourse";
 import ActiveCourse from "./ActiveCourse";
 import InstructorSidebar from "../../../components/shared-components/InstructorSidebar";
 


 export async function courseListingLoader() {
    return {
      date: new Date().toISOString(),
    };
}

export default function CourseListing() {

    return(
        <section className="bg-gray-100 pt-24 w-full">
            <Container>
                <MyCourse />
            </Container>
            
            <Container>
                <ActiveCourse />
            </Container>
         </section>
    )

}