import { Fragment, useState, useRef } from "react";
import { useLoaderData } from "react-router-dom";
import { Button, Card, Carousel, Col, Row } from "antd";
import {
  MagnifyingGlassIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { Container, DropDownField } from "../../components/ui-components";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  MenuButton,
  MenuItem,
  MenuItems,
  Menu,
} from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import InnerHeroSection from "../../components/shared-components/InnerHeroSection";
import {
  FaqAccountProfileManagement,
  FaqInstructors,
  FaqLearners,
  FaqPaymentsBilling,
  FaqQuestions,
} from "../../components/icons";
import { checkIsMobile } from "../../utils/helpers";
import like from "../../assets/svgs/like.svg";
import notification from "../../assets/svgs/notification.svg";
import { getFaqCategories } from "../../redux/faqs/actionCreator";
import store from "../../redux/store";



const getFaqCategoriesList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getFaqCategories());
    if(responce && responce.type == 'FAQS_SUCCESS'){
      resolve(responce.data);
    }else{
      resolve([]);
    }
  })
}

export async function faqLoader() {
  const faqCategories = await getFaqCategoriesList();
  return {
    date: new Date().toISOString(),
    faqCategories:faqCategories
  };
}

const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
];

export default function FaqPage() {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  let loaderData = useLoaderData();
  const isMobile = checkIsMobile();

  // const handleBeforeChange = (from, to) => {
  //   setCurrentSlide(to);
  // };
  const onSlideChange = (iin) => {
    console.log("iin", iin);
  };

  const getIcon = (icon) => {
    switch (icon) {
      case 'ForVenueProvider':
        return <FaqPaymentsBilling color={"#00000090"} />;
        break;
      case 'ForInstructors':
        <FaqLearners color={"#00000090"} />
        break;
      case 'ForLearners':
        return <FaqLearners color={"#00000090"} />;
        break;
      case 'GeneralPlatform':
        return <FaqQuestions color={"#00000090"} />;
        break;
      case 'PaymentsBilling':
        return <FaqPaymentsBilling color={"#00000090"} />;
        break;
      case 'AccountProfile':
        return <FaqAccountProfileManagement color={"#00000090"} />;
        break;
      default:
        break;
    }
  }

  const goToPrev = () => {
    carouselRef.current.prev();
    // console.log(currentSlide);
    setCurrentSlide(currentSlide - 1);
    // console.log(currentSlide-1);
  };

  const goToNext = () => {
    carouselRef.current.next();
    // console.log(currentSlide);
    setCurrentSlide(currentSlide + 1);
    // if((currentSlide+1) >= items.length){
    //   carouselRef.current.slickGoTo(0);
    // }

    // console.log(currentSlide+1);
  };

  return (
    <>
      {/* Hero Section */}
      <section className="bg-gray-100 md:py-20">
        <Container className={`${isMobile && "py-5"}`}>
          <div className="text-center w-full md:max-w-lg m-auto">
            {isMobile && (
              <div className="pt-5 pb-10 flex flex-row justify-between">
                <div className="flex flex-row items-center gap-2">
                  {/* <Logo /> */}
                  <h1 className="text-lg font-bold text-secondary">
                    SimpliTrain
                  </h1>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-2xl bg-white px-3 py-1 text-sm font-semibold text-secondary  border">
                        Categories
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="-mr-1 size-5 text-secondary"
                        />
                      </MenuButton>
                    </div>

                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-2 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                      <div className="py-1 px-2">
                        <MenuItem>
                          <a
                            href="#"
                            className="block px-4 py-2 text-sm whitespace-nowrap text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                            Learners
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            href="#"
                            className="block px-4 py-2 text-sm whitespace-nowrap text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                            Instructors
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            href="#"
                            className="block px-4 py-2 text-sm whitespace-nowrap text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                            Venue Providers
                          </a>
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </Menu>
                </div>
                <div className="flex flex-row items-center gap-4">
                  <img src={like} alt="" />
                  <img src={notification} alt="" />
                </div>
              </div>
            )}
            <h1
              className="text-3xl md:text-5xl font-bold text-text mb-4"
              style={
                isMobile ? { lineHeight: "40px" } : { lineHeight: "60px" }
              }>
              Frequently Asked
              <br />
              Questions
            </h1>
            <div className="relative w-full">
              <input
                id={"faq-search"}
                type={"text"}
                placeholder="Ask a questions..."
                className={
                  "bg-white border-0 text-sm rounded-xl w-full h-10 pr-8 text-input-text placeholder:text-input-placeholder"
                }
              />
              <MagnifyingGlassIcon
                className="pointer-events-none absolute right-1 top-1 h-8 w-8 py-2 px-2 rounded-xl text-white bg-black"
                aria-hidden="true"
              />
            </div>
            {/* <DropDownField options={[{key:1, value:'Option1'},{key:2, value:'Option2'},{key:3, value:'Option3'}]} label="Email ID / Phone Number" name={"username"} id={"username"} placeholder="demo@gmail.com" error={''} /> */}
          </div>
        </Container>
      </section>
      <section className="py-10 overflow-x-scroll">
        <Container>
          <div className="relative w-full m-auto">
            <Carousel
              ref={carouselRef}
              // dots
              // arrows
              draggable
              // autoplay
              // autoplaySpeed={3000}
              slidesToShow={isMobile ? 2 : 5}
              slidesToScroll={1}
              style={{ height: "100%"}}
              // infinite={true}
              id={"faqs-slider"}>
              {loaderData.faqCategories.map((item, index) => (
                <div className="p-3">
                  <div className="flex flex-col justify-center items-start gap-6 p-6 h-48 bg-white shadow-lg">
                    {/* {getIcon(item.icon)} */}
                    <h5>{item.name}</h5>
                  </div>
                </div>
              ))}
            </Carousel>
            {isMobile ? (
              ""
            ) : (
              <div className="">
                {/* {currentSlide > 0 && ( */}
                <div className="absolute -left-10 top-0 w-10 h-full flex items-center">
                  <Button
                    type="primary"
                    className="bg-primary"
                    onClick={goToPrev}
                    shape="circle"
                    icon={<ChevronLeftIcon className="size-7 text-white" />}
                  />
                </div>
                {/* )} */}
                {/* {currentSlide < items.length - 1 && ( */}
                <div className="absolute -right-12 top-0 w-10 h-full flex items-center">
                  <Button
                    type="primary"
                    className="bg-primary"
                    onClick={goToNext}
                    shape="circle"
                    icon={<ChevronRightIcon className="size-7 text-white" />}
                  />
                </div>
                {/* )} */}
              </div>
            )}
          </div>
        </Container>
      </section>
      <section className="py-10">
        <Container>
          <div className="w-full flex flex-col">
            <h4 className="text-text text-xl font-medium mb-5">
              General Platform Questions
            </h4>
            <div className="mt-10 flex flex-col gap-5">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="p-3 border-b-2 border-b-gray-5 rounded-none">
                  <Disclosure key={index} as="div" className="">
                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-sm md:text-lg font-semibold">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        <PlusSmallIcon
                          aria-hidden="true"
                          className="size-6 group-data-[open]:hidden"
                        />
                        <MinusSmallIcon
                          aria-hidden="true"
                          className="size-6 group-[&:not([data-open])]:hidden"
                        />
                      </span>
                    </DisclosureButton>
                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                      <p className="text-sm text-text">{faq.answer}</p>
                    </DisclosurePanel>
                  </Disclosure>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
