import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import CourseCard from "../courses/courseCard";
import { Link } from "react-router-dom";
import { checkIsMobile } from "../../utils/helpers";

const FeaturedCourses = () => {
  const isMobile = checkIsMobile();

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center mb-6">
        <h5 className="text-3xl font-bold ">Featured Courses</h5>
        <button className="w-20 flex flex-row justify-between items-center">
          <span className="text-lg font-medium text-text">See All</span>
          <ArrowRightIcon className="size-5" />
        </button>
      </div>
      <div className="hidden md:flex flex-row gap-5">
        <span className="text-sm font-medium bg-black border border-black py-1 px-2 text-white rounded-full">
          All
        </span>
        <span className="text-sm font-medium bg-gray-100 border border-gray-300 py-1 px-2 text-text rounded-full">
          Online
        </span>
        <span className="text-sm font-medium bg-gray-100 border border-gray-300 py-1 px-2 text-text rounded-full">
          Classroom
        </span>
        <span className="text-sm font-medium bg-gray-100 border border-gray-300 py-1 px-2 text-text rounded-full">
          One on One
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 py-5 md:py-10">
        {(isMobile ? ["", ""] : ["", "", "", "", ""]).map((item, index) =>
          index == 0 ? (
            <div className="md:row-span-2">
              {/* <div className="h-44 bg-gray-200 rounded-lg"></div> */}
              <div className="relative h-60 md:h-full flex flex-col bg-gray-200 rounded-3xl justify-between items-start">
                <div className="flex justify-between items-center">
                  <span className="text-[10px] font-medium bg-gray-200 border border-gray-400 py-1 px-2 rounded-full absolute top-4 left-4">
                    Design
                  </span>
                  <span className="text-[10px] font-medium bg-gray-500 text-white py-1 px-2 rounded-full absolute top-4 right-4">
                    Popular
                  </span>
                </div>
                <div className="p-5">
                  <h2 className="mt-4 text-xl md:text-sm font-bold">
                    How to become UX designer
                  </h2>
                  {!isMobile && (
                    <>
                      <p className="mt-2 text-xs font-semibold text-gray-600">
                        Online | Classroom | One on One | 60hrs
                      </p>
                      <div className="flex flex-row items-center mt-2">
                        <div className="w-8 h-8 bg-gray-4 rounded-full"></div>
                        <div className="ml-2 flex flex-row gap-2">
                          <p className="text-sm font-medium">Rohan Joshi</p>
                          <p className="text-sm text-gray-500">
                            <span className="font-semibold">4.8</span> (200)
                          </p>
                        </div>
                      </div>
                      <div className="py-2 px-3 bg-gray-4 rounded-full mt-2">
                        <p className="text-text text-sm font-medium">
                          {"Next Batch : May 24 - Aug 24 | +2 More Batches"}
                        </p>
                      </div>
                      <p className="mt-2 text-sm font-semibold">
                        <span className="text-xs text-gray-500">
                          Starts From
                        </span>{" "}
                        ₹ 9,999/-
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <Link to={"/courses/courseid"} key={index + 1}>
                <CourseCard type={index} />
              </Link>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FeaturedCourses;
