
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    EDUCATIONS_BEGIN:'EDUCATIONS_BEGIN',
    EDUCATIONS_ERR:'EDUCATIONS_ERR',
    EDUCATIONS_SUCCESS:'EDUCATIONS_SUCCESS',

    ADD_EDUCATIONS_BEGIN:'ADD_EDUCATIONS_BEGIN',
    ADD_EDUCATIONS_ERR:'ADD_EDUCATIONS_ERR',
    ADD_EDUCATIONS_SUCCESS:'ADD_EDUCATIONS_SUCCESS'
}

export default actiontyps;