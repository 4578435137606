import React from "react";

const Icon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="#fff" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_905_5310)">
        <path d="M16.344 14.0275C16.0718 14.6563 15.7495 15.2352 15.3762 15.7674C14.8674 16.4929 14.4507 16.9952 14.1296 17.274C13.6318 17.7318 13.0985 17.9662 12.5274 17.9796C12.1174 17.9796 11.623 17.8629 11.0475 17.6263C10.47 17.3907 9.9394 17.274 9.45419 17.274C8.94532 17.274 8.39956 17.3907 7.8158 17.6263C7.23115 17.8629 6.76016 17.9862 6.40006 17.9985C5.85242 18.0218 5.30654 17.7807 4.76167 17.274C4.41391 16.9707 3.97892 16.4507 3.45783 15.7141C2.89873 14.9274 2.43908 14.0152 2.07898 12.9753C1.69333 11.852 1.5 10.7642 1.5 9.71115C1.5 8.50486 1.76066 7.46444 2.28275 6.59258C2.69307 5.89227 3.23894 5.33984 3.92214 4.9343C4.60535 4.52875 5.34354 4.32209 6.13852 4.30887C6.5735 4.30887 7.14393 4.44342 7.8528 4.70786C8.55966 4.97319 9.01354 5.10774 9.21253 5.10774C9.3613 5.10774 9.86551 4.95041 10.7203 4.63675C11.5286 4.34587 12.2108 4.22543 12.7696 4.27287C14.284 4.39509 15.4218 4.99207 16.1784 6.0676C14.824 6.88824 14.154 8.03765 14.1674 9.51216C14.1796 10.6607 14.5962 11.6164 15.4151 12.3753C15.7862 12.7275 16.2006 12.9997 16.6617 13.193C16.5617 13.483 16.4562 13.7608 16.344 14.0275ZM12.8707 0.360103C12.8707 1.26031 12.5419 2.10083 11.8863 2.8788C11.0952 3.80367 10.1384 4.33809 9.10076 4.25376C9.08753 4.14577 9.07987 4.0321 9.07987 3.91266C9.07987 3.04847 9.45608 2.1236 10.1242 1.36741C10.4577 0.984528 10.8819 0.666171 11.3963 0.412212C11.9097 0.162041 12.3952 0.0236918 12.8519 0C12.8652 0.120343 12.8707 0.240694 12.8707 0.360091V0.360103Z" />
        </g>
        <defs>
        <clipPath id="clip0_905_5310">
        <rect width="18" height="18" fill="white"/>
        </clipPath>
        </defs>
    </svg>



  );
};

export default Icon;
