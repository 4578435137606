import React, { useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
// import { Button, Divider, Form, FormProps, Input, Select, Space } from 'antd';
import { useDispatch } from 'react-redux';
// import { useForm } from "react-hook-form";
import { checkUser, checkUserType } from '../../redux/authentication/actionCreator';
import GoogleButton from '../../components/shared-components/GoogleButton';
import AppleButton from '../../components/shared-components/AppleButton';
import AuthFooter from '../../components/shared-components/AuthFooter';
import { BackArrow } from '../../components/icons';
import { Button, Divider, TextField, FloatingTextField, Spinner } from '../../components/ui-components';


export async function forgotPasswordLoader() {
  // await sleep();
  return {
    date: new Date().toISOString(),
  };
}
  
const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const { register, handleSubmit, formState: { errors } } = useForm();
  const [username, setUsername] = useState('');
  const [formData, setFormData] = useState({
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [formSubmit, setFormSubmit] = useState(false);  
  let data = useLoaderData();

  const validate = () => {
    const newErrors = {};
    // Password validation
    if (!formData.password) {
        newErrors.password = 'Please enter your password.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const onFinish = async (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setTimeout(() => {
      if (validate()) {
        console.log(formData);
        navigate('/');
        setFormSubmit(false);
      }else{
        setFormSubmit(false);
      }
    }, 2000);    
  };

  return (
    <div className='flex flex-col justify-start items-start gap-4'>
      <h3 className='text-2xl text-text text-left w-full font-medium'>{'Enter Password'}</h3>
      <p className="text-base text-text text-left w-full">Your OTP has been verified. Now, please set your password to get started on SimpliTrain.</p>
      <form  onSubmit={onFinish} method="POST" className="w-full">
        <FloatingTextField label="Password" type={'password'} name={"password"} id={"password"} placeholder="Password" error={errors.password} />
        <p className='flex flex-row items-center justify-end w-full m-0 mb-3'>
          <a href="/auth/forgot" color='text' className={'font-medium underline'}>{'Forgot Password ?'}</a>
        </p>
        <Button type='submit' color='primary' variant='solid' className={formSubmit?'w-20':'w-full'} rounded={formSubmit?true:false}>{formSubmit?<Spinner className={''} color={'white'} />:'Sign In'}</Button>
      </form>
        <p className='flex flex-row items-center justify-start w-full m-0 mb-3'>
          <a href="/auth/signin" color='text' className={'font-medium underline'}>{'Want to use another Email ID?'}</a>
        </p>
    </div>
  );
}

export default ForgotPassword;