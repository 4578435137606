import React, { useState } from "react";
import clsx from "clsx";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Rate } from "antd";
import { Slider } from "antd";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import upArrow from "../../assets/svgs/upArrow.svg";
import downArrow from "../../assets/svgs/downArrow.svg";

const marks = {
  0: "0",
  25: "250",
  50: "500",
  75: "750",

  100: {
    style: {
      color: "gray",
    },
    label: <strong>1000</strong>,
  },
};

const FilterSideBar = ({ onClose }) => {
  const [showMore, setShowMore] = useState(false);
  const [instructorShow, setInstructorShow] = useState(false);
  const [companies, setCompanies] = useState([
    "Adobe",
    "HCL",
    "BOSCH",
    "Appolo",
    "Decathlon",
    "Google",
    "Apple",
    "Facebook",
    "Samsung",
  ]);
  const [courseShow, setCourseShow] = useState(false);
  const [courseCategory, setCourseCategory] = useState([
    "All",
    "IT & Computers",
    "Health & Wellness",
    "Skill development",
    "Business",
    "Personal development",
    "Design",
    "Marketing",
    "lifestyle",
    "Health & Fitness",
    "Music",
    "Teaching & Academics",
  ]);
  const [subCategoryShow, setSubCategoryShow] = useState(false);
  const [subCategory, setSubCategory] = useState([
    "IT & Computers", "IT & SOFTWARE", "IT & HARDWARE", "IT & NETWORKING", "IT & NETWORKING", "IT & WEB",
  ])

  const displayedCompanies = instructorShow ? companies : companies.slice(0, 6);

  const displayedCourse = courseShow
    ? courseCategory
    : courseCategory.slice(0, 6);

    const displayedSubCategory = subCategoryShow ? subCategory : subCategory.slice(0, 3);

  return (
    <div className="w-full bg-white md:border-r md:border-r-gray-5">
      <div className="hidden md:flex py-4 px-5 justify-between items-center border-b border-b-gray-5 mb-4">
        <h2 className="text-sm uppercase text-gray-600 font-medium">Filter</h2>
        <button onClick={() => onClose(false)} className="w-8 h-8">
          <XMarkIcon className="size-5" />
        </button>
      </div>
      <div className="flex flex-col gap-8">
        {/* Ratings Section */}
        <div className="px-5 border-b pb-5">
          <h3 className="text-sm font-medium mb-5">Ratings</h3>
          <ul className="space-y-2">
            {Array.from({ length: 6 }, (_, i) => (
              <li key={i} className="flex items-center space-x-2">
                <input
                  type="radio"
                  name="rating"
                  id={`rating-${i}`}
                  className="w-4 h-4 text-black border-2 hover:border-black"
                />
                <Rate
                  allowHalf
                  defaultValue={5 - i}
                  className="text-black"
                  disabled
                />
                <label htmlFor={`rating-${i}`} className="text-sm">
                  {5 - i}.0 & up
                </label>
              </li>
            ))}
          </ul>
        </div>

        {/* Price Slider */}
        <div className="px-5 border-b pb-5">
          <h3 className="text-sm font-medium mb-5">Price</h3>
          {/* <input type="range" min="0" max="100" className="w-full accent-blue-500" /> */}
          <Slider
            marks={marks}
            step={null}
            defaultValue={50}
            styles={{
              track: {
                background: "transparent",
              },
              tracks: {
                background: "#585E6D",
                color: "#585E6D",
              },
            }}
          />
        </div>

        {/* Course Level */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Course Level</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {["All Levels", "Beginner", "Intermediate", "Advanced"].map(
                (level, idx) => (
                  <li key={idx} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`level-${idx}`}
                      className="w-4 h-4 text-[#363A49]"
                    />
                    <label
                      htmlFor={`level-${idx}`}
                      className="text-base text-[#363A49] font-medium">
                      {level}
                    </label>
                  </li>
                )
              )}
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Duration */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Duration of the Course</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {["Hours", "Days", "Weeks", "Months"].map((duration, idx) => (
                <li key={idx} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={`duration-${idx}`}
                    className="w-4 h-4 text-[#363A49]"
                  />
                  <label
                    htmlFor={`duration-${idx}`}
                    className="text-base text-[#363A49] font-medium">
                    {duration}
                  </label>
                </li>
              ))}
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Discount */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Discount</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {[
                "50% or more",
                "40% or more",
                "30% or more",
                "20% or more",
                "10% or more",
              ].map((duration, idx) => (
                <li key={idx} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    id={`duration-${idx}`}
                    className="w-4 h-4 text-[#363A49]"
                  />
                  <label
                    htmlFor={`duration-${idx}`}
                    className="text-base text-[#363A49] font-medium">
                    {duration}
                  </label>
                </li>
              ))}
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Instructor Type */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Instructor Type</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {["Celebrity Instructor", "Simplitrain Certified Instructor"].map(
                (duration, idx) => (
                  <li key={idx} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`duration-${idx}`}
                      className="w-4 h-4 text-[#363A49]"
                    />
                    <label
                      htmlFor={`duration-${idx}`}
                      className="text-sm text-[#363A49] font-medium ">
                      {duration}
                    </label>
                  </li>
                )
              )}
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Instructor Company */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Instructor Company</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {displayedCompanies.map((duration, idx) => (
                <li key={idx} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={`duration-${idx}`}
                    className="w-4 h-4 text-[#363A49]"
                  />
                  <label
                    htmlFor={`duration-${idx}`}
                    className="text-sm text-[#363A49] font-medium ">
                    {duration}
                  </label>
                </li>
              ))}
              <button onClick={() => setInstructorShow(!instructorShow)}>
                {instructorShow ? (
                  <div className="flex flex-row gap-3 items-center">
                    <p className="text-base text-secondary font-medium">
                      Show Less
                    </p>
                    <img src={upArrow} alt="" />
                  </div>
                ) : (
                  <div className="flex flex-row gap-3 items-center">
                    <p className="text-base text-secondary font-medium">
                      show More
                    </p>
                    <img src={downArrow} alt="" />
                  </div>
                )}
              </button>
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Language Type */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Language Type</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {["All", "Hindi", "English", "Tamil", "Kannada", "Malayalam"].map(
                (duration, idx) => (
                  <li key={idx} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`duration-${idx}`}
                      className="w-4 h-4 text-[#363A49]"
                    />
                    <label
                      htmlFor={`duration-${idx}`}
                      className="text-base text-[#363A49] font-medium">
                      {duration}
                    </label>
                  </li>
                )
              )}
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Date */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Date</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {["Today", "Tomorrow", "Custom"].map((duration, idx) => (
                <li key={idx} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={`duration-${idx}`}
                    className="w-4 h-4 text-[#363A49]"
                  />
                  <label
                    htmlFor={`duration-${idx}`}
                    className="text-base text-[#363A49] font-medium">
                    {duration}
                  </label>
                </li>
              ))}
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Distance */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Distance</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {["All", "0 - 5km", "5 - 10km", "10 - 40km", "40+ km"].map(
                (duration, idx) => (
                  <li key={idx} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`duration-${idx}`}
                      className="w-4 h-4 text-[#363A49]"
                    />
                    <label
                      htmlFor={`duration-${idx}`}
                      className="text-base text-[#363A49] font-medium">
                      {duration}
                    </label>
                  </li>
                )
              )}
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Course Category */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Course Category</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {displayedCourse.map((duration, idx) => (
                <li key={idx} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={`duration-${idx}`}
                    className="w-4 h-4 text-[#363A49]"
                  />
                  <label
                    htmlFor={`duration-${idx}`}
                    className="text-sm text-[#363A49] font-medium ">
                    {duration}
                  </label>
                </li>
              ))}
              <button onClick={() => setCourseShow(!courseShow)}>
                {courseShow ? (
                  <div className="flex flex-row gap-3 items-center">
                    <p className="text-base text-secondary font-medium">
                      Show Less
                    </p>
                    <img src={upArrow} alt="" />
                  </div>
                ) : (
                  <div className="flex flex-row gap-3 items-center">
                    <p className="text-base text-secondary font-medium">
                      show More
                    </p>
                    <img src={downArrow} alt="" />
                  </div>
                )}
              </button>
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Sub Category */}
        <Disclosure as="div" className="pb-5 border-b px-5">
          <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
            <span className="text-sm font-medium">Sub Category</span>
            <span className="ml-6 flex h-7 items-center">
              <img
                src={downArrow}
                alt=""
                className="size-4 group-data-[open]:hidden"
              />
              <img
                src={upArrow}
                alt=""
                className="size-4 group-[&:not([data-open])]:hidden"
              />
            </span>
          </DisclosureButton>
          <DisclosurePanel as="dd" className="mt-2 pr-12">
            <ul className="space-y-2">
              {displayedSubCategory.map((duration, idx) => (
                <li key={idx} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={`duration-${idx}`}
                    className="w-4 h-4 text-[#363A49]"
                  />
                  <label
                    htmlFor={`duration-${idx}`}
                    className="text-sm text-[#363A49] font-medium ">
                    {duration}
                  </label>
                </li>
              ))}
              <button onClick={() => setSubCategoryShow(!subCategoryShow)}>
                {subCategoryShow ? (
                  <div className="flex flex-row gap-3 items-center">
                    <p className="text-base text-secondary font-medium">
                      Show Less
                    </p>
                    <img src={upArrow} alt="" />
                  </div>
                ) : (
                  <div className="flex flex-row gap-3 items-center">
                    <p className="text-base text-secondary font-medium">
                      show More
                    </p>
                    <img src={downArrow} alt="" />
                  </div>
                )}
              </button>
            </ul>
          </DisclosurePanel>
        </Disclosure>

        {/* Show More Section */}
        {/* <div className="mb-6">
          <button
            onClick={() => setShowMore(!showMore)}
            className="text-blue-500 hover:underline"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
          {showMore && (
            <div className="mt-4 space-y-4">
              <div>
                <h3 className="text-sm font-semibold mb-2">Instructor Type</h3>
                <ul className="space-y-2">
                  {["Celebrity Instructor", "Certified Instructor"].map((type, idx) => (
                    <li key={idx} className="flex items-center space-x-2">
                      <input type="checkbox" id={`instructor-${idx}`} className="w-4 h-4" />
                      <label htmlFor={`instructor-${idx}`} className="text-sm">{type}</label>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="text-sm font-semibold mb-2">Language Type</h3>
                <ul className="space-y-2">
                  {["All", "English", "Hindi", "Tamil", "Kannada", "Malayalam"].map((lang, idx) => (
                    <li key={idx} className="flex items-center space-x-2">
                      <input type="checkbox" id={`language-${idx}`} className="w-4 h-4" />
                      <label htmlFor={`language-${idx}`} className="text-sm">{lang}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default FilterSideBar;
