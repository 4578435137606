import React, { useEffect, useState } from "react";
import DiscriptionRow from "./DiscriptionRow";

function ContactInfo({profile}) {
  const [contactInformation, setContactInformation] = useState([]);
  
  useEffect(()=>{
      setContactInformation([
        {
          label:'EMAIL',
          value: profile?.email
        },
        {
          label:'PHONE NUMBER',
          value: `${profile?.countryCode?profile.countryCode:''} ${profile?.mobile?profile.mobile:''}`
        }
      ]);
    },[profile]);
  
  return (
    <>
      <div className="border border-gray-200 rounded-xl pb-2 mt-4">
        <h3 className="text-xl mx-6 mt-6 mb-6 font-semibold">
          Contact Information
        </h3>
        {
          contactInformation.map((item)=>(
            <DiscriptionRow label={item.label} value={item.value} />
          ))
        }
      </div>
    </>
  );
}

export default ContactInfo;
