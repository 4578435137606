import React, { useState } from "react";
import { Container, Button } from "../../../../components/ui-components";
import SidePanel from "../SidePanel";
import OneOnOneStep1 from './steps/OneOnOneStep1'
import OneOnOneStep2 from './steps/OneOnOneStep2'
import OneOnOneStep3 from './steps/OneOnOneStep3'
import CourseSuccessful from "../CourseSuccessful";
import SelectCourse from "../SelectCourse";

export async function createBatchOneOnOneLoader() {
  return {
    date: new Date().toISOString(),
  };
}

export default function CreateBatchOneOnOne() {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 5));
  };

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return < SelectCourse />;
      case 2:
        return <OneOnOneStep1 />;
      case 3:
        return <OneOnOneStep2 />;
      case 4:
        return <OneOnOneStep3 />;
      case 5:
        return <CourseSuccessful />;
          
      default:
        return null;
    }
  };

  
  const isSidePanelVisible = currentStep > 1 && currentStep <= 4;

  const buttonText = (() => {
    if (currentStep === 1) return "Continue"; 
    if (currentStep === 4) return "Review & Submit";
    if (currentStep === 5) return null; 
    return "Next ->";
  })();

  return (
    <div className={`${currentStep == 1 ? 'bg-white pt-12 ' : 'bg-[#EFEFEF] pt-24 h-full'} w-full flex flex-col items-center justify-center overflow-hidden`}>
      <section className="flex flex-row justify-center items-start w-full overflow-y-auto">
        {isSidePanelVisible && (
          <Container className="w-[25%]">
            <SidePanel current_step={currentStep - 1} batchType={'One_on_One'} />
          </Container>
        )}

        <div className={`w-full h-full flex flex-col justify-start items-start px-6 ${isSidePanelVisible ? "w-[80%]" : "w-full"}`}>
          {renderContent()} 
          {currentStep < 5 && ( 
            <footer className={`w-full flex ${currentStep == 1 ? 'justify-center bg-white ' : 'justify-end'} items-center py-4`}>
              <Button
                onClick={nextStep}
                text={buttonText} 
                className="min-w-32 w-max h-[50px]"
              />
            </footer>
          )}
        </div>
      </section>
    </div>
  );
}
