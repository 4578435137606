import { ArrowRightIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {getCategoryList} from "../index";

export async function MobCategoryLoader() {
  return {
    date: new Date().toISOString(),
  };
}

// const categories = [
//   {name: "Development", number: "230"},
//   {name: "Business", number: "130"},
//   {name: "IT & Software", number: "200"},
//   {name: "Personal Development", number: "510"},
//   {name: "Design", number: "211"},
//   {name: "Marketing", number: "190"},
//   {name: "Lifestyle", number: "111"},
//   {name: "Health & Fitness", number: "316"},
//   {name: "Music", number: "121"},
//   {name: "Teaching & Academics", number: "666"},
// ]

function MobCategory() {
    const [categories, setCategories] = useState([]);
    // console.log("loader Data", loaderData);

  
    const getCats = async () => {
      const categoryList = await getCategoryList();
      // console.log("categoryList", categoryList);
      if (categoryList.length > 0) {
        setCategories(categoryList);
      }
    };
  
  
    useEffect(() => {
      getCats();
    }, []);

  return (
    <div className="p-5">
      <h1 className="text-2xl text-secondary font-medium mb-4">All Categories</h1>
      <h3 className="text-sm text-[#121212] font-semibold mb-3">Browse by category</h3>
      <div className="flex flex-col gap-4">
        {
          categories.map((item)=>(
            <Link key={item.id} to={`/categories/${item.id}`} state={{categoryName: item.name}} className="flex flex-row justify-between items-center border-b pb-3">
              <p className="text-base text-gray-500 font-medium">{item.name} (121)</p>
              <ChevronRightIcon className="w-6" />
            </Link>
          ))
        }
      </div>
    </div>
  );
}

export default MobCategory;
