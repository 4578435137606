import React, { useEffect, useRef, useState } from "react";
import Sortable from "sortablejs";

export const CardTile = ({ children }) => (
  <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
    <div className="bg-[#F0F0F0] w-full">{children}</div>
  </div>
);

export const EmptyCardTile = () => (
  <div className="w-full h-max">
        <div id="alert-2" class="flex items-center p-4 mb-4 text-red-500 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
          <svg width="40" height="40" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 19L11 0L22 19H0ZM3.45 17H18.55L11 4L3.45 17ZM11 16C11.2833 16 11.5208 15.9042 11.7125 15.7125C11.9042 15.5208 12 15.2833 12 15C12 14.7167 11.9042 14.4792 11.7125 14.2875C11.5208 14.0958 11.2833 14 11 14C10.7167 14 10.4792 14.0958 10.2875 14.2875C10.0958 14.4792 10 14.7167 10 15C10 15.2833 10.0958 15.5208 10.2875 15.7125C10.4792 15.9042 10.7167 16 11 16ZM10 13H12V8H10V13Z" fill="#C51B1B"/>
          </svg>
          <span class="sr-only">Info</span>
          <div class="ms-3 text-sm font-medium">
          Some classes are currently empty as the number of classes exceeds the chapters assigned. Please add chapters to the empty classes or reduce the number of classes for better alignment.
          </div>
          <button type="button" class="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-2" aria-label="Close">
            <span class="sr-only">Close</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
      </div>
      <div className="bg-white p-16 rounded-[34px] mb-4 flex flex-col items-start w-full">
        <div className="w-full flex flex-row items-center justify-center">
          <p className="text-gray-500 text-md">NO CHAPTERS ADDED</p>
        </div>
      </div>
  </div>
);

const SortableList = ({ chapters, onChapterReorder, groupName }) => {
  const chaptersRef = useRef(null);

  useEffect(() => {
    if (chaptersRef.current) {
      const sortable = new Sortable(chaptersRef.current, {
        animation: 150,
        handle: ".chapter-drag-handle",
        group: groupName, // Enable cross-group dragging
        onEnd: (evt) => {
          const newOrder = [...chapters];
          const [movedItem] = newOrder.splice(evt.oldIndex, 1);
          newOrder.splice(evt.newIndex, 0, movedItem);
          onChapterReorder(newOrder, evt);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [chapters, onChapterReorder, groupName]);

  return (
    <ul ref={chaptersRef} className="w-full flex flex-col">
      {chapters.map((chapter, index) => (
        <li
          key={chapter.ch_name}
          className="w-full flex flex-row justify-between items-center mb-4 chapter-drag-handle cursor-grab"
        >
          <svg
            className="w-5 h-5 text-gray-400 shrink-0 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="9" cy="12" r="1"></circle>
            <circle cx="9" cy="5" r="1"></circle>
            <circle cx="9" cy="19" r="1"></circle>
            <circle cx="15" cy="12" r="1"></circle>
            <circle cx="15" cy="5" r="1"></circle>
            <circle cx="15" cy="19" r="1"></circle>
          </svg>
          <div className="w-full flex flex-col justify-center items-center mb-4 bg-white border pb-4 pt-8 px-4 border-gray-200 rounded-[24px]">
            <p className="w-full text-left text-sm text-gray-400 p-2">
              {chapter.ch_name}
            </p>
            <div className="bg-[#F1F1F1] w-full h-max rounded-[24px]">
              <div className="w-full flex flex-row justify-between items-center p-4 chapter-drag-handle cursor-grab">
                <div className="w-full flex flex-col justify-center items-center p-2">
                  <p className="w-full text-2xl font-semibold">
                    {chapter.title}
                  </p>
                  <p className="w-full text-sm text-gray-400">
                    {chapter.subtitle}
                  </p>
                </div>
                <button className="w-[20%] ml-4 text-sm text-gray-500">
                  <div className="flex items-center">
                    <svg
                      width="15"
                      height="9"
                      viewBox="0 0 15 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.53516 1.5L7.71583 7.5L13.8965 1.5"
                        stroke="#2A3140"
                        stroke-opacity="0.7"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default function ClassroomStep2() {
  const MileStones = [
    {
      milestone: "MILESTONE 1",
      class: [
        {
          class_name: "CLASS 1",
          chapters: [
            {
              ch_name: "CHAPTER 1",
              title: "Introduction to UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
            },
            {
              ch_name: "CHAPTER 2",
              title: "Impact of design in UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
            },
          ],
        },
        {
          class_name: "CLASS 2",
          chapters: [
            {
              ch_name: "CHAPTER 3",
              title: "Introduction to UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
            },
            {
              ch_name: "CHAPTER 4",
              title: "Impact of design in UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
            },
          ],
        },
      ],
    },
    {
      milestone: "MILESTONE 2",
      class: [
        {
          class_name: "CLASS 3",
          chapters: [
            {
              ch_name: "CHAPTER 5",
              title: "Essence of UI/UX design",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
            },
            {
              ch_name: "CHAPTER 6",
              title: "Introduction to UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
            },
            {
              ch_name: "CHAPTER 7",
              title: "Impact of design in UI/UX",
              subtitle: "Learn the basics of UI/UX and their importance in Design",
            },
          ],
        },
        {
          class_name: "CLASS 4",
          chapters: [],
        },
        {
          class_name: "CLASS 4",
          chapters: [],
        },
      ],
    },
  ];

  const [chapterData, setChapterData] = useState(MileStones);

  const handleChapterReorder = (updatedChapters, milestoneIndex, classIndex) => {
    setChapterData((prevData) =>
      prevData.map((milestone, mIdx) =>
        mIdx === milestoneIndex
          ? {
              ...milestone,
              class: milestone.class.map((cls, cIdx) =>
                cIdx === classIndex
                  ? { ...cls, chapters: updatedChapters }
                  : cls
              ),
            }
          : milestone
      )
    );
  };

  return (
    <div className="w-full h-full flex flex-col justify-start items-start grow overflow-y-auto px-8">
      <div className="w-full flex flex-row justify-between items-center py-6">
        <h1 className="font-medium text-xl text-left text-secondary mb-4">
          Class Distribution
        </h1>
        <div className="flex justify-evenly items-center text-sm font-medium p-2">
        <svg className="mr-4" width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33073 9.4974L4.9974 12.8307L1.66406 9.4974M1.66406 4.4974L4.9974 1.16406L8.33073 4.4974" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

          EXPAND ALL
        </div>
      </div>
      <div className="w-full h-max flex flex-col justify-center items-start">
        {chapterData.map((milestone, milestoneIndex) => (
          <div
            key={milestone.milestone}
            className="w-full flex flex-col justify-center items-center"
          >
            <p className="p-2 rounded-full border text-[13px] text-primary font-medium border-gray-400">
              {milestone.milestone}
            </p>
            {milestone.class.map((cls, classIndex) => (
              <div
                key={cls.class_name}
                className="w-full flex flex-col justify-center items-start"
              >
                <div className="w-full flex flex-row justify-center items-center py-4">
                  <span className="bg-gray-400 h-[2px] w-20 px-2" />
                  <p className="mt-2 text-primary font-medium text-[13px] mx-4">
                    {cls.class_name}
                  </p>
                  <span className="bg-gray-400 h-[2px] w-20 px-2" />
                </div>
                {cls.chapters.length === 0 ? (
                  <EmptyCardTile />
                ) : (
                  <SortableList
                    chapters={cls.chapters}
                    onChapterReorder={(updatedChapters) =>
                      handleChapterReorder(updatedChapters, milestoneIndex, classIndex)
                    }
                    groupName="shared-chapters"
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
