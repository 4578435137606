import React, { useEffect, useState } from "react";
// import { EditPencil } from "../../../components/icons";
import { EditPencilTwo } from "../../../components/icons";
// import userCircle from "../../../assets/svgs/userCircle.svg";
import PersonalInfo from "./PersonalInfo";
import ContactInfo from "./ContactInfo";
import Bio from "./Bio";
import Language from "./Language";
import Topics from "./Topics";
import SocialMedia from "./SocialMedia";
import ProfileCard from "./ProfileCard";
import Education from "./Education";
import WorkExperience from "./WorkExprience";
import { Container } from "../../../components/ui-components";
import {checkIsMobile} from "../../../utils/helpers";
import MobProfile from "./mobileProfile";
import { getProfile } from "../../../redux/profile/actionCreator";
import { useLoaderData } from "react-router-dom";
import store from "../../../redux/store";
import { getCountries, getDegrees, getEmploymentType, getIndustries } from "../../../redux/master_data/actionCreator";
import { useSelector } from "react-redux";
import { getEducations } from "../../../redux/educations/actionCreator";
import { getWorkExprience } from "../../../redux/work_exprience/actionCreator";

const getMyProfile = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getProfile());
    if(responce && responce.type == 'PROFILE_SUCCESS'){
      resolve(responce.data);
    }else{
      resolve([]);
    }
  })
}

const getEducationList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getEducations());
    if(responce && responce.type == 'EDUCATIONS_SUCCESS'){
      resolve(responce.data);
    }else{
      resolve([]);
    }
  })
}

const getWorkExprienceList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getWorkExprience());
    if(responce && responce.type == 'WORK_EXPRIENCE_SUCCESS'){
      resolve(responce.data);
    }else{
      resolve([]);
    }
  })
}

const getDegreesList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getDegrees());
    if(responce && responce.type == 'MASTER_DATA_SUCCESS'){
      const dd = responce.data.map((item)=>{
        return {
          key: item.id,
          value: item.name
        }
      });
      resolve(dd);
    }else{
      resolve([]);
    }
  })
}

const getEmploymentTypeList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getEmploymentType());
    if(responce && responce.type == 'MASTER_DATA_SUCCESS'){
      const dd = responce.data.map((item)=>{
        return {
          key: item.id,
          value: item.name
        }
      });
      resolve(dd);
    }else{
      resolve([]);
    }
  })
}

const getIndustriesList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getIndustries());
    if(responce && responce.type == 'MASTER_DATA_SUCCESS'){
      const dd = responce.data.map((item)=>{
        return {
          key: item.id,
          value: item.name
        }
      });
      resolve(dd);
    }else{
      resolve([]);
    }
  })
}

const getCountriesList = () => {
  return new Promise(async(resolve, reject)=>{
    const responce = await store.dispatch(getCountries());
    if(responce && responce.type == 'MASTER_DATA_SUCCESS'){
      resolve(responce.data);
    }else{
      resolve([]);
    }
  })
}


export async function myProfileLoader() {
  // alert('sasasasaasasasas');
  const profile = await getMyProfile();
  const countries = await getCountriesList();
  const degrees = await getDegreesList();
  const educations = await getEducationList();
  const workExpriences = await getWorkExprienceList();
  const industries = await getIndustriesList();
  const employmentTypes = await getEmploymentTypeList();
  
  return {
    date: new Date().toISOString(),
    profile: profile,
    countries:countries,
    degrees:degrees,
    educations:educations,
    workExpriences:workExpriences,
    industries:industries,
    employmentTypes:employmentTypes
  };
}
// getProfile
const MyProfile = () => {
  const isMobile = checkIsMobile();
  let loaderData = useLoaderData();
  const { myProfile, workExpriences, educations } = useSelector((state)=> {
    return {
      myProfile: state.myProfile.data,
      workExpriences: state.workExprience.data,
      educations: state.educations.data 
    }
  });


  // console.log('profileprofileprofileprofileprofile', myProfile);
  return (
    <>
      {isMobile ? (
        <MobProfile />
      ) : (
        <div className="w-full bg-gray-6 relative">
          <div className="bg-[#D9D9D9] absolute h-60 w-full top-0 left-0 z-0">
            <button className="border border-gray-400 px-2 py-2 rounded-full absolute right-8 top-4 w-14 flex justify-center items-center">
              <EditPencilTwo color="black" />
            </button>
          </div>
          {/* Profile Section */}
          <div className="w-full mt-36 relative">
            <Container>
              <div className="flex flex-row justify-start items-start gap-6">
                <div className="flex w-64 pt-12">
                  {/* Profile Card */}
                  <ProfileCard profile={myProfile} />
                </div>
                <div className="flex flex-col w-full mb-8">
                  <h3 className="font-semibold h-12 text-lg">Profile</h3>
                  <div className="profile-info-card bg-white px-4 py-4 rounded-xl">
                    {/* personal Information */}
                    <PersonalInfo profile={myProfile} countries={loaderData.countries} />

                    {/* Contact Information */}
                    <ContactInfo profile={myProfile} />

                    {/* Bio */}
                    <Bio profile={myProfile} />

                    {/* Preferred Language */}
                    {/* <Language profile={myProfile} /> */}

                    {/* Topics */}
                    <Topics profile={myProfile} />

                    {/* Social Media */}
                    <SocialMedia profile={myProfile} />
                  </div>
                  {/* Education Section */}
                  <div className="my-5">
                    <Education educations={educations} degrees={loaderData.degrees} />
                  </div>
                  {/* Work Experience Section */}
                  <div className="my-5">
                    <WorkExperience workExpriences={workExpriences} industries={loaderData.industries} employmentTypes={loaderData.employmentTypes} />
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default MyProfile;
