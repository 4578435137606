
// const AUTH_BEGIN = 'AUTH_BEGIN';
// const AUTH_ERR = 'AUTH_ERR';
// const AUTH_SUCCESS = 'AUTH_SUCCESS';


const actiontyps = {
    MASTER_DATA_BEGIN:'MASTER_DATA_BEGIN',
    MASTER_DATA_ERR:'MASTER_DATA_ERR',
    MASTER_DATA_SUCCESS:'MASTER_DATA_SUCCESS'
}

export default actiontyps;