import React from "react";
import save from "../../assets/svgs/save.svg";
import { checkIsMobile } from "../../utils/helpers";

const venueData = [
  {
    title: "Increased Visibility",
    body: "our venue will be featured on our platform, giving you access to a wider audience of instructors and learners.",
  },
  {
    title: "Steady Revenue Stream",
    body: "Your venue will be featured on our platform, giving you access to a wider audience of instructors and learners.",
  },
  {
    title: "Flexible Scheduling",
    body: "Offer your venue based on availability and manage bookings with ease.",
  },
  {
    title: "Community Impact",
    body: "Be part of an educational movement by hosting classes that help individuals up skill and learn new things.",
  },
];

function VenueBenefits() {
  const isMobile = checkIsMobile();
  return (
    <>
      <div className="flex flex-col gap-8 md:gap-0 md:flex-row md:justify-between">
        <div className="md:w-96 flex flex-col gap-4 m-auto text-center md:text-start md:m-0">
          <h1 className="text-2xl md:text-5xl text-secondary font-medium">Benefits of{isMobile && <br />} Being a Venue Provider</h1>
          <p className="text-sm md:text-lg text-primary">
            Becoming a venue provider on our platform offers unique advantages
            that go beyond traditional rentals.
          </p>
        </div>
        <div className="grid grid-cols-2 gap-2 md:gap-4 m-auto md:m-0">
          {venueData.map((item, index) => (
            <div className="w-fit h-52 px-2 py-5 md:w-72 md:h-72  md:px-5  md:py-7 flex flex-col  justify-between bg-[#F7F7F7] rounded-xl">
              <div className="bg-[#040303] w-10 md:w-12 rounded-full px-1 py-2 md:py-3">
                <img className="w-5 md:w-5 m-auto" src={save} alt="save" />
              </div>
              <div className="flex flex-col md:gap-2">
                <h2 className="text-base md:text-2xl text-secondary font-medium">
                  {item.title}
                </h2>
                <p className="text-xs md:text-sm text-primary">
                  {item.body}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default VenueBenefits;
