import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import  Button  from "../../../components/ui-components/Button";


const courseData = [
    { title: "How to become a UX Designer", description: "17 Chapter | intermediate | English"},
    { title: "Advance UI Design Principles", description: "27 Chapter | intermediate | English"},
    { title: "Become a Design Leader", description: "19 Chapter | Difficult | English"},
    { title: "Building Effective Prototypes", description: "27 Chapter | Beginner | English"},
    { title: "Designing for Accessibility", description: "20 Chapter | intermediate | English"},
    { title: "Designing for Accessibility", description: "20 Chapter | intermediate | English"},
    { title: "Designing for Accessibility", description: "20 Chapter | intermediate | English"},
    { title: "Designing for Accessibility", description: "20 Chapter | intermediate | English"},
  ];

  const Card = ({
    title,
    description,
  }) => (
    <div className="bg-white p-4 rounded-[34px] mb-4 flex justify-between items-center h-[120px] w-[50%]">
      <div className="bg-gray-200 h-[92px] w-[140px] rounded-[30px] flex flex-col items-start justify-between"></div>
      <div className="flex flex-col items-start justify-evenly mb-4 ml-6 w-[65%]">
        <h3 className="font-semibold text-md">{title}</h3>
        <p className="text-gray-500 pt-2 text-[10px]">{description}</p>
          <p className="text-gray-400 text-center text-[10px] w-max border-gray-300 border-2 p-1 mt-3 rounded-full">
            DESIGN
          </p>
      </div>
      
      <div>
        <input type="checkbox" id='courseID' className="rounded-full w-5 h-5" />
      </div>
    </div>
  );

export default function SelectCourse() {

  return (
    <>
  
          <div className="h-[90%] w-full flex flex-col justify-center items-start mt-10">
            <h2 className="text-xl text-center font-bold w-full">
              Select a course
            </h2>
            <h6 className="text-[13px] text-center text-gray-1 w-full pb-4">
              Choose a course form the list below to proceed with creating a new batch.
            </h6>
        <div className="flex flex-col items-center justify-center w-full h-full">

          <section className="flex flex-col justify-start items-center w-full p-4 overflow-y-auto">
            {courseData.map((course) => (
              <div className="w-full flex flex-col justify-center items-center">
                <Card title={course.title} description={course.description} />
              </div>
            ))}
          </section>

          
        </div>
          </div>
    
    </>
  );
}
