import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import CourseCard from "../courses/courseCard";
import { Link } from "react-router-dom";
import { checkIsMobile } from "../../utils/helpers";

const FreeCourses = () => {
    const isMobile = checkIsMobile();
    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center md:mb-3">
                <h5 className="text-2xl md:text-3xl font-bold ">Similar Courses</h5>
                <button className="w-20 flex flex-row justify-between items-center"><span className="text-lg font-medium text-text">See All</span><ArrowRightIcon className="size-5" /></button>
            </div>
            <div className={`md:grid md:grid-cols-3 md:gap-10 py-10 ${isMobile && "flex flex-row gap-5 overflow-x-auto scroll-smooth custom-scroll"}`}>
                {
                    ['','',''].map((item, index)=>(
                        <Link to={'/courses/courseid'} key={index+1} className={`${isMobile && "shrink-0 w-80"}`}>
                            <CourseCard type={index} />
                        </Link>
                    ))
                }
            </div>
        </div>
    );
};

export default FreeCourses;
