import React, { useEffect, useRef, useState } from "react";
import Sortable from "sortablejs";

export const CardTile = ({ children }) => (
  <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
    <div className="bg-[#F0F0F0] w-full">{children}</div>
  </div>
);

const SortableList = ({ chapters, onChapterReorder, groupName }) => {
  const chaptersRef = useRef(null);

  useEffect(() => {
    if (chaptersRef.current) {
      const sortable = new Sortable(chaptersRef.current, {
        animation: 150,
        handle: ".chapter-drag-handle",
        group: groupName, // Enable cross-group dragging
        onEnd: (evt) => {
          const newOrder = [...chapters];
          const [movedItem] = newOrder.splice(evt.oldIndex, 1);
          newOrder.splice(evt.newIndex, 0, movedItem);
          onChapterReorder(newOrder, evt);
        },
      });

      return () => {
        sortable.destroy();
      };
    }
  }, [chapters, onChapterReorder, groupName]);

  return (
    <ul ref={chaptersRef} className="w-full flex flex-col">
      {chapters.map((chapter, index) => (
        <li
          key={chapter.ch_name}
          className="w-full flex flex-row justify-between items-center mb-4 chapter-drag-handle cursor-grab"
        >
          <svg
            className="w-5 h-5 text-gray-400 shrink-0 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="9" cy="12" r="1"></circle>
            <circle cx="9" cy="5" r="1"></circle>
            <circle cx="9" cy="19" r="1"></circle>
            <circle cx="15" cy="12" r="1"></circle>
            <circle cx="15" cy="5" r="1"></circle>
            <circle cx="15" cy="19" r="1"></circle>
          </svg>
          <div className="w-full flex flex-col justify-center items-center mb-4 bg-white border pb-4 pt-8 px-4 border-gray-200 rounded-[24px]">
            <p className="w-full text-left text-sm text-gray-400 p-2">
              {chapter.ch_name}
            </p>
            <div className="bg-[#F1F1F1] w-full h-max rounded-[24px]">
              <div className="w-full flex flex-row justify-between items-center p-4 chapter-drag-handle cursor-grab">
                <div className="w-full flex flex-col justify-center items-center p-2">
                  <p className="w-full text-2xl font-semibold">
                    {chapter.title}
                  </p>
                  <p className="w-full text-sm text-gray-400">
                    {chapter.subtitle}
                  </p>
                </div>
                <button className="w-[25%] ml-4 text-sm text-gray-500">
                  <div className="flex items-center">
                    <p className="w-full text-sm text-gray-400 mr-2">
                        {chapter.duration}
                    </p>
                    <svg
                      width="15"
                      height="9"
                      viewBox="0 0 15 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.53516 1.5L7.71583 7.5L13.8965 1.5"
                        stroke="#2A3140"
                        stroke-opacity="0.7"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default function EditClassDistribution() {
    const MileStones = [
        {
          milestone: "MILESTONE 1",
          class: [
            {
              class_name: "CLASS 1",
              chapters: [
                {
                  ch_name: "CHAPTER 1",
                  title: "Introduction to UI/UX",
                  subtitle: "Learn the basics of UI/UX and their importance in Design",
                  duration :'2 Topics . 30min'
                },
                {
                  ch_name: "CHAPTER 2",
                  title: "Impact of design in UI/UX",
                  subtitle: "Learn the basics of UI/UX and their importance in Design",
                  duration :'2 Topics . 30min'
                },
              ],
            },
            {
              class_name: "CLASS 2",
              chapters: [
                {
                  ch_name: "CHAPTER 3",
                  title: "Introduction to UI/UX",
                  subtitle: "Learn the basics of UI/UX and their importance in Design",
                  duration :'2 Topics . 30min'
                },
                {
                  ch_name: "CHAPTER 4",
                  title: "Impact of design in UI/UX",
                  subtitle: "Learn the basics of UI/UX and their importance in Design",
                  duration :'2 Topics . 30min'
                },
              ],
            },
          ],
        },
        {
          milestone: "MILESTONE 2",
          class: [
            {
              class_name: "CLASS 3",
              chapters: [
                {
                  ch_name: "CHAPTER 5",
                  title: "Essence of UI/UX design",
                  subtitle: "Learn the basics of UI/UX and their importance in Design",
                  duration :'2 Topics . 30min'
                },
                {
                  ch_name: "CHAPTER 6",
                  title: "Introduction to UI/UX",
                  subtitle: "Learn the basics of UI/UX and their importance in Design",
                  duration :'2 Topics . 30min'
                },
                {
                  ch_name: "CHAPTER 7",
                  title: "Impact of design in UI/UX",
                  subtitle: "Learn the basics of UI/UX and their importance in Design",
                  duration :'2 Topics . 30min'
                },
              ],
            },
            {
              class_name: "CLASS 4",
              chapters: [
                {
                    ch_name: "CHAPTER 8",
                    title: "Essence of UI/UX design",
                    subtitle: "Learn the basics of UI/UX and their importance in Design",
                    duration :'2 Topics . 30min'
                  },
                  {
                    ch_name: "CHAPTER 9",
                    title: "Introduction to UI/UX",
                    subtitle: "Learn the basics of UI/UX and their importance in Design",
                    duration :'2 Topics . 30min'
                  },
              ],
            },
            {
              class_name: "CLASS 5",
              chapters: [
                {
                    ch_name: "CHAPTER 10",
                    title: "Essence of UI/UX design",
                    subtitle: "Learn the basics of UI/UX and their importance in Design",
                    duration :'2 Topics . 30min'
                  },
                  {
                    ch_name: "CHAPTER 11",
                    title: "Introduction to UI/UX",
                    subtitle: "Learn the basics of UI/UX and their importance in Design",
                    duration :'2 Topics . 30min'
                  },
              ],
            },
          ],
        },
      ];
    

  const [chapterData, setChapterData] = useState(MileStones);

  const handleChapterReorder = (updatedChapters, milestoneIndex, classIndex) => {
    setChapterData((prevData) =>
      prevData.map((milestone, mIdx) =>
        mIdx === milestoneIndex
          ? {
              ...milestone,
              class: milestone.class.map((cls, cIdx) =>
                cIdx === classIndex
                  ? { ...cls, chapters: updatedChapters }
                  : cls
              ),
            }
          : milestone
      )
    );
  };

  return (
      <div className="w-full h-max flex flex-col justify-center items-start pt-6">
        {chapterData.map((milestone, milestoneIndex) => (
          <div
            key={milestone.milestone}
            className="w-full flex flex-col justify-center items-center"
          >
            <p className="p-2 rounded-full border text-[13px] text-primary font-medium border-gray-400">
              {milestone.milestone}
            </p>
            {milestone.class.map((cls, classIndex) => (
              <div
                key={cls.class_name}
                className="w-full flex flex-col justify-center items-start"
              >
                <div className="w-full flex flex-row justify-center items-center py-4">
                  <span className="bg-gray-400 h-[2px] w-20 px-2" />
                  <p className="mt-2 text-primary font-medium text-[13px] mx-4">
                    {cls.class_name}
                  </p>
                  <span className="bg-gray-400 h-[2px] w-20 px-2" />
                </div>
                  <SortableList
                    chapters={cls.chapters}
                    onChapterReorder={(updatedChapters) =>
                      handleChapterReorder(updatedChapters, milestoneIndex, classIndex)
                    }
                    groupName="shared-chapters"
                  />
              </div>
            ))}
          </div>
        ))}
      </div>
  );
}
