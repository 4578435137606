// import Cookies from 'js-cookie';
// import { message } from 'antd';
import { DataService } from '../../dataService/dataService';
import actiontyps from './actionsTypes';
import { setItem } from '../../utils/localStorageControl';
const { PROFILE_BEGIN, PROFILE_SUCCESS, PROFILE_ERR } = actiontyps; 

const getProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({type: PROFILE_BEGIN});
      let response = await DataService.get(`user/profile`);
      // console.log('profile response', response);
      if(await response.data && response.data.user){
        setItem('simplitrain_user', response.data.user);
        setItem('simplitrain_user_id', response.data.user.id);
        return dispatch({ type: PROFILE_SUCCESS, data:response.data.user});
      }else{
        return dispatch({ type: PROFILE_ERR, err:response.data.message});
      }
    } catch (err) {
      dispatch({ type: PROFILE_ERR, err:err});
    }
  };
};


const updateProfile = (data) => {
  return async (dispatch) => {
    try {
      dispatch({type: PROFILE_BEGIN});
      let postData = {
        name: data.name,
        age_limit: data.age,
        gender: data.gender,
      };      
      let response = await DataService.put('user/profile', postData);
      console.log('responseresponse', response);
      if(await response.data.success){
        // message.success({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_SUCCESS, data:{...response.data.user}});
      }else{
        // message.error({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_ERR, err:response.data.message});
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch({ type: PROFILE_ERR, err:err});
    }
  };
};

const updateBio = (data) => {
  return async (dispatch) => {
    try {
      dispatch({type: PROFILE_BEGIN});
      let postData = {
        onboarding: {
          bio:data.bio
        }
      };      
      let response = await DataService.put('user/profile', postData);
      console.log('responseresponse', response);
      if(await response.data.success){
        // message.success({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_SUCCESS, data:{...response.data.user}});
      }else{
        // message.error({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_ERR, err:response.data.message});
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch({ type: PROFILE_ERR, err:err});
    }
  };
};

const updateInterestedTopics = (data) => {
  return async (dispatch) => {
    try {
      dispatch({type: PROFILE_BEGIN});
      let postData = {
        learningGoals: data.topics
      };      
      let response = await DataService.put('user/profile', postData);
      console.log('responseresponse', response);
      if(await response.data.success){
        // message.success({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_SUCCESS, data:{...response.data.user}});
      }else{
        // message.error({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_ERR, err:response.data.message});
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch({ type: PROFILE_ERR, err:err});
    }
  };
};

const updateSocialLinks = (data) => {
  return async (dispatch) => {
    try {
      dispatch({type: PROFILE_BEGIN});
      let postData = {
        socialLinks: data.socialLinks
      };      
      let response = await DataService.put('user/profile', postData);
      console.log('responseresponse', response);
      if(await response.data.success){
        // message.success({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_SUCCESS, data:{...response.data.user}});
      }else{
        // message.error({ content:  response.data.message, duration: 2 });
        return dispatch({ type: PROFILE_ERR, err:response.data.message});
      }
      // setTimeout(() => {
      //   Cookies.set('logedIn', true);
      //   return dispatch(loginSuccess(true));
      // }, 1000);
    } catch (err) {
      dispatch({ type: PROFILE_ERR, err:err});
    }
  };
};

export { getProfile, updateProfile, updateBio, updateInterestedTopics, updateSocialLinks };
