import React from "react";
import { EditPencilTwo } from "../../../components/icons";
import linkedin from "../../../assets/svgs/linkedin.svg";
import twitter from "../../../assets/svgs/twitter.svg";
import colorGoogle from "../../../assets/svgs/colorGoogle.svg";
import pinkball from "../../../assets/svgs/pinkball.svg";
import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Drawer, FloatingTextField } from "../../../components/ui-components";
import { Button } from "../../../components/ui-components";

function SocialMedia() {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  
  const [socialLinks, setSocialLinks] = useState([
    {
      platform: "linkedin",
      link: ""
    },
    {
      platform: "instagram",
      link: ""
    },
    {
      platform: "facebook",
      link: ""
    },
    {
      platform: "twitter",
      link: ""
    },
    {
      platform: "youtube",
      link: ""
    }
  ]);

  const getDomain = (url, subdomain) => {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
        url = url.split('.');

        // url = url.slice(url.length - 2).join('.');
        url = url[url.length - 2];
    }

    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }

    return url;
  }

  const isValidUrl = (string) => {
    try {
      new URL(string);
      console.log(URL);
      return true;
    } catch (err) {
      return false;
    }
  }

  const validate = () => {
    const newErrors = {};
    const URLFormate = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    
    socialLinks.forEach(element => {
      if(element.link){
        if(isValidUrl(element.link)){

        }else{
          newErrors[element.platform] = 'Invalid URL';
        }
      }

    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e, index) => {
    // const newLink = {
    //   platform: e.target.name,
    //   link: e.target.value
    // };
    const newErrors = {};
    if(isValidUrl(e.target.value)){
      let links = [...socialLinks];
      links[index].link = e.target.value;
      if(!links[index].platform || links[index].platform == 'your link'){
        links[index].platform = getDomain(e.target.value);
      }
      setSocialLinks([...links]);
    }else{
      newErrors[e.target.name ] = 'Invalid URL';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(socialLinks);
    if (validate()) {

    }
  };
  return (
    <>
      <div className="border border-gray-200 rounded-xl my-4 relative">
        <h3 className="text-xl mx-6 mt-6 mb-8 font-semibold">Social Media</h3>
        <button
          className="border bg-white border-gray-200 px-2 py-2 rounded-full absolute right-4 top-6 w-22 flex gap-2 justify-center items-center transform transition duration-300 hover:scale-110"
          onClick={() => setOpen(true)}>
          <p className="text-base ml-2">Edit</p>
          <EditPencilTwo color="black" />
        </button>
        <div className="flex flex-row mx-6 mb-6 gap-6">
          <img src={linkedin} alt="linkedin logo" />
          <img src={twitter} alt="twitter logo" />
          <img src={colorGoogle} alt="Google logo" />
          <img src={pinkball} alt="PinkBall logo" />
        </div>
      </div>

      <Drawer open={open} onClose={setOpen} title="Edit Social Media Link">
          <form
            onSubmit={handleSubmit}
            className="w-96 flex flex-col justify-between m-auto mt-4 h-full">
              <div className="flex flex-col justify-center">
                {
                  socialLinks.map((socialLink, index)=>(
                    <FloatingTextField
                      key={index}
                      label={socialLink.platform.toLocaleUpperCase()}
                      type="text"
                      value={socialLink.link}
                      onblur={(e)=> handleChange(e, index)}
                      name={socialLink.platform}
                      id={socialLink.platform}
                      error={errors[socialLink.platform]}
                      // placeholder="demo@gmail.com"
                    />
                  ))
                }
                <Button type="text"></Button>
                
              </div>

              <Button
                type="submit"
                color="primary"
                variant="solid"
                className="w-52 m-auto my-6">
                SAVE
              </Button>
          </form>
      </Drawer>

      
    </>
  );
}

export default SocialMedia;
