import React, { useState, useRef, useEffect } from "react";
import { Drawer } from "../../../../components/ui-components";
import { Button } from "../../../../components/ui-components";

export default function Step4() {
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [imagePreview, setImagePreview] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
  
    // Handle image files
    const handleImageFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image")) {
        setSelectedFile(file);
        setImagePreview(URL.createObjectURL(file)); // Set image preview
        setOpen(true); // Open the drawer for image editing
      } else {
        alert("Please upload a valid image file.");
      }
    };
  
    // Handle video files
    const handleVideoFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith("video")) {
        setSelectedFile(file);
        setVideoPreview(URL.createObjectURL(file)); // Set video preview
        setOpen(false); // Close drawer for video files
      } else {
        alert("Please upload a valid video file.");
      }
    };
  
    useEffect(() => {
      return () => {
        if (imagePreview) {
          URL.revokeObjectURL(imagePreview); // Clean up image preview URL
        }
        if (videoPreview) {
          URL.revokeObjectURL(videoPreview); // Clean up video preview URL
        }
      };
    }, [imagePreview, videoPreview]);
  
    const handleCloseDrawer = () => {
      setOpen(false);
      setSelectedFile(null);
      setCroppedImage(null);
    };
  
    const imageRef = useRef(null);
    const canvasRef = useRef(null);
  
    const handleZoom = (e) => {
      setZoom(parseFloat(e.target.value));
    };
  
    const handleRotate = (direction) => {
      setRotation((prev) => prev + (direction === "left" ? -90 : 90));
    };
  
    const handleCrop = () => {
      if (!imageRef.current || !canvasRef.current) return;
  
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const image = imageRef.current;
  
      const size = 300; // Square size for cropped area
      canvas.width = size;
      canvas.height = size;
  
      const scale = image.naturalWidth / image.width;
      const cropX = (image.width - size) / 2 / zoom;
      const cropY = (image.height - size) / 2 / zoom;
  
      ctx.clearRect(0, 0, size, size);
      ctx.drawImage(
        image,
        cropX * scale,
        cropY * scale,
        (size * scale) / zoom,
        (size * scale) / zoom,
        0,
        0,
        size,
        size
      );
  
      const croppedImageUrl = canvas.toDataURL("image/jpeg");
      setOpen(false);
      setCroppedImage(croppedImageUrl);
      console.log("Cropped Image Data URL:", croppedImageUrl);
    };

  return (
    <>
      <div className="w-full h-full flex flex-col justify-start items-start grow overflow-y-auto px-8">
        <h1 className="font-medium text-lg text-left text-secondary mb-6">
          Gallery
        </h1>

        <div className="w-full flex flex-row justify-between items-center">
          {/* Course Thumbnail */}
          <div className="w-[50%] flex flex-col items-start h-max rounded-[24px] p-6 bg-white shadow-sm">
            <h2 className="font-semibold text-md mb-2 text-gray-800">
              Course Thumbnail *
            </h2>
            <p className="text-sm text-gray-500 mb-4 text-center">
              Add an engaging image to represent your course
            </p>
            <div className={`w-full text-gray-400 flex flex-col items-center justify-start h-64 border-2 ${croppedImage || imagePreview ? "border-white" : 'border-dashed border-gray-300' } rounded-lg`}>
              {croppedImage || imagePreview ? (
                <div className="w-full flex flex-col justify-center items-center">
                    <img
                      src={croppedImage || imagePreview}
                      alt="Cropped"
                      className="object-contain h-full w-full rounded-lg"
                    />
                <button className="flex p-2 mt-4 flex-row justify-center items-center w-max text-[13px] border border-gray-400 rounded-[10px]">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3294 7.17578C15.0294 7.40828 16.1319 8.79578 16.1319 11.8333V11.9308C16.1319 15.2833 14.7894 16.6258 11.4369 16.6258H6.55437C3.20187 16.6258 1.85938 15.2833 1.85938 11.9308V11.8333C1.85938 8.81828 2.94687 7.43078 5.60187 7.18328" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 11.7498V3.21484" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.5094 4.8875L8.99688 2.375L6.48438 4.8875" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p className="text-primary ml-3">Reupload</p>
                </button>
                </div>
              ) : (
                <div className="py-12 flex flex-col justify-center items-center">
                  <input
                    type="file"
                    accept="image/jpeg,image/png"
                    className="hidden"
                    id="thumbnailInput"
                    onChange={handleImageFileChange}
                  />
                  <label htmlFor="thumbnailInput">
                    <svg
                      className="w-full text-gray-400 lucide lucide-cloud-upload flex flex-col items-center justify-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="52"
                      height="52"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M12 13v8" />
                      <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
                      <path d="m8 17 4-4 4 4" />
                    </svg>
                    <div className="w-full flex-col items-center justify-center text-center text-gray-400">
                      <p className="text-sm">
                        Upload a course thumbnail (1024 x 768 px, 4:3 ratio)
                      </p>
                      <p className="text-xs text-gray-400">
                        JPEG or PNG, max size: 5 MB
                      </p>
                      <button className="flex p-2 mt-4 ml-28 flex-row justify-center items-center w-max text-[13px] border border-gray-400 rounded-[10px]">
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.3294 7.17578C15.0294 7.40828 16.1319 8.79578 16.1319 11.8333V11.9308C16.1319 15.2833 14.7894 16.6258 11.4369 16.6258H6.55437C3.20187 16.6258 1.85938 15.2833 1.85938 11.9308V11.8333C1.85938 8.81828 2.94687 7.43078 5.60187 7.18328" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9 11.7498V3.21484" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.5094 4.8875L8.99688 2.375L6.48438 4.8875" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className="text-primary ml-3">Upload</p>
                     </button>
                    </div>
                  </label>
                </div>
              )}
            </div>
          </div>

          {/* Course Promo Video */}
          <div className="w-[50%] ml-6 flex flex-col items-start h-max rounded-[24px] p-6 bg-white shadow-sm">
            <h2 className="font-semibold text-md mb-2 text-gray-800">
              Course Promo Video
            </h2>
            <p className="text-sm text-gray-500 mb-4 text-center">
              Add an engaging video to showcase your course highlights
            </p>
            <div className={`w-full text-gray-400 flex flex-col items-center justify-start h-64 border-2 ${videoPreview ? "border-white" : 'border-dashed border-gray-300' } rounded-lg`}>
            {videoPreview ? (
                <div className="flex flex-col justify-center items-center">
                    <video
                      src={videoPreview}
                      className="h-full w-full object-cover rounded-lg"
                      controls
                    />
                    <button onClick={handleVideoFileChange} className="flex p-2 mt-4 flex-row justify-center items-center w-max text-[13px] border border-gray-400 rounded-[10px]">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3294 7.17578C15.0294 7.40828 16.1319 8.79578 16.1319 11.8333V11.9308C16.1319 15.2833 14.7894 16.6258 11.4369 16.6258H6.55437C3.20187 16.6258 1.85938 15.2833 1.85938 11.9308V11.8333C1.85938 8.81828 2.94687 7.43078 5.60187 7.18328" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 11.7498V3.21484" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.5094 4.8875L8.99688 2.375L6.48438 4.8875" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p className="text-primary ml-3">Reupload</p>
                </button>
                </div>
              ) : (
                <div className="py-12 flex flex-col items-center justify-center">
                  <input
                    type="file"
                    accept="video/mp4,video/webm,video/ogg"
                    className="hidden"
                    id="videoInput"
                    onChange={handleVideoFileChange}
                  />
                  <label htmlFor="videoInput">
                    <svg
                      className="w-full text-gray-400 lucide lucide-cloud-upload flex flex-col items-center justify-center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="52"
                      height="52"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M12 13v8" />
                      <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
                      <path d="m8 17 4-4 4 4" />
                    </svg>
                    <div className="w-full flex-col items-center justify-center text-center text-gray-400">
                      <p className="text-sm">
                        Upload a course promo video
                      </p>
                      <p className="text-xs text-gray-400">
                        MP4, WebM, OGG, max size: 10 MB
                      </p>
                      <button className="flex p-2 mt-4 ml-12 flex-row justify-center items-center w-max text-[13px] border border-gray-400 rounded-[10px]">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3294 7.17578C15.0294 7.40828 16.1319 8.79578 16.1319 11.8333V11.9308C16.1319 15.2833 14.7894 16.6258 11.4369 16.6258H6.55437C3.20187 16.6258 1.85938 15.2833 1.85938 11.9308V11.8333C1.85938 8.81828 2.94687 7.43078 5.60187 7.18328" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 11.7498V3.21484" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.5094 4.8875L8.99688 2.375L6.48438 4.8875" stroke="#505050" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p className="text-primary ml-3">Upload</p>
                </button>
                    </div>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        open={open}
        onClose={handleCloseDrawer}
        title="Edit Thumbnail"
        className="flex flex-col justify-center items-center"
      >
        <div className="h-[90%] w-full flex flex-col justify-center items-center">
          <div className="w-full">
            <div className="relative w-full h-80 bg-[#EFEFEF] overflow-hidden border border-gray-300">
              {/* Background image with blur */}
              {selectedFile && (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Crop"
                  ref={imageRef}
                  style={{
                    transform: `scale(${zoom}) rotate(${rotation}deg)`,
                    transformOrigin: "center",
                  }}
                  className="absolute inset-0 w-full h-full object-contain"
                />
              )}

              {/* Transparent Crop Box */}
              <div className="absolute inset-0 w-full h-full bg-black opacity-30 backdrop-blur-md z-10"></div>

              {/* Crop Box */}
              <div
                className="absolute top-[14%] left-1/4 w-1/2 h-3/4 border-4 border-white"
                style={{
                  background: "transparent",
                  zIndex: 20, // Make sure the crop box is above the blur layer
                }}
              />
            </div>

            <div className="mt-4 flex items-center justify-between">
              {/* Zoom Controls */}
              <div className="w-[50%] flex flex-row justify-start items-center">
                <label className="mr-6 text-sm">Zoom</label>
                <div className="w-full relative">
                  {/* Custom Range Input */}
                  <input
                    className="w-full h-1 bg-black rounded-full appearance-none"
                    type="range"
                    min="1"
                    max="3"
                    step="0.1"
                    value={zoom}
                    onChange={handleZoom}
                    style={{
                      backgroundSize: `${(zoom - 1) * 50}% 100%`, // Dynamic progress bar effect
                    }}
                  />
                </div>
              </div>
              {/* Rotation Controls */}
              <div className="flex items-center gap-4">
                <button
                  onClick={() => handleRotate("left")}
                  className="py-4 px-2 rounded"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6703 29.3337C13.5592 29.2226 12.4925 28.9504 11.4703 28.5171C10.4481 28.0837 9.47031 27.5115 8.53698 26.8004L10.4036 24.8671C11.0481 25.3337 11.7314 25.7115 12.4536 26.0004C13.1759 26.2893 13.9148 26.4893 14.6703 26.6004V29.3337ZM17.337 29.3337V26.6004C19.6481 26.2671 21.5592 25.2282 23.0703 23.4837C24.5814 21.7393 25.337 19.7115 25.337 17.4004C25.337 14.8004 24.4314 12.5948 22.6203 10.7837C20.8092 8.97261 18.6036 8.06706 16.0036 8.06706H15.737L17.8703 10.2004L16.0036 12.0671L10.6703 6.73372L16.0036 1.40039L17.8703 3.33372L15.8036 5.40039H16.0036C17.6703 5.40039 19.2314 5.71706 20.687 6.35039C22.1425 6.98372 23.4092 7.83928 24.487 8.91706C25.5648 9.99484 26.4203 11.2615 27.0536 12.7171C27.687 14.1726 28.0036 15.7337 28.0036 17.4004C28.0036 20.4448 26.9925 23.0948 24.9703 25.3504C22.9481 27.6059 20.4036 28.9337 17.337 29.3337ZM6.60365 24.8671C5.89253 23.9337 5.32031 22.9559 4.88698 21.9337C4.45365 20.9115 4.18142 19.8448 4.07031 18.7337H6.80365C6.91476 19.4893 7.11476 20.2282 7.40365 20.9504C7.69253 21.6726 8.07031 22.3559 8.53698 23.0004L6.60365 24.8671ZM4.07031 16.0671C4.20365 14.9337 4.48142 13.8448 4.90365 12.8004C5.32587 11.7559 5.89253 10.8004 6.60365 9.93372L8.53698 11.8004C8.07031 12.4448 7.69253 13.1282 7.40365 13.8504C7.11476 14.5726 6.91476 15.3115 6.80365 16.0671H4.07031Z"
                      fill="#5F6368"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => handleRotate("right")}
                  className="py-4 rounded"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3297 29.3337C18.4408 29.2226 19.5075 28.9504 20.5297 28.5171C21.5519 28.0837 22.5297 27.5115 23.463 26.8004L21.5964 24.8671C20.9519 25.3337 20.2686 25.7115 19.5464 26.0004C18.8241 26.2893 18.0852 26.4893 17.3297 26.6004V29.3337ZM14.663 29.3337V26.6004C12.3519 26.2671 10.4408 25.2282 8.92969 23.4837C7.41858 21.7393 6.66302 19.7115 6.66302 17.4004C6.66302 14.8004 7.56857 12.5948 9.37969 10.7837C11.1908 8.97261 13.3964 8.06706 15.9964 8.06706H16.263L14.1297 10.2004L15.9964 12.0671L21.3297 6.73372L15.9964 1.40039L14.1297 3.33372L16.1964 5.40039H15.9964C14.3297 5.40039 12.7686 5.71706 11.313 6.35039C9.85746 6.98372 8.5908 7.83928 7.51302 8.91706C6.43524 9.99484 5.57969 11.2615 4.94635 12.7171C4.31302 14.1726 3.99635 15.7337 3.99635 17.4004C3.99635 20.4448 5.00746 23.0948 7.02969 25.3504C9.05191 27.6059 11.5964 28.9337 14.663 29.3337ZM25.3964 24.8671C26.1075 23.9337 26.6797 22.9559 27.113 21.9337C27.5464 20.9115 27.8186 19.8448 27.9297 18.7337H25.1964C25.0852 19.4893 24.8852 20.2282 24.5964 20.9504C24.3075 21.6726 23.9297 22.3559 23.463 23.0004L25.3964 24.8671ZM27.9297 16.0671C27.7964 14.9337 27.5186 13.8448 27.0964 12.8004C26.6741 11.7559 26.1075 10.8004 25.3964 9.93372L23.463 11.8004C23.9297 12.4448 24.3075 13.1282 24.5964 13.8504C24.8852 14.5726 25.0852 15.3115 25.1964 16.0671H27.9297Z"
                      fill="#5F6368"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <footer className="w-full flex justify-end items-center py-4">
          <Button
            onClick={() => {
                handleCrop();
                setOpen(false);
              }}
            text="Save ->"
            className="w-32 h-[50px]"
          />
        </footer>
      </Drawer>
    </>
  );
}
