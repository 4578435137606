import React from "react";
import { checkIsMobile } from "../../utils/helpers";
import {
  EarnTeach,
  EasyCourseCreation,
  EngagementTools,
  FlexibleFormats,
  GlobalReach,
} from "../../components/icons";

function HowItWorks() {
  const incentives = [
    {
      name: "Sign Up & Create a Profile",
      icon: <GlobalReach className="size-6 text-gray-3" />,
      description:
        "Register as a venue provider and create your venue’s profile, showcasing its location, capacity, and amenities.",
    },
    {
      name: "List Your Venue",
      icon: <FlexibleFormats className="size-6 text-gray-3" />,
      description:
        "Add your venue to our platform, specifying available dates and types of events/classes (offline sessions etc.).",
    },
    {
      name: "Get Bookings",
      icon: <EarnTeach className="size-6 text-gray-3" />,
      description:
        "Instructors browse your venue and book it for their offline sessions or group classes.",
    },
    {
      name: "Host Classes & Earn",
      icon: <EasyCourseCreation className="size-6 text-gray-3" />,
      description:
        "Welcome instructors and learners to your space, host live classes, and get paid securely through the platform.",
    },
  ];
  const isMobile = checkIsMobile();
  return (
    <>
      <div className="flex flex-col justify-center items-center gap-4 mb-6">
        <h4 className="text-2xl md:text-3xl text-text font-semibold md:font-medium text-center m-0">
          How It Works{isMobile && <br />} for Venue Providers
        </h4>
      </div>
      <div className="items-baseline grid grid-cols-1 md:grid-cols-4">
        {incentives.map((incentive) => (
          <>
            <div
              key={incentive.name}
              className="flex flex-col justify-center items-center w-72 m-auto md:m-0 md:w-full  px-5 py-5 md:p-5 gap-3">
              <div className="bg-gray-5 rounded-full p-1.5">
                {incentive.icon}
              </div>
              <div className={`${isMobile ? "flex flex-col gap-1" : ""}`}>
                <h3
                  className={`text-base md:text-lg font-medium text-center text-text whitespace-nowrap md:whitespace-normal`}>
                  {incentive.name}
                </h3>
                <p className="text-sm md:text-xs text-text text-center">
                  {incentive.description}
                </p>
              </div>
            </div>
          </>
        ))}
      </div>
    </>
  );
}

export default HowItWorks;
