import { FloatingTextField } from '../../../../../components/ui-components';
import { useState } from 'react';


export const CardTile = ({ title, description, OfferPrice , free, CoursePrice, children }) => (
    <div className="bg-white p-8 rounded-[34px] mb-4 flex flex-col items-start w-full">
     <div className='w-full flex flex-row justify-between items-center'>
        <div className='flex flex-col items-start'>
            <h3 className="font-semibold text-lg text-gray-800 mb-2">{title}</h3>
            <p className="text-gray-500 text-sm mb-4">{description}</p>
        </div>
        <div className='flex flex-col items-end'>
            <h3 className="font-semibold text-lg text-gray-800 mb-2">{free ? "Free" : `₹ ${OfferPrice == '' ? '2999' : OfferPrice } `}</h3>
            <p className="text-gray-500 text-sm mb-4 flex flex-row justify-center">{free ? "" : "20% Off ₹ "}<p className='line-through'>{free ? "" : `${CoursePrice == '' ? '5000' : CoursePrice}`} </p></p>
        </div>
     </div>
      <div className="w-full">{children}</div>
    </div>
  );

  const coupons = [
    {title : 'SAVE 500' , description : 'Get an instant discount of ₹500 off on courses above ₹3,000'},
    {title : 'SAVE 500' , description : 'Get an instant discount of ₹500 off on courses above ₹3,000'},
    {title : 'SAVE 500' , description : 'Get an instant discount of ₹500 off on courses above ₹3,000'}
  ]
  

export default function ClassroomStep4() {

  const [CoursePrice , setCoursePrice] = useState('')
  const [OfferPrice , setOfferPrice] = useState('')
  const [showCoupon , setShowCoupon] = useState(false)
  const [isFreeCourse, setIsFreeCourse] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsFreeCourse(event.target.checked);
  };

    return (
        <>
        <div className="w-full flex flex-col justify-start items-start grow overflow-y-auto px-8">
        <div className="w-full flex flex-row justify-between items-center py-6">
          <h1 className="font-medium text-xl text-left text-secondary mb-4">
            Price
          </h1>
        </div>
        <CardTile
        title="Set Price for Your Course"
        description='Define the pricing structure for your course, including discounts or free options.'
        OfferPrice={OfferPrice}
        CoursePrice={CoursePrice}
        free={isFreeCourse}
        >
        <div>
            <div className='w-[80%] flex flex-row justify-between items-center'>
               <div className='flex flex-row justify-evenly items-center'>
                    <svg className='mb-6' width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4375 17.5L5.83333 11.6667V10H8.75C9.48611 10 10.1215 9.76042 10.6562 9.28125C11.191 8.80208 11.5139 8.20833 11.625 7.5H5V5.83333H11.375C11.1389 5.34722 10.7882 4.94792 10.3229 4.63542C9.85764 4.32292 9.33333 4.16667 8.75 4.16667H5V2.5H15V4.16667H12.2917C12.4861 4.40278 12.6597 4.65972 12.8125 4.9375C12.9653 5.21528 13.0833 5.51389 13.1667 5.83333H15V7.5H13.3125C13.2014 8.68056 12.7153 9.67014 11.8542 10.4688C10.9931 11.2674 9.95833 11.6667 8.75 11.6667H8.14583L13.75 17.5H11.4375Z" fill="#5F6368"/>
                    </svg>

                    <FloatingTextField
                    label="Enter Course Price"
                    type="number"
                    name="CoursePrice"
                    id="CoursePrice"
                    className="w-full py-2"
                    value={CoursePrice}
                    onChange={(e) => setCoursePrice(e.target.value)}
                    />
              </div>
              <div className='flex flex-row justify-between items-center'>
                    <svg className='mb-6' width="28" height="28" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4375 17.5L5.83333 11.6667V10H8.75C9.48611 10 10.1215 9.76042 10.6562 9.28125C11.191 8.80208 11.5139 8.20833 11.625 7.5H5V5.83333H11.375C11.1389 5.34722 10.7882 4.94792 10.3229 4.63542C9.85764 4.32292 9.33333 4.16667 8.75 4.16667H5V2.5H15V4.16667H12.2917C12.4861 4.40278 12.6597 4.65972 12.8125 4.9375C12.9653 5.21528 13.0833 5.51389 13.1667 5.83333H15V7.5H13.3125C13.2014 8.68056 12.7153 9.67014 11.8542 10.4688C10.9931 11.2674 9.95833 11.6667 8.75 11.6667H8.14583L13.75 17.5H11.4375Z" fill="#5F6368"/>
                    </svg>
                <FloatingTextField
                label="Offer Price"
                type="number"
                name="OfferPrice"
                id="OfferPrice"
                className="w-full py-2"
                value={OfferPrice}
                onChange={(e) => setOfferPrice(e.target.value)}
                />
              </div>
            </div>
              <div className="flex flex-row items-center justify-start text-gray-400 pb-6">
              <input checked={isFreeCourse} onChange={handleCheckboxChange} className="w-5 h-5 mr-4 text-black bg-gray-100 border-gray-400 rounded focus:ring-black dark:focus:ring-black dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox"/> <label>make this course free</label>
            </div>
            {!isFreeCourse ? <div>
            <h3 className="font-semibold text-lg text-gray-800 my-2">Add Coupon</h3>

            <button onClick={()=>{setShowCoupon(true)}} className={`${showCoupon ? 'hidden' : 'w-full py-10 flex flex-row justify-between items-center rounded-xl bg-gray-50'}`} >
                <svg className='w-max px-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.8307 11.8802L11.8724 17.8385C11.7057 18.0052 11.5182 18.1302 11.3099 18.2135C11.1016 18.2969 10.8932 18.3385 10.6849 18.3385C10.4766 18.3385 10.2682 18.2969 10.0599 18.2135C9.85156 18.1302 9.66406 18.0052 9.4974 17.8385L2.14323 10.4844C1.99045 10.3316 1.8724 10.1545 1.78906 9.95312C1.70573 9.75173 1.66406 9.53993 1.66406 9.31771V3.33854C1.66406 2.88021 1.82726 2.48785 2.15365 2.16146C2.48003 1.83507 2.8724 1.67188 3.33073 1.67188H9.3099C9.53212 1.67188 9.7474 1.71701 9.95573 1.80729C10.1641 1.89757 10.3446 2.0191 10.4974 2.17187L17.8307 9.52604C17.9974 9.69271 18.1189 9.88021 18.1953 10.0885C18.2717 10.2969 18.3099 10.5052 18.3099 10.7135C18.3099 10.9219 18.2717 11.1267 18.1953 11.3281C18.1189 11.5295 17.9974 11.7135 17.8307 11.8802ZM10.6849 16.6719L16.6432 10.7135L9.28906 3.33854H3.33073V9.29687L10.6849 16.6719ZM5.41406 6.67187C5.76128 6.67187 6.05642 6.55035 6.29948 6.30729C6.54253 6.06424 6.66406 5.7691 6.66406 5.42187C6.66406 5.07465 6.54253 4.77951 6.29948 4.53646C6.05642 4.2934 5.76128 4.17187 5.41406 4.17187C5.06684 4.17187 4.7717 4.2934 4.52865 4.53646C4.28559 4.77951 4.16406 5.07465 4.16406 5.42187C4.16406 5.7691 4.28559 6.06424 4.52865 6.30729C4.7717 6.55035 5.06684 6.67187 5.41406 6.67187Z" fill="#5F6368"/>
                </svg>
                <p className='w-[90%] text-gray-400 text-left'>Choose a Coupon </p>
                <svg className='w-max px-2' width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L0.999999 1" stroke="#2A3140" stroke-opacity="0.7" stroke-width="2"/>
                </svg>
            </button>
            {showCoupon ? 

            <div className='grid grid-cols-2 gap-x-16 gap-y-4 p-3'>
                {coupons.map((coupon) => (
                    <div className='flex flex-col justify-start items-start bg-gray-50 p-4 w-[70%] '>
                        <h3 className="font-semibold text-lg text-gray-800 mb-2">{coupon.title}</h3>
                        <p className="text-gray-400 text-sm mb-4">{coupon.description}</p>
                    </div>
                ))}
            </div> 
            : <div>
                
              </div>
              
            }
            <button
            // onClick={() => handleAddTopic(chapter.id)}
            className="flex justify-start text-gray-800 border-2 border-gray-400 rounded-full text-sm mt-2 p-2"
            >
                <p>+ Create New Coupon</p>
            </button>
          
            </div> : 
            
          <div></div>}
        </div>
        </CardTile>
        </div>
        </>
    )
}