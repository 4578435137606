import React, { useState } from "react";
import rightArrow from "../../../assets/svgs/rightArrow.svg";
import { Button, Drawer } from "../../../components/ui-components";
import visa from "../../../assets/svgs/visa.svg";
// import masterCard from "../../../assets/svgs/masterCard.svg";
import masterCard from "../../../assets/svgs/masterCard.svg";
import razorPay from "../../../assets/svgs/razorPay.svg";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import AddCard from "./AddCard";
import { checkIsMobile } from "../../../utils/helpers";
import MobDrawer from "../../../components/ui-components/MobDrawer";

const cardData = [
  {
    cardNumber: "**** **** **** 4973",
    expiryDate: "10/25",
    cardType: visa,
  },
  {
    cardNumber: "**** **** **** 3245",
    expiryDate: "01/26",
    cardType: masterCard,
  },
  {
    cardNumber: "**** **** **** 7000",
    expiryDate: "12/36",
    cardType: razorPay,
  },
];

function Payment() {
  const [open, setOpen] = useState(false);
  const [isAddCard, setIsAddCard] = useState(false);
  const isMobile = checkIsMobile();

  return (
    <>
      <div
        className="w-full flex flex-row justify-between items-start transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer mt-4 md:mt-8 border-b pb-4 md:border-none md:pb-0"
        onClick={() => setOpen(true)}>
        <div className="w-full flex flex-row justify-between gap-2">
          <p className="text-base text-primary">Saved Payment Methods</p>
          <img src={rightArrow} alt="" />
        </div>
      </div>

      {isMobile ? (
        <MobDrawer open={open} onClose={setOpen} title={`${isAddCard ? "Add Card" : "Saved Payment Methods"}`}>
          {isAddCard ? (
            <AddCard setIsAddCard={setIsAddCard} />
          ) : (
            <div className="m-auto flex flex-col items-center my-8">
              <div className="w-full grid grid-cols-1 gap-5">
                {cardData.map((item, index) => (
                  <div className="flex flex-row gap-2 items-center" key={index}>
                    <div className="w-full flex flex-row gap-5 border border-gray-200 px-4 py-3 rounded-2xl">
                      <img src={item.cardType} alt="" />
                      <div className="flex flex-col">
                        <p className="text-lg text-secondary font-medium">
                          {item.cardNumber}
                        </p>
                        <p className="text-sm text-primary">
                          Expires {item.expiryDate}
                        </p>
                      </div>
                    </div>

                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            aria-hidden="true"
                            className="size-6"
                          />
                        </MenuButton>
                      </div>

                      <MenuItems
                        transition
                        className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                        <div className="py-1">
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                              Set as default
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                              Remove as default
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                              Remove Card
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  </div>
                ))}

                <button
                  className="w-[70%] m-auto mt-4 bg-white border border-dotted border-gray-300 py-4 rounded-2xl text-sm text-secondary font-medium"
                  onClick={() => setIsAddCard(true)}>
                  + Add New Card
                </button>
              </div>
            </div>
          )}
        </MobDrawer>
      ) : (
        <Drawer
          open={open}
          onClose={setOpen}
          title={isAddCard ? "" : "Saved Payment Methods"}>
          {isAddCard ? (
            <AddCard setIsAddCard={setIsAddCard} />
          ) : (
            <div className="max-w-[65%] m-auto flex flex-col items-center my-8">
              <div className="w-full grid grid-cols-1 gap-5">
                {cardData.map((item, index) => (
                  <div className="flex flex-row gap-2 items-center" key={index}>
                    <div className="w-full flex flex-row gap-5 border border-gray-200 px-4 py-3 rounded-2xl">
                      <img src={item.cardType} alt="" />
                      <div className="flex flex-col">
                        <p className="text-lg text-secondary font-medium">
                          {item.cardNumber}
                        </p>
                        <p className="text-sm text-primary">
                          Expires {item.expiryDate}
                        </p>
                      </div>
                    </div>

                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-offset-gray-100">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            aria-hidden="true"
                            className="size-6"
                          />
                        </MenuButton>
                      </div>

                      <MenuItems
                        transition
                        className="absolute right-8 top-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                        <div className="py-1">
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                              Set as default
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                              Remove as default
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none">
                              Remove Card
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  </div>
                ))}

                <button
                  className="w-[70%] m-auto mt-4 bg-white border border-dotted border-gray-300 py-4 rounded-2xl text-sm text-secondary font-medium"
                  onClick={() => setIsAddCard(true)}>
                  + Add New Card
                </button>
              </div>
            </div>
          )}
        </Drawer>
      )}
    </>
  );
}

export default Payment;
